import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { TRANSLATION_BASE_URL } from "../../utils/EmpUtil";
import { getHeaders } from "../../utils/AuthorizationUtil";

export default class TranslationsClient {
  static translationUrl = TRANSLATION_BASE_URL;

  static getTranslationsLanguage(language, market, token) {
    const url = `${this.translationUrl}/translations/${language}/${market}`;
    return axios
      .get(url, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error.response.status);
      });
  }

  static updateCurrentTranslations(
    language,
    moduleKey,
    key,
    updatedValue,
    market,
    token
  ) {
    const url = `${this.translationUrl}/translations/${language}/${market}`;
    const data = {
      moduleKey: moduleKey,
      key: key,
      changedValue: updatedValue,
    };
    return axios
      .post(url, data, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error.response.status);
      });
  }

  static postAddTranslations(newTranslation, markets, token) {
    const url = `${this.translationUrl}/translations/add`;
    const data = {
      moduleKey: newTranslation.moduleKey,
      key: newTranslation.key,
      en: newTranslation.en,
      es: newTranslation.es,
      vi: newTranslation.vi,
      th: newTranslation.th,
      ph: newTranslation.ph,
      pt: newTranslation.pt,
      markets: markets,
    };
    return axios
      .post(url, data, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error.response.status);
      });
  }

  static postAddComponentTranslations(newtranslation, markets, token) {
    const url = `${this.translationUrl}/translations/add/component`;
    const data = {
      moduleKey: newtranslation.moduleKey,
      key: newtranslation.key,
      en: newtranslation.en,
      es: newtranslation.es,
      vi: newtranslation.vi,
      th: newtranslation.th,
      ph: newtranslation.ph,
      pt: newtranslation.pt,
      markets: markets,
    };
    return axios
      .post(url, data, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error.response.status);
      });
  }

  static getDefaultLanguage() {
    const url = `${this.translationUrl}/translations/language/`;
    return url;
    // return axios.get(url, config)
    //     .then((response) => response.data)
    //     .catch((error) => {
    //         if (error.response.status === 401 || error.response.status === 403) {
    //             AuthenticationFailureHandler.execute();
    //         }
    //         return Promise.reject(error.response.status);
    //     });
  }
}
