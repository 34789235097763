import React from "react";
import { Grid } from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import styles from "./NscDeliveryRangesRow.module.scss";
import { withTranslation } from "react-i18next";
import Item from "../../../../../shared/item/Item";
import Button from "@material-ui/core/Button";

const NscDeliveryRangesRow = (props) => {
  function displayTo(val) {
    if (val === -1 || val === null) return "OVER";
    return val;
  }

  return (
    <div
      id="DeliverRangeAndPriceRow"
      className={`${styles.rowBorder} pagePaddingLeft`}
    >
      <Grid container spacing={0}>
        <Grid item xs={1} className={styles.rowItem}>
          <TextValidator
            className={styles.textBox}
            style={{ marginTop: "10px", marginBottom: "10px" }}
            id={props.currentRow === null ? "" : props.currentRow.toString()}
            name={"rangeFrom" + props.currentRow.toString()}
            variant="outlined"
            value={
              props.dataObjItem.from === null ? "" : props.dataObjItem.from
            }
            onChange={props.action}
            validators={[
              "required",
              "matchRegexp:^[0-9\\b]+$",
              "checkingRows:" + `${props.currentRow}`,
              "shouldBeZero:" + `${props.currentRow}`,
            ]}
            errorMessages={[
              props.t("nscDeliveryBands.required"),
              props.t("nscDeliveryBands.isNumber"),
              props.t("nscDeliveryBands.invalid"),
              props.t("nscDeliveryBands.hasToBeZero"),
            ]}
          />
        </Grid>
        <Grid item xs={2} className={styles.rowItem}>
          <div className={styles.to}>
            <Item value={props.t("nscDeliveryBands.to")} />
          </div>
          <TextValidator
            className={styles.textBox}
            style={{ marginTop: "10px", marginBottom: "10px" }}
            id={props.currentRow === null ? "" : props.currentRow.toString()}
            name={"rangeTo" + props.currentRow.toString()}
            variant="outlined"
            value={displayTo(props.dataObjItem.to)}
            validators={[
              "required",
              "requiredToValue:" +
                `${props.currentRow}` +
                `${props.currentNoOfRows}`,
              "matchRegexp:^[A-Za-z0-9\\b]+$",
              "isBigger:" + `${props.dataObjItem.from}`,
              "isOverText:" + `${props.dataObjItem.to}`,
            ]}
            errorMessages={[
              props.t("nscDeliveryBands.required"),
              props.t("nscDeliveryBands.invalid"),
              props.t("nscDeliveryBands.isNumber"),
              props.t("nscDeliveryBands.invalid"),
              props.t("nscDeliveryBands.invalid"),
            ]}
            onChange={props.action}
          />
        </Grid>
        <Grid item xs={5} className={`${styles.rowItem} ${styles.right}`}>
          <TextValidator
            className={styles.textBox}
            style={{ marginTop: "10px", marginBottom: "10px" }}
            id={props.currentRow === null ? "" : props.currentRow.toString()}
            name={"rangeValue" + props.currentRow.toString()}
            variant="outlined"
            value={
              props.dataObjItem.value === null ? "" : props.dataObjItem.value
            }
            validators={[
              "required",
              "matchRegexp:^[0-9\\b.]+$",
              "isNumber",
              "isNumericWithTwoDecimalPlaces",
            ]}
            errorMessages={[
              props.t("nscDeliveryBands.required"),
              props.t("nscDeliveryBands.invalid"),
              props.t("nscDeliveryBands.invalid"),
              props.t("nscDeliveryBands.invalid"),
            ]}
            onChange={props.action}
          />
        </Grid>
        <Grid item xs={3} className={`${styles.rowItem} ${styles.delete}`}>
          {props.currentNoOfRows > props.defaultNoOfRows &&
            props.currentRow + 1 === props.currentNoOfRows && (
              <div>
                <Button
                  id="deleteDeliveryRangeBtn"
                  className={styles.deleteButton}
                  onClick={props.deleteAction}
                  component="button"
                >
                  {props.t("nscDeliveryBands.delete")}
                </Button>
              </div>
            )}
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </div>
  );
};

export default withTranslation("emp")(NscDeliveryRangesRow);
