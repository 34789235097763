import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import styles from "./SearchWrapper.module.scss";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HasPermissionTo from "../../../common/HasPermissionTo";

const DisplaySearchType = (props) => {
  return (
    <Grid item md={3} xs={6}>
      <div className={styles.searchType}>
        <div>
          <div className={styles.searchTitle}>{props.header}</div>
          <div className={styles.searchText}>
            {props.desc ? props.desc : "--"}
          </div>
          <Link
            className={styles.link}
            data-testid={props.name}
            to={props.link}
            name={props.name}
          >
            <div className={styles.goto}>
              {props.goto}
              <ChevronRightIcon className={styles.rightChevron} />
            </div>
          </Link>
        </div>
      </div>
    </Grid>
  );
};

class SearchWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <HasPermissionTo
        perform={["searchLandingPage"]}
        permissions={this.props.user.permissions.rolePermissions}
        condition={this.props.user.hasNscCompletedBasicPAs}
        render={() => (
          <div className="pageWrapper" id="SearchWrapper">
            <div className={`${styles.titleDiv} pagePadding`}>
              <div className="pageTitle">
                {this.props.t("SearchWrapper.title")}
              </div>
            </div>
            <p className={`${styles.subHeader} pagePadding`}>
              {this.props.t("SearchWrapper.subTitle")}
            </p>

            <Grid container className={styles.search} spacing={0}>
              <DisplaySearchType
                header={this.props.t("SearchWrapper.ordersSearch")}
                desc={this.props.t("SearchWrapper.ordersSearchDesc")}
                goto={this.props.t("SearchWrapper.gotoPage")}
                link="/search/orders"
                name="orders"
              />
            </Grid>
          </div>
        )}
      />
    );
  }
}

export default withTranslation("emp")(SearchWrapper);
