import React, { Component } from "react";
import style from "./ErrorProcessingDialog.module.scss";
import { withTranslation } from "react-i18next";
import FordLogo from "../common/FordLogo";
import Button from "@material-ui/core/Button";

class PageError extends Component {
  goBack() {
    window.history.back();
    this.props.close();
  }
  render() {
    return (
      <div className="pageWrapper">
        <div className={style.center}>
          <FordLogo className={style.brandLogo} />
          <div className={style.deniedTitle}>{this.props.code}</div>
          <div className={style.deniedInfo}>{this.props.body}</div>
          <Button className={style.confirm} onClick={() => this.goBack()}>
            {this.props.t("Error.goBack")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(PageError);
