import { BRAND, PAGE, SALESCODE_KEY } from "../common/Constants";

export default class AuthenticationFailureHandler {
  static execute = (authenticationRequest = null) => {
    const displayLogin = sessionStorage.getItem("login");
    if (!displayLogin) {
      sessionStorage.setItem("login", "true");
    }
    const page =
      sessionStorage.getItem(PAGE) ||
      (authenticationRequest && authenticationRequest["page"]);
    const salesCode =
      sessionStorage.getItem(SALESCODE_KEY) ||
      (authenticationRequest && authenticationRequest["salesCode"]);
    const brand =
      sessionStorage.getItem(BRAND) ||
      (authenticationRequest && authenticationRequest["brand"]);

    if (displayLogin) {
      //Displaying ADFS login screen
      let stateParam = "";

      if (salesCode) {
        stateParam += ",salesCode=" + salesCode;
      }
      if (page) {
        stateParam += ",page=" + page;
      }
      if (brand) {
        stateParam += ",brand=" + brand;
      }
      window.location.href =
        window.EMP.ADFS_URL + (stateParam ? "&state=" + stateParam : "");
    } else {
      //Clearing the ADFS session if any
      let param = "";
      if (salesCode)
        param +=
          param === "" ? "#salesCode=" + salesCode : "&salesCode=" + salesCode;
      if (page) param += param === "" ? "#page=" + page : "&page=" + page;
      if (brand) param += param === "" ? "#brand=" + brand : "&brand=" + brand;

      window.location.href =
        window.EMP.LOGOUT_URI + window.EMP.REDIRECT_URI + param;
    }
  };

  /*
        Regarding the OAuth state parameter (from oauth.com): When the user is redirected back to your app,
        whatever value you include as the state will also be included in the redirect. This gives your app
        a chance to persist data between the user being directed to the authorization server and back again,
        such as using the state parameter as a session key. This may be used to indicate what action in the
        app to perform after authorization is complete, for example, indicating which of your app’s pages to
        redirect to after authorization.
     */
  static logout = () => {
    sessionStorage.clear();
    window.location.href = window.EMP.LOGOUT_URI;
  };
}
