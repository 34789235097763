import React from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import "./CalendarDialog.scss";
import "../../../../shared/datePickerDialog/datePickerDialog.scss";
import CheckBox from "../../../../shared/checkBox/CheckBox";
import checkboxStyles from "../../../../shared/checkBox/CheckBox.module.scss";
import { withStyles } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import UiDateUtils from "../../../../utils/UiDateUtils";
import Info from "@material-ui/icons/Info";
import { Tooltip } from "@material-ui/core";
import PricingTooltip from "./PricingTooltip";

const StyledClear = withStyles({
  root: {
    width: 35,
    height: 35,
    fill: "#102b4e",
    align: "right",
  },
})(Clear);

const StyledInfo = withStyles({
  root: {
    fill: "#4a90e2",
    width: 18,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: -3,
    cursor: "pointer",
  },
})(Info);

const StyledTooltip = withStyles({
  popper: {
    opacity: 1,
    pointerEvents: "auto",
  },
  tooltip: {
    background: "white",
    boxShadow: "1px 1px 9px 0 rgba(0, 0, 0, 0.27)",
    paddingTop: 28,
    paddingLeft: 28,
    paddingRight: 28,
    paddingBottom: 18,
    maxWidth: 350,
  },
})(Tooltip);

class CalendarDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerSelectedDate: this.formatTodayDate(),
      openTooltip: false,
    };
    this.ref = React.createRef();
  }

  formatTodayDate = () => {
    const dateString = this.props.todayDate;
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);
    return new Date(year, month - 1, day);
  };

  updateDate = (date) => {
    if (date !== null) {
      this.setState({ dealerSelectedDate: date }); //Set newly selected date
    }
  };

  saveDateAndClose = () => {
    this.ref.current.setOpen(false);
    const year = this.state.dealerSelectedDate.getFullYear();
    const month = this.state.dealerSelectedDate.getMonth() + 1;
    const day = this.state.dealerSelectedDate.getDate();
    const formattedDate = UiDateUtils.formatDateYYYYMMDD(year, month, day);
    this.props.saveCalendarDate(
      UiDateUtils.saveDateFormat(formattedDate),
      this.props.type
    );
  };

  toggleDatePickerDialog = () => {
    this.ref.current.setOpen(true);
  };

  resetAndCloseDialog = () => {
    this.ref.current.setOpen(false);
  };

  toggleTooltip = () => {
    this.setState((prevState) => ({
      openTooltip: !prevState.openTooltip,
    }));
  };

  closeTooltip = () => {
    this.setState({
      openTooltip: false,
    });
  };

  render() {
    const CalendarDialogContainer = ({ children }) => {
      return (
        <div className="dialogContainer">
          <CalendarContainer id={this.props.id + "ContainerId"}>
            <div className="cancel">
              <button
                data-testid="closeDialogId"
                onClick={this.resetAndCloseDialog}
              >
                <StyledClear />
              </button>
            </div>
            <div className="dialogTitle">{this.props.dialogTitle}</div>
            <div className="dialogDescription">
              {this.props.dialogDescription}
            </div>
            <div className="calendarContainer">{children}</div>
          </CalendarContainer>
        </div>
      );
    };

    const CheckBoxSelector = () => (
      <CheckBox
        id={this.props.id + "CheckBoxId"}
        checked={this.props.isChecked}
        isDisabled={this.props.isDisabled}
        label={this.props.checkBoxText}
        onChange={() => this.toggleDatePickerDialog()}
        textClass={
          this.props.isDisabled
            ? this.props.isChecked
              ? checkboxStyles.boldLabel
              : checkboxStyles.disabledLabel
            : checkboxStyles.boldLabel
        }
      />
    );

    const formatMaxDate = () => {
      const todayDate = this.props.todayDate.split("-");
      return new Date(todayDate[0], todayDate[1] - 1, todayDate[2]);
    };
    return (
      <div className="calendarDialogContainer dialogWrapper">
        <DatePicker
          ref={this.ref}
          id={this.props.id + "CalendarPickerId"}
          selected={this.state.dealerSelectedDate}
          withPortal
          shouldCloseOnSelect={false}
          maxDate={this.props.hasMaxDate && formatMaxDate()}
          onChange={this.updateDate}
          calendarContainer={CalendarDialogContainer}
          customInput={<CheckBoxSelector />}
        >
          <div className="applyButtonFormat">
            <Button
              className="applySave"
              name="applySave_name"
              data-testid="applySaveId"
              onClick={this.saveDateAndClose}
            >
              {this.props.t("CustomerOptOffline.applyAndSave")}
            </Button>
          </div>
        </DatePicker>
        {this.props.showTooltip && (
          <StyledTooltip
            data-testid="pricingTooltip"
            open={this.state.openTooltip}
            placement="bottom-start"
            title={<PricingTooltip closeTooltip={this.closeTooltip} />}
          >
            <StyledInfo onClick={this.toggleTooltip} fontSize="small" />
          </StyledTooltip>
        )}
      </div>
    );
  }
}

export default withTranslation("emp")(CalendarDialog);
