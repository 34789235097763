import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./OptIn.module.scss";
import { Button, Grid, withStyles } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio/Radio";
import { OPT_IN } from "../../../common/Constants";
import PendingActionPrompt from "../../../shared/pendingActionPrompt/PendingActionPrompt";
import HasPermissionTo from "../../../common/HasPermissionTo";
import DealerPreferencesClient from "../../../shared/clients/DealerPreferencesClient";
import MarketPreferencesClient from "../../../shared/clients/MarketPreferencesClient";
import { IS_SWISS_MARKET } from "../../../utils/EmpUtil";

const StyledRadio = withStyles({
  root: {
    color: "#102b4e",
    "&$checked": {
      color: "#102b4e",
    },
  },
  checked: {},
})(Radio);

class OptIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vmStatus: "",
      aldStatus: "",
      gpisStatus: "",
      renderPage: false,
      aldCupidId: undefined,
      optInPendingActionPrompt: false,
    };
    this.hasALDPricing =
      this.props.user.permissions.rolePermissions.hasOwnProperty(
        "aldPricing:view"
      );
    this.hasGPISPricing =
      this.props.user.permissions.rolePermissions.hasOwnProperty(
        "gpisPricing:view"
      );
    this.hasVMPricing =
      this.props.user.permissions.rolePermissions.hasOwnProperty(
        "variableMarketing:view"
      );
    this.onboardedDealers = this.props.user.getOnboardedDealerGroup(true);
    this.copyOptInPreferences = null;
  }

  componentDidMount() {
    this.props.hideOrShow(true);
    this.determineOPTInPAStatus();
    this.getAldCupidId();
    this.getAllOptInStatus();
    this.props.hideOrShow(false);
  }

  handleOptInPendingActionPrompt = () => {
    this.setState((prevState) => ({
      optInPendingActionPrompt: !prevState.optInPendingActionPrompt,
    }));
  };
  handleOptInChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getAldCupidId() {
    MarketPreferencesClient.getAldCupidId(this.props.user).then((response) => {
      this.setState({ aldCupidId: response.result.aldCupidId });
    });
  }

  getAllOptInStatus() {
    DealerPreferencesClient.getOptInPreferences(this.props.user).then(
      (response) => {
        const vmStatus = this.extractResponse(
          response.variableMarketingStatus,
          "vm"
        );
        const aldStatus = this.extractResponse(
          response.aldPricingStatus,
          "ald"
        );
        const gpisStatus = this.extractResponse(
          response.gpisPricingStatus,
          "gpis"
        );
        this.copyOptInPreferences = {
          vmStatus: vmStatus,
          aldStatus: aldStatus,
          gpisStatus: gpisStatus,
        };
        this.setState({
          vmStatus: vmStatus,
          aldStatus: aldStatus,
          gpisStatus: gpisStatus,
          renderPage: true,
        });
      }
    );
  }

  extractResponse(response, type) {
    let status;
    if (response === "OPT_IN") {
      if (type === "vm") status = "vmYes";
      else if (type === "ald") status = "aldYes";
      else if (type === "gpis") status = "gpisYes";
    } else if (response === "OPT_OUT") {
      if (type === "vm") status = "vmNo";
      else if (type === "ald") status = "aldNo";
      else if (type === "gpis") status = "gpisNo";
    } else if (response === "PENDING" || response === "") {
      status = "";
    }
    return status;
  }

  updateRequestBody(commonIds) {
    const optInRequest = {};
    commonIds.forEach((commonId) => {
      const dealerPreference = {};
      if (this.state.vmStatus !== this.copyOptInPreferences.vmStatus)
        dealerPreference.variableMarketingStatus =
          this.state.vmStatus === "vmYes" ? "OPT_IN" : "OPT_OUT";
      if (this.state.aldStatus !== this.copyOptInPreferences.aldStatus)
        dealerPreference.aldPricingStatus =
          this.state.aldStatus === "aldYes" ? "OPT_IN" : "OPT_OUT";
      if (this.state.gpisStatus !== this.copyOptInPreferences.gpisStatus)
        dealerPreference.gpisPricingStatus =
          this.state.gpisStatus === "gpisYes" ? "OPT_IN" : "OPT_OUT";

      const formattedCommonId = commonId.toString().split("%7C").join("|");
      optInRequest[formattedCommonId] = dealerPreference;
    });
    this.applyOptInPreferences(optInRequest);
  }

  optInPAPromptApplyAll = () => {
    this.updateRequestBody(
      this.getAllIds(this.optInPAStatusForDealers[0], true)
    );
    this.handleOptInPendingActionPrompt();
  };
  optInPAPromptApplySelective = () => {
    this.updateRequestBody(
      this.getSelectiveIds(this.optInPAStatusForDealers[0], true)
    );
    this.handleOptInPendingActionPrompt();
  };

  getAllIds(pendingActionStatus, commonId = false) {
    return pendingActionStatus.map((dealer) => {
      return commonId ? dealer.commonId : dealer.dealerId;
    });
  }

  getSelectiveIds(pendingActionStatus, commonId = false) {
    return pendingActionStatus
      .filter((dealer) => !dealer.pendingActionStatus)
      .map((dealer) => {
        return commonId ? dealer.commonId : dealer.dealerId;
      });
  }

  determineOPTInPAStatus() {
    this.optInPAStatusForDealers =
      this.props.user.getPendingActionStatusForDealers(
        this.props.user.pendingActions,
        OPT_IN
      );
    this.optInDisablePAPrompt = this.optInPAStatusForDealers.length === 0;
  }

  determineApplyDisabled = () => {
    const aldStatus = this.state.aldStatus;
    const vmStatus = this.state.vmStatus;
    const gpisStatus = this.state.gpisStatus;
    let applyDisabled = true;
    if (
      (aldStatus !== "" || !this.hasALDPricing) &&
      (gpisStatus !== "" || !this.hasGPISPricing) &&
      (vmStatus !== "" || !this.hasVMPricing)
    ) {
      applyDisabled =
        aldStatus === this.copyOptInPreferences.aldStatus &&
        vmStatus === this.copyOptInPreferences.vmStatus &&
        gpisStatus === this.copyOptInPreferences.gpisStatus;
    }

    return applyDisabled;
  };

  saveOptIn() {
    if (
      this.props.user.showAll &&
      this.onboardedDealers.length > 1 &&
      !this.optInDisablePAPrompt
    ) {
      this.handleOptInPendingActionPrompt();
    } else {
      this.updateRequestBody(
        this.props.user.getCommonIdsWithoutSDLR().split(",")
      );
    }
  }

  applyOptInPreferences = (optInRequest) => {
    this.props.hideOrShow(true);
    DealerPreferencesClient.updateAllOptInPreferences(
      this.props.user,
      optInRequest
    )
      .then(() => {
        this.props.getPendingActions().then(() => {
          this.determineOPTInPAStatus();
          this.getAllOptInStatus();
        });
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  getOptInPanel(
    prompt,
    secondPrompt,
    name,
    value,
    yesId,
    noId,
    onClick,
    permissions
  ) {
    return (
      <div className="pagePaddingTop">
        <Grid container className={`${styles.panel} pagePadding`}>
          <Grid item xs={10}>
            <div className={styles.prompt}>{prompt}</div>
            <span className={styles.secondaryPrompt}>{secondPrompt}</span>
          </Grid>
          <Grid item xs={2}>
            <HasPermissionTo
              perform={[permissions]}
              condition={!this.props.isMainViewingSDLR(this.props.user)}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => (
                <FormControl
                  component="fieldset"
                  className={styles.radioContainer}
                >
                  <RadioGroup
                    name={name}
                    label={name}
                    value={value}
                    row
                    onChange={onClick}
                  >
                    <FormControlLabel
                      value={yesId}
                      control={<StyledRadio />}
                      className={styles.labels}
                      data-testid={yesId}
                      label={this.props.t("OptIn.yes")}
                    />
                    <FormControlLabel
                      value={noId}
                      control={<StyledRadio />}
                      className={styles.labels}
                      data-testid={noId}
                      label={this.props.t("OptIn.no")}
                    />
                  </RadioGroup>
                </FormControl>
              )}
              noRender={() =>
                value.toLowerCase().includes("yes") ? (
                  <div className={styles.viewOnly}>
                    {this.props.t("OptIn.yes")}
                  </div>
                ) : value.toLowerCase().includes("no") ? (
                  <div className={styles.viewOnly}>
                    {this.props.t("OptIn.no")}
                  </div>
                ) : (
                  value === "" && (
                    <div className={styles.viewOnly}>
                      {this.props.t("OptIn.pending")}
                    </div>
                  )
                )
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const applyDisabled =
      this.state.renderPage && this.determineApplyDisabled();
    return (
      this.state.renderPage && (
        <div>
          <div className={styles.subHeader}>
            {this.props.t("OptIn.subHeader")}
          </div>

          <HasPermissionTo
            perform={["variableMarketing:view"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() =>
              this.getOptInPanel(
                this.props.t("VariableMarketing.prompt"),
                this.props.t("VariableMarketing.secondPrompt"),
                "vmStatus",
                this.state.vmStatus,
                "vmYes",
                "vmNo",
                this.handleOptInChange,
                "variableMarketing:write"
              )
            }
          />

          <HasPermissionTo
            perform={["aldPricing:view"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => (
              <>
                {this.getOptInPanel(
                  this.props.t("ALDPricing.prompt"),
                  this.props.t("ALDPricing.secondPrompt"),
                  "aldStatus",
                  this.state.aldStatus,
                  "aldYes",
                  "aldNo",
                  this.handleOptInChange,
                  "aldPricing:write"
                )}

                {this.state.aldStatus === "aldYes" && (
                  <div
                    className={`${styles.aldStatusDropdown} pagePaddingLeft pagePaddingRight`}
                  >
                    <div className={styles.cupidIdWrapper}>
                      <span className={styles.cupidIdLabel}>
                        {this.props.t("ALDPricing.aldCupidId")}
                      </span>
                      <span className={styles.cupidIdValue}>
                        {this.state.aldCupidId}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          />
          <HasPermissionTo
            perform={["gpisPricing:view"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => (
              <>
                {this.getOptInPanel(
                  this.props.t("OptIn.gpisHeader"),
                  this.props.t("OptIn.gpisSubHeader"),
                  "gpisStatus",
                  this.state.gpisStatus,
                  "gpisYes",
                  "gpisNo",
                  this.handleOptInChange,
                  "gpisPricing:write"
                )}
              </>
            )}
          />

          <HasPermissionTo
            perform={["optIn:write"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={!this.props.isMainViewingSDLR(this.props.user)}
            render={() => (
              <div className={styles.actionSection}>
                <Button
                  data-testid="applyOptionsBtn"
                  variant="contained"
                  onClick={(event) => {
                    this.saveOptIn(event);
                  }}
                  disabled={applyDisabled}
                  className={
                    applyDisabled ? styles.disableButton : styles.applyBtn
                  }
                  component="button"
                >
                  <span>
                    {applyDisabled
                      ? IS_SWISS_MARKET(this.props.user.market)
                        ? this.props.t("OptIn.disabledApplySingular")
                        : this.props.t("OptIn.disabledApply")
                      : this.props.t("OptIn.apply")}
                  </span>
                </Button>
              </div>
            )}
          />

          <PendingActionPrompt
            {...this.props}
            disabled={this.optInDisablePAPrompt}
            dealersActioned={
              this.optInDisablePAPrompt
                ? []
                : this.optInPAStatusForDealers[0].filter(
                    (dealer) => dealer.pendingActionStatus
                  )
            }
            dealersNotActioned={
              this.optInDisablePAPrompt
                ? []
                : this.optInPAStatusForDealers[0].filter(
                    (dealer) => !dealer.pendingActionStatus
                  )
            }
            title={this.props.t("PendingActionPrompt.gpisPricing")}
            buttonTitle={this.props.t("PendingActionPrompt.gpisPricingButton")}
            onClose={this.handleOptInPendingActionPrompt}
            open={this.state.optInPendingActionPrompt}
            applyAll={this.optInPAPromptApplyAll}
            applySome={this.optInPAPromptApplySelective}
          />
        </div>
      )
    );
  }
}

export default withTranslation("emp")(OptIn);
