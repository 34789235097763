import React from "react";
import { withTranslation } from "react-i18next";
import styles from "./CustomDropdown.module.scss";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

const CustomDropdown = (props) => {
  const FordSelect = withStyles({
    root: {
      width: 290,
      textAlign: "left",
      borderRadius: 3,
      border: "solid 1px #4d4d4d",
      backgroundColor: "white",
      fontFamily: "FordAntenna-Regular, sans-serif",
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: 1,
      color: "#102b4e",
      paddingLeft: 10,
    },
  })(Select);

  const FordIcon = withStyles({
    root: {
      top: "calc(50% - 12px)",
      right: 0,
      color: "#102b4e",
      position: "absolute",
      paddingRight: 10,
      cursor: "pointer",
      pointerEvents: "none",
    },
  })(KeyboardArrowDown);

  const LincolnSelect = withStyles({
    root: {
      width: 200,
      textAlign: "left",
      borderRadius: 3,
      border: "solid 1px #919191",
      backgroundColor: "white",
      fontFamily: "ProximaNova, sans-serif",
      fontSize: 13,
      lineHeight: "25px",
      color: "#324047",
      paddingLeft: 10,
    },
  })(Select);

  const LincolnIcon = withStyles({
    root: {
      top: "calc(50% - 12px)",
      right: 0,
      color: "#324047",
      position: "absolute",
      paddingRight: 10,
      cursor: "pointer",
      pointerEvents: "none",
    },
  })(KeyboardArrowDown);

  const dropDownValues = props.dropDownValues;

  const StyledSelect = props.isFordBrand ? FordSelect : LincolnSelect;
  const StyledIcon = props.isFordBrand ? FordIcon : LincolnIcon;

  return (
    <div id="dropdown" className={styles.dropdownDiv}>
      <FormControl id="menu">
        <StyledSelect
          name="customDropdown"
          id="select"
          data-testid="customDropdown"
          className={styles.dropdown}
          IconComponent={() => <StyledIcon />}
          value={props.value}
          onChange={props.onChange}
        >
          {Object.keys(dropDownValues).map((key) => {
            return (
              <MenuItem key={key} value={key}>
                {dropDownValues[key]}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default withTranslation("emp")(CustomDropdown);
