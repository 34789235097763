import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class DealerNotificationsClient {
  static baseUrl = BASE_URL;

  static getDealerNotifications(user, currentPage) {
    const url = `${
      this.baseUrl
    }/dealer/notifications?dealerCommonIds=${user.getCommonIdsWithSDLR()}&currentPage=${currentPage}`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static markNotificationStatusRead(user, id) {
    const url = `${this.baseUrl}/dealer/notifications/${id}`;

    return axios
      .post(url, "", getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static deleteNotification(user, id) {
    const url = `${this.baseUrl}/dealer/notifications/${id}`;

    return axios
      .delete(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }
}
