export const TERMS_AND_CONDITIONS = "TERMS_CONDITIONS";

export const PRICING = "PRICING";

export const RESERVATION_DEPOSIT = "RESERVATION_DEPOSIT";

export const STORE_ID = "STORE_ID";

export const MERCHANT_ID = "MERCHANT_ID";

export const ACCESSORIES_PRICING = "ACCESSORIES_PRICING";

export const DELIVERY_BANDS = "DELIVERY_BANDS";

export const VARIABLE_MARKETING = "VM";

export const OPT_IN = "OPT_IN";

export const DEFAULT_VEHICLE = "RANGER";

export const DEFAULT_YEAR = "2022";

export const GPIS_PRICING = "GPIS_PRICING";

export const NEW_VEHICLE_LINE = "NEW_VEHICLE_LINE";

export const ALD_PRICING = "ALD_PRICING";

export const PAYMENT_THRESHOLD = "PAYMENT_THRESHOLD";

export const CUPID_ID = "CUPID_ID";

export const LEAD_TIMES = "LEAD_TIMES";

export const WALLBOX = "WALLBOX";

export const CUSTOMER_CONTRACT = "CUSTOMER_CONTRACT";

export const US_MACHE_CATALOG_ID = "WANAB-CGW-2021-CX727";

export const US_BRONCO_CATALOG_ID = "WANAB-TG1-2021-Bronco";

export const US_BRONCO_SPORT_CATALOG_ID = "WANAB-CHD-2021-BroncoSport";

export const ENGLISH = "en";

export const THAILAND_ENGLISH = "en_THA";

export const THAILAND_THAI = "th_THA";

export const PAGE = "page";

export const BRAND = "brand";

export const SALESCODE_KEY = "sales.code";

export const ADFS_TOKEN = "adfs.token";

export const MARKET = "market";

export const CASH = "cash";

export const FINANCE = "finance";

export const LEASE = "lease";

export const OFFLINE_LEASE = "offline_lease";

export const OFFLINE_FINANCE = "offline_finance";

export const OFFLINEFINANCE = "offline finance";

export const DEALER = "DEALER";

export const MACHE = "MUSTANG MACH-E";

export const FINANCE_PRODUCT_TYPE = {
  FINANCE: "finance",
  LEASE: "lease",
  FORD_OPTIONS: "ford options",
  CASH: "cash",
  THIRD_PARTY: "finance",
  BUSINESS_CONTRACT_HIRE: "lease",
  OTHER: "other financing options",
};

export const DELIVERY_FEE = "deliveryFee";

export const FIRST_REG_FEE = "firstRegFee";

export const CITY_FEE = "cityFee";

export const DEALER_HANDLING_FEE = "handlingFee";

export const ADDITIONAL_FEES = "additionalFees";

export const NUMBER_PLATE = "numberPlates";

export const DELIVERY = "delivery";

export const CANCELLED = "CANCELLED";

export const ACCEPTED = "ACCEPTED";

export const SUBMITTED = "SUBMITTED";

export const OFFLINEDOCSIGN = "offlineDocsign";

export const PARTIALOFFLINE = "partialOfflinePriceUpdate";

export const FINANCED = "FINANCED";

export const LEASED = "LEASED";

export const OWNED = "OWNED";

export const INCLUDED = "included";

export const ACCESSORIES_VERSION = {
  GBR: 1,
  FRA: 1,
  NOR: 3,
  NLD: 1,
  CHE: 1,
  DEU: 1,
  ESP: 3,
  ITA: 3,
  AUT: 3,
  BEL: 3,
  FIN: 3,
  DNK: 3,
};

// add new language for img markets eg. en_AU
export const LANGUAGE_MARKET_MAP = {
  GBR: "en_GB",
  FRA: "fr",
  NOR: "no",
  NLD: "nl",
  CHE: "de_CH",
  DEU: "de",
  ESP: "es_ES",
  ITA: "it_IT",
  AUT: "de_AT",
  BEL: "fr_BE",
  FIN: "fi_FI",
  DNK: "da_DK",
  USA: "en_US",
  CAN: "en_CA",
  AUS: "en_AU",
  NZL: "en_NZ",
  ZAF: "en_ZA",
  THA: "th_TH",
  VNM: "vi_VN",
  PHL: "ph_PH",
  BRA: "pt_BR",
  ARG: "es_AR",
};

export const FEES_MAP = {
  GBR: [
    {
      key: "roadFundLicense",
      name: "DealerFees.roadFundLicense",
      isEditable: false,
    },
    {
      key: "firstRegFee",
      name: "DealerFees.firstRegFee",
      isEditable: false,
    },
    {
      key: "numberPlates",
      name: "DealerFees.numberPlates",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  ESP: [
    {
      key: "cityFee",
      name: "DealerFees.cityFee",
      isEditable: true,
    },
    {
      key: "handlingFee",
      name: "DealerFees.handlingFee",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  ITA: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  AUT: [
    {
      key: "firstRegFee",
      name: "DealerFees.regFee",
      isEditable: true,
    },
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  BEL: [
    {
      key: "handlingFee",
      name: "DealerFees.handlingFee",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  FIN: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  DNK: [
    {
      key: "numberPlates",
      name: "DealerFees.numberPlates",
      isEditable: false,
    },
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: false,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  DEU: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: true,
    },
    {
      key: "firstRegFee",
      name: "DealerFees.regFee",
      isEditable: true,
    },
    {
      key: "numberPlates",
      name: "DealerFees.numberPlates",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  FRA: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFee",
      isEditable: true,
    },
    {
      key: "firstRegFee",
      name: "DealerFees.regFee",
      isEditable: true,
    },
    {
      key: "numberPlates",
      name: "DealerFees.numberPlates",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  NOR: [
    {
      key: "deliveryFee",
      name: "DealerFees.deliveryFee",
      isEditable: true,
    },
    {
      key: "firstRegFee",
      name: "DealerFees.regFee",
      isEditable: true,
    },
    {
      key: "scrapFee",
      name: "DealerFees.scrapFee",
      isEditable: false,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  NLD: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFeeInclReg",
      isEditable: false,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
  CHE: [
    {
      key: "deliveryFee",
      name: "DealerFees.handlingAndDeliveryFeeInclReg",
      isEditable: true,
    },
    {
      key: "additionalFees",
      name: "DealerFees.additionalFees",
      isEditable: true,
    },
  ],
};

export const BEV_VEHICLES = ["MUSTANG MACH-E"];

export const TRANSIT_VEHICLES = "TRANSIT CUSTOM";

export const SRP_PRICE_TYPE = "SRP";

export const CHECURRENCY = "CHF";

export const PURCHASE_REQUEST_ACCEPTED = "purchaserequestaccepted";

export const PURCHASE_TYPES = {
  CASH: "CASH",
  CONTINUE_AT_DEALER: "CONTINUE_AT_DEALER",
  CREDIT_APPLICATION: "CREDIT_APPLICATION",
  DISCUSS_WITH_DEALER: "DISCUSS_WITH_DEALER",
  SIGN_AT_DEALER: "SIGN_AT_DEALER",
  SIGN_OFFLINE: "SIGN_OFFLINE",
  SIGN_ONLINE: "SIGN_ONLINE",
  THIRD_PARTY: "THIRD_PARTY",
  DENIED_CREDIT_APPLICATION: "DENIED_CREDIT_APPLICATION",
  PENDING_CREDIT_APPLICATION: "PENDING_CREDIT_APPLICATION",
};

export const PURCHASE_REQUEST_STATUS = {
  CREATED: "CREATED",
  SOLD: "SOLD",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
};

export const COMPLETED = "COMPLETED";
// Progress Tracker
// 0: Order Placed, 1: In Production, 2: Shipping, 3: Delivery
export const ORDER_STATUS = {
  1: ["strt", "vdos", "vshp", "prod", "iprl", "grta", "vasc", "vitr"],
  2: [
    "vprt",
    "vhlg",
    "vetl",
    "vmod",
    "vcrb",
    "vand",
    "vcmp",
    "vehs",
    "vafd",
    "vphd",
  ],
};
//0: Pre-Order Placed, 1: Order Placed, 2: In Production, 3: On Its Way, 4: Delivered
export const RESERVATION_STATUS = {
  2: ["strt", "vdos", "vshp", "prod", "iprl", "grta", "vasc", "vitr"],
  3: [
    "vprt",
    "vhlg",
    "vetl",
    "vmod",
    "vcrb",
    "vand",
    "vcmp",
    "vehs",
    "vafd",
    "vphd",
  ],
};
export const REGEX_LIST = {
  number: /^\d*\.?(\.\d{1,2})?$/,
  phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  zip: /^\d{5}(-\d{4})?$/,
};

export const DEALER_SETUP =
  "https://ecommdealersetup.dealerconnection.com/vue/dashboard";
export const RESOURCE_CENTER =
  "https://ecommdealersetup.dealerconnection.com/vue/tools";
export const MARKET_LANGUAGES = {
  BEL: ["nl", "fr"],
  CHE: ["fr", "de"],
  CAN: ["en", "fr"],
  THA: ["en", "th"],
};
export const LANCODES = {
  en: "ENGLISH",
  fr: "FRENCH",
  de: "GERMAN",
  nl: "DUTCH",
  no: "NORWEGIAN",
  es: "SPANISH",
  it: "ITALIAN",
  fi: "FINNISH",
  da: "DANISH",
  pt: "PORTUGUESE",
  th: "THAI",
  vi: "VIETNAMESE",
  ph: "FILIPINO",
};

// Regions for translation tool, added New market under IMG
export const TRANSLATION_TOOL_REGIONS = {
  EU: [
    "GBR",
    "DNK",
    "AUT",
    "DEU",
    "CHE",
    "ESP",
    "FIN",
    "BEL",
    "FRA",
    "ITA",
    "NLD",
    "NOR",
  ],
  CAN: ["CAN"],
  IMG: ["VNM", "ZAF", "THA", "PHL", "AUS", "NZL", "BRA", "ARG"],
};

// Added for payment threshold validation
export const PAYMENT_THRESHOLD_LIMIT = {
  VNM: { maxLimit: "999999999.99", maxLimitFormatted: "999,999,999.99" },
  ZAF: { maxLimit: "99999.99", maxLimitFormatted: "99,999.99" },
  THA: { maxLimit: "99999.99", maxLimitFormatted: "99,999.99" },
  PHL: { maxLimit: "99999.99", maxLimitFormatted: "99,999.99" },
  AUS: { maxLimit: "9999.99", maxLimitFormatted: "9,999.99" },
  NZL: { maxLimit: "9999.99", maxLimitFormatted: "9,999.99" },
};

export const AT_YARD = "AtYard";
export const PRODUCED_IN_TRANSIT = "ProducedInTransit";
export const ORDER_SCHEDULING = "OrderScheduling";
