import React, { Component } from "react";
import { Button, Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidationRules from "../../../../common/ValidationRules";
import NscDeliveryRangesRow from "./deliveryRangesRow/NscDeliveryRangesRow";
import styles from "./NscDeliveryRanges.module.scss";
import Item from "../../../../shared/item/Item";
import { withTranslation } from "react-i18next";
import ConfirmDialog from "../../../../shared/confirmDialog/ConfirmDialog";
import NscDeliveryRowDisplay from "./deliveryRangesRow/NscDeliveryRowDisplay";
import { InfoIconWithMessage } from "../../../../shared/infoIcon/InfoIcon";
import { DELIVERY_BANDS } from "../../../../common/Constants";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import DeliveryRangesClient from "../../../../shared/clients/DeliveryRangesClient";
import PendingActionClient from "../../../../shared/clients/PendingActionClient";

class NscDeliveryRanges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultNoOfRows: 2,
      ranges: [],
      currentNoOfRows: 2,
      openDialog: false,
      rangesSaved: false,
      dataLoaded: false,
      deliveryRangesApplied: false,
    };

    this.maxNoOfRows = 5;
    this.currencySymbol =
      this.props.localizedInfo && this.props.localizedInfo.defaultCurrency
        ? this.props.localizedInfo.defaultCurrency.symbol
        : "NA";
    this.distanceUnit =
      this.props.localizedInfo && this.props.localizedInfo.defaultDistanceUnit
        ? this.props.localizedInfo.defaultDistanceUnit
        : "NA";

    this.deleteRangeDialogProps = {
      title: this.props.t("nscDeliveryBands.deleteRangeTitle"),
      bodyText: this.props.t("nscDeliveryBands.deleteRangeBody"),
      confirm: this.props.t("nscDeliveryBands.deleteRangeConfirm"),
      cancel: this.props.t("nscDeliveryBands.deleteRangeCancel"),
    };

    this.handleTextOnChange = this.handleTextOnChange.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("checkingRows", (fromVal, currentRow) => {
      let isValid = true;
      if (currentRow < 1 || this.state.ranges[currentRow - 1].to === null) {
        isValid = true;
      } else if (
        this.state.ranges[currentRow - 1].to === "" ||
        isNaN(this.state.ranges[currentRow - 1].to) ||
        fromVal === ""
      ) {
        isValid = true;
      } else if (
        parseInt(fromVal) !==
        parseInt(this.state.ranges[currentRow - 1].to) + 1
      ) {
        isValid = false;
      }

      return isValid;
    });

    /**
     * Checking the input value is zero or noe
     * Validation Fails - if the input value has other than 0-9
     */
    ValidatorForm.addValidationRule("shouldBeZero", (value, index) => {
      // eslint-disable-next-line
            if (index != 0) return true;
      const re = /^[0\b]+$/;
      let isValid = true;
      if (value !== "" && !re.test(value)) {
        isValid = false;
      }
      return isValid;
    });

    ValidationRules();
    this.fetchRanges();
  }

  handleDeleteAction = () => this.setState({ openDialog: true });

  handleTextOnChange(event) {
    const ranges = JSON.parse(JSON.stringify(this.state.ranges));

    if (event.target.name === "rangeFrom" + event.target.id) {
      ranges[event.target.id].from = event.target.value;
      this.setState({ ranges: ranges });
    } else if (event.target.name === "rangeTo" + event.target.id) {
      ranges[event.target.id].to = event.target.value;
      const textEvent = event.target;
      this.setState(
        {
          ranges: ranges,
        },
        () => {
          if (!isNaN(textEvent.value)) {
            this.autoIncrementNxtRowFromValue(textEvent.id);
          }
        }
      );
    } else if (event.target.name === "rangeValue" + event.target.id) {
      ranges[event.target.id].value = event.target.value;
      this.setState({ ranges: ranges });
    }
  }

  handleSubmit = () => {
    let isValidInput = true;
    const ranges = JSON.parse(JSON.stringify(this.state.ranges));
    const length = ranges.length;
    for (let index = 0; index < length; ++index) {
      const to = ranges[index].to;
      if (to && isNaN(to) && to.toUpperCase() === "OVER") {
        if (index !== length - 1) {
          ranges[index].to = null;
          isValidInput = false;
          break;
        } else {
          ranges[index].to = -1;
        }
      }
      if (ranges[index].to !== null && ranges[index].to !== "") {
        ranges[index].to = parseInt(ranges[index].to);
      }
      ranges[index].from = parseInt(ranges[index].from);
      ranges[index].value = parseFloat(ranges[index].value);
    }

    this.setState({ ranges: ranges });

    isValidInput &&
      this.saveDeliveryRanges(this.state.ranges)
        .then((status) => {
          if (status === 200) {
            this.props.hideOrShow(false);
            PendingActionClient.removeNscPendingAction(
              this.props.user,
              DELIVERY_BANDS,
              1
            );
            this.setState({ rangesSaved: true, deliveryRangesApplied: true });
          }
        })
        .catch(() => {
          this.setState({
            deliveryRangesApplied: false,
          });
        });
  };

  fetchRanges() {
    this.props.hideOrShow(true);
    DeliveryRangesClient.getDeliveryRanges(this.props.user)
      .then((ranges) => {
        if (ranges.length > 0) {
          this.setState({
            currentNoOfRows: ranges.length,
            ranges: ranges,
            rangesSaved: true,
          });
        } else {
          this.setState({
            ranges: [
              { from: "0", to: "", value: "" },
              { from: "", to: "", value: "" },
            ],
          });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({
          dataLoaded: true,
        });
      });
  }

  async saveDeliveryRanges(data) {
    this.props.hideOrShow(true);
    return await DeliveryRangesClient.saveDeliveryRanges(data, this.props.user);
  }

  addRow = () => {
    if (this.state.currentNoOfRows === this.maxNoOfRows) {
      return;
    }
    const ranges = JSON.parse(JSON.stringify(this.state.ranges));
    ranges.push({ from: "", to: "", value: "" });

    this.setState(
      {
        ranges,
        currentNoOfRows: ranges.length,
      },
      () => {
        const ranges = JSON.parse(JSON.stringify(this.state.ranges));
        let previousRowToVal = ranges[ranges.length - 2].to;
        if (
          previousRowToVal !== null &&
          previousRowToVal !== "" &&
          !isNaN(previousRowToVal)
        ) {
          ranges[ranges.length - 1].from = ++previousRowToVal;
          this.setState({ ranges: ranges });
        }
      }
    );
  };

  deleteRow = () => {
    if (this.state.currentNoOfRows === this.state.defaultNoOfRows) {
      return;
    }

    const dataObjRow = JSON.parse(JSON.stringify(this.state.ranges));
    let rangeRows = this.state.currentNoOfRows;

    dataObjRow.pop(dataObjRow.length - 1);

    this.setState({
      ranges: dataObjRow,
      currentNoOfRows: --rangeRows,
      openDialog: false,
    });
  };

  autoIncrementNxtRowFromValue(rowId) {
    const ranges = JSON.parse(JSON.stringify(this.state.ranges));
    let rowValue = ranges[rowId].to;
    const currentRow = parseInt(rowId);

    if (currentRow + 1 < this.state.currentNoOfRows) {
      if (rowValue !== "") {
        ranges[currentRow + 1].from = ++rowValue;
      } else {
        ranges[currentRow + 1].from = null;
      }
    }

    this.setState({ ranges: ranges });
  }

  render() {
    return (
      this.state.dataLoaded && (
        <section className="pageWrapper">
          <div className="pagePaddingLeft pagePaddingTop">
            <InfoIconWithMessage
              message={this.props.t("nscDeliveryBands.subheader")}
            />
          </div>

          <ConfirmDialog
            open={this.state.openDialog}
            dialogProps={this.deleteRangeDialogProps}
            onConfirm={this.deleteRow}
            onCancel={() => this.setState({ openDialog: false })}
            onClose={() => this.setState({ openDialog: false })}
            boldTitle={false}
            {...this.props}
          />

          <div className="pagePaddingTop">
            <Grid
              container
              spacing={0}
              className={`${styles.divider} pagePaddingLeft`}
            >
              <Grid item xs={3} className={styles.rowHeader}>
                <div className={styles.headerWrap}>
                  <Item
                    value={
                      this.props.t("nscDeliveryBands.bands") +
                      "  (" +
                      this.distanceUnit +
                      ")"
                    }
                  />
                </div>
              </Grid>
              <Grid
                item
                style={{ justifyContent: "flex-end" }}
                xs={5}
                className={styles.rowHeader}
              >
                <div className={styles.headerWrap}>
                  <Item
                    value={
                      this.props.t("nscDeliveryBands.charge") +
                      "  (" +
                      this.currencySymbol +
                      ")"
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={4} />
            </Grid>
          </div>

          {this.state.rangesSaved ? (
            <div>
              {this.state.ranges.map((range, index) => {
                return (
                  <NscDeliveryRowDisplay
                    id="deliveryDisplay"
                    range={range}
                    key={index}
                  />
                );
              })}
              <div className={styles.pricesApplied}>
                <span>{this.props.t("nscDeliveryBands.applied")}</span>
              </div>
            </div>
          ) : (
            <HasPermissionTo
              perform={["deliveryRanges:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => (
                <ValidatorForm onSubmit={this.handleSubmit}>
                  {this.state.ranges.map((item, index) => {
                    return (
                      <NscDeliveryRangesRow
                        key={index}
                        dataObjItem={item}
                        action={this.handleTextOnChange}
                        deleteAction={this.handleDeleteAction}
                        defaultNoOfRows={this.state.defaultNoOfRows}
                        currentNoOfRows={this.state.currentNoOfRows}
                        currentRow={index}
                      />
                    );
                  })}

                  <div>
                    {this.state.currentNoOfRows === this.maxNoOfRows ? (
                      <p className={styles.max}>
                        {this.props.t("nscDeliveryBands.maxRows")}
                      </p>
                    ) : (
                      <Button
                        id="addDeliveryRangeBtn"
                        className={styles.add}
                        onClick={this.addRow}
                        component="button"
                      >
                        {this.props.t("nscDeliveryBands.add")}
                      </Button>
                    )}
                  </div>
                  <div className={styles.actionSection}>
                    <Button
                      data-testid="APPLY-BUTTON"
                      type="submit"
                      className={styles.saveBtn}
                      component="button"
                      disabled={this.state.deliveryRangesApplied}
                    >
                      {this.props.t("nscDeliveryBands.submit")}
                    </Button>
                  </div>
                </ValidatorForm>
              )}
            />
          )}
        </section>
      )
    );
  }
}

export default withTranslation("emp")(NscDeliveryRanges);
