import React, { Component } from "react";
import { Button, Dialog } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import styles from "./PendingActionPrompt.module.scss";
import withStyles from "@material-ui/core/styles/withStyles";

const StyledDialog = withStyles({
  paper: {
    width: 600,
    maxWidth: 600,
  },
})(Dialog);

const StyledGrid = withStyles({
  root: {
    width: 600,
    maxWidth: 600,
  },
  container: {
    width: 600,
    maxWidth: 600,
  },
})(Grid);

class PendingActionPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
  }

  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open });
    }
  }

  render() {
    return (
      !this.props.disabled && (
        <StyledDialog
          open={this.state.open}
          onClose={this.props.onClose}
          data-testid="pendingActionPrompt"
        >
          <StyledGrid
            container
            className={`pagePadding ${styles.container}`}
            justify="center"
          >
            <Grid item xs={10} className={styles.title}>
              <div>{this.props.t("PendingActionPrompt.title")}</div>
            </Grid>
            {this.props.showDeclineWarning && (
              <Grid item xs={10}>
                <div className={styles.warning}>
                  {this.props.t("BusinessSettings.bodyTextDecline")}
                </div>
              </Grid>
            )}
            {this.props.dealersActioned.length > 0 && (
              <Grid item xs={10}>
                <div className={styles.subheader}>
                  {this.props.customText ? (
                    this.props.customText["actioned"]
                  ) : (
                    <div>
                      {this.props.t("PendingActionPrompt.appliedBranches")}
                      {this.props.title}
                      {this.props.t("PendingActionPrompt.appliedBranches2")}
                    </div>
                  )}
                </div>
              </Grid>
            )}
            {this.props.dealersActioned.length > 0 && (
              <Grid item xs={10}>
                <Grid container className={styles.dealerContainer}>
                  {this.props.dealersActioned.map((dealer) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        data-testid={`${dealer.dealerId}_actioned`}
                        key={dealer.dealerId}
                        className={styles.dealerName}
                      >
                        {dealer.dealerName} {dealer.termsStatus}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {this.props.dealersNotActioned.length > 0 && (
              <Grid item xs={10}>
                <div className={styles.subheader}>
                  {this.props.customText ? (
                    this.props.customText["notActioned"]
                  ) : (
                    <div>
                      {this.props.t("PendingActionPrompt.notAppliedBranches")}
                      {this.props.title}
                      {this.props.t("PendingActionPrompt.notAppliedBranches2")}
                    </div>
                  )}
                </div>
              </Grid>
            )}
            {this.props.dealersNotActioned.length > 0 && (
              <Grid item xs={10}>
                <Grid container className={styles.dealerContainer}>
                  {this.props.dealersNotActioned.map((dealer) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        key={dealer.dealerId}
                        data-testid={`${dealer.dealerId}_notActioned`}
                        className={styles.dealerName}
                      >
                        {dealer.dealerName}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            <Grid item xs={10} className={styles.actionButtons}>
              <Button
                onClick={this.props.onClose}
                className={styles.cancelBtn}
                variant="contained"
              >
                {this.props.t("PendingActionPrompt.cancel")}
              </Button>
            </Grid>
            {this.props.dealersNotActioned.length > 0 &&
              this.props.dealersActioned.length > 0 && (
                <Grid item xs={10} className={styles.actionButtons}>
                  <Button
                    className={styles.applyBtn}
                    onClick={() => this.props.applySome()}
                    variant="contained"
                  >
                    {this.props.customText ? (
                      this.props.customText["applySelective"]
                    ) : (
                      <div>
                        {this.props.t("PendingActionPrompt.applySelective")}{" "}
                        {this.props.buttonTitle}
                      </div>
                    )}
                  </Button>
                </Grid>
              )}
            <Grid item xs={10} className={styles.actionButtons}>
              <Button
                className={styles.applyBtn}
                onClick={() => this.props.applyAll()}
                variant="contained"
              >
                {this.props.t("PendingActionPrompt.applyAll")}
              </Button>
            </Grid>
          </StyledGrid>
        </StyledDialog>
      )
    );
  }
}

export default PendingActionPrompt;
