import React from "react";
import { Dialog, MenuItem, withStyles } from "@material-ui/core";
import "../../styles/sharedStyles.scss";
import Grid from "@material-ui/core/Grid";
import Clear from "@material-ui/icons/Clear";
import cancelOrderStyles from "./CancelOrderDialog.module.scss";
import subNavStyles from "../../shared/subnav/subNavStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import CheckBox from "../../shared/checkBox/CheckBox";
import checkboxStyles from "../../shared/checkBox/CheckBox.module.scss";
import { KeyboardArrowDown } from "@material-ui/icons";
import { CANCELLED } from "../../common/Constants";
import CustomerReservationsClient from "../../shared/clients/CustomerReservationsClient";
import UpperCaseText from "../../utils/UpperCaseText";
import { IS_NA_MARKET } from "../../utils/EmpUtil";

const StyledSelect = withStyles({
  select: {
    width: "100%",
    boxSizing: "border-box",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 14,
    color: "#4a4a4a",
    lineHeight: 1.57,
    letterSpacing: 1.5,
    fontFamily: "FordAntenna-Regular, sans-serif",
  },
})(Select);

const StyledClear = withStyles({
  root: {
    width: 27,
    height: 27,
    fill: "#102b4e",
    align: "right",
  },
})(Clear);

const StyledMenuItem = withStyles({
  root: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    margin: 0,
    fontSize: 14,
    color: "#102b4e",
    lineHeight: 1.57,
    letterSpacing: 1.5,
    fontFamily: "FordAntenna-Regular, sans-serif",
    "&:hover": {
      backgroundColor: "#102b4e",
      color: "white",
    },
  },
})(MenuItem);

const StyledDialog = withStyles({
  paper: {
    width: 700,
    maxWidth: 700,
  },
})(Dialog);

const StyledGrid = withStyles({
  root: {
    width: 700,
    maxWidth: 700,
  },
  container: {
    width: 700,
    maxWidth: 700,
  },
})(Grid);

class CancelOrderDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownValue: "",
      checkBox: false,
      cancellationReasons: [],
      isOtherReasonAvailable: false,
    };

    this.otherReasonCode = "RC_OTHER";
    this.otherReasonDisplayText = "Other";

    this.cancelOrder = "REQUEST FROM CUSTOMER TO CANCEL ORDER";
    this.cancelReservation = "Request from customer to cancel reservation";

    this.translations = {
      "REQUEST FROM CUSTOMER TO CANCEL RESERVATION":
        "Demande d’annulation de la réservation par le client",
      OTHER: "Autre",
      "UNABLE TO CONTACT CUSTOMER": "Impossible de contacter le client",
      "INABILITY TO COMPLETE PURCHASE": "Incapacité d’effectuer l’achat",
      "PURCHASE OF UNAUTHORIZED RE-SELLER": "Achat d’un revendeur non autorisé",
    };
  }

  componentDidMount() {
    if (this.props.showDropdown) {
      this.getCancelOrderReasons();
    }
  }

  handleConfirm = () => {
    this.props.isRefund
      ? this.props.handleConfirm()
      : this.props.purchaseRequestCancel
      ? this.props.handleConfirm(this.state.dropDownValue)
      : IS_NA_MARKET(this.props.user.market)
      ? this.props.handleConfirm(CANCELLED, this.state.dropDownValue)
      : this.props.handleConfirm(CANCELLED, null, this.state.dropDownValue);
  };

  changeConfirmButtonState = () => {
    this.setState({
      checkBox: !this.state.checkBox,
    });
  };

  getMenuItem(key, message) {
    if (
      UpperCaseText(this.props.orderType) === "RESERVATION" &&
      UpperCaseText(message) === this.cancelOrder
    ) {
      message = this.cancelReservation;
    }
    if (
      UpperCaseText(this.props.orderType) === "RESERVATION" &&
      localStorage.getItem("lang") === "fr_CA" &&
      this.translations[UpperCaseText(message)]
    ) {
      message = this.translations[UpperCaseText(message)];
    }

    if (
      UpperCaseText(key) === this.otherReasonCode &&
      UpperCaseText(message) === UpperCaseText(this.otherReasonDisplayText)
    ) {
      //SKIP loading other option in the normal loading to add at the bottom
      return null;
    }

    return this.getStyledMenuItem(key, message);
  }

  getStyledMenuItem(key, message) {
    return (
      <StyledMenuItem
        data-testid={key}
        value={IS_NA_MARKET(this.props.user.market) ? message : key}
        key={key}
      >
        <div className={cancelOrderStyles.menuItems}>{message}</div>
      </StyledMenuItem>
    );
  }

  getCancelOrderReasons() {
    CustomerReservationsClient.getCancelOrderReasons(
      this.props.token,
      this.props.lang,
      this.props.purchaseRequestCancel,
      false,
      !this.props.purchaseRequestCancel
    ).then((response) => {
      if (
        response.cancellationReasons.filter(
          (reason) => reason.code === this.otherReasonCode
        ).length === 1
      ) {
        this.setState({ isOtherReasonAvailable: true });
      }
      this.setState({ cancellationReasons: response.cancellationReasons });
    });
  }

  updateDropdown = (event) => {
    this.setState({ dropDownValue: event.target.value });
  };

  render() {
    return (
      <StyledDialog
        open={this.props.open}
        className={cancelOrderStyles.dialog}
        onClose={this.props.onClose}
      >
        <StyledGrid
          container
          data-testid="cancelOrderDialog"
          className={cancelOrderStyles.dialogContainer}
          justify="center"
        >
          <Grid item md={12} className={cancelOrderStyles.cancel}>
            <div>
              <button data-testid="closeDialog" onClick={this.props.onClose}>
                <StyledClear />
              </button>
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={cancelOrderStyles.dialogTitle}>
              <span className={cancelOrderStyles.warning}></span>
              <div>{this.props.dialogProps.title}</div>
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={cancelOrderStyles.dialogDescription}>
              {this.props.dialogProps.body}
            </div>
          </Grid>

          {this.props.showDropdown && (
            <Grid item md={9} className={cancelOrderStyles.dropdown}>
              <div className={cancelOrderStyles.dropdownDiv}>
                <div className={cancelOrderStyles.cancelReason}>
                  {this.props.t("CustomerOrderDetails.reasonForCancelling")}
                </div>
                <FormControl id="cancelOrderDropDown" fullWidth={true}>
                  <StyledSelect
                    IconComponent={() => (
                      <KeyboardArrowDown style={subNavStyles.icon} />
                    )}
                    name="dropdown"
                    data-testid="dropdown"
                    className={cancelOrderStyles.dropdownCancelOrder}
                    value={this.state.dropDownValue}
                    onClick={(event) => this.updateDropdown(event)}
                  >
                    <StyledMenuItem value="" disabled>
                      <div>
                        {this.props.t("CustomerOrderDetails.pleaseSelect")}
                      </div>
                    </StyledMenuItem>
                    {this.state.cancellationReasons &&
                      this.state.cancellationReasons.map((item) => {
                        return this.getMenuItem(item.code, item.displayText);
                      })}
                    {this.state.isOtherReasonAvailable &&
                      this.getStyledMenuItem(
                        this.otherReasonCode,
                        this.otherReasonDisplayText
                      )}
                  </StyledSelect>
                </FormControl>
              </div>
            </Grid>
          )}

          <Grid item md={9}>
            <div className={cancelOrderStyles.checkbox}>
              <CheckBox
                boldCheck={true}
                textClass={checkboxStyles.cancelLabel}
                checked={this.state.checkBox}
                isDisabled={false}
                name="chkBxConfirm"
                id="chkBxConfirm"
                label={this.props.dialogProps.checkbox}
                labelList={this.props.dialogProps.checkboxList}
                onChange={(e) => this.changeConfirmButtonState(e)}
              />
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={cancelOrderStyles.dialogActions}>
              <Button
                className={cancelOrderStyles.goBack}
                name="cancel"
                data-testid="cancel"
                onClick={this.props.onClose}
              >
                {this.props.dialogProps.cancel}
              </Button>
              <Button
                className={cancelOrderStyles.confirmShared}
                name="confirmShared"
                data-testid="confirmShared"
                disabled={
                  this.state.checkBox === false ||
                  (this.props.showDropdown &&
                    this.state.dropDownValue === undefined) ||
                  (this.props.showDropdown && this.state.dropDownValue === "")
                }
                onClick={(e) => this.handleConfirm(e)}
              >
                {this.props.dialogProps.confirm}
              </Button>
            </div>
            {this.props.is400Error && (
              <div className={cancelOrderStyles.errorText}>
                {this.props.t("CustomerOrderDetails.cancellationError")}
              </div>
            )}
          </Grid>
        </StyledGrid>
      </StyledDialog>
    );
  }
}

export default withTranslation("emp")(CancelOrderDialog);
