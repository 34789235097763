import React from "react";
import styles from "./PricesApplied.module.scss";
import CheckIcon from "@material-ui/icons/Check";

export const PricesApplied = (props) => {
  const { success, text } = props;

  return (
    <div>
      {success && (
        <div className={styles.container}>
          <span className={styles.text}>
            {text}
            <CheckIcon fontSize="large" className={styles.icon} />
          </span>
        </div>
      )}
    </div>
  );
};
