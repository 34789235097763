import { ValidatorForm } from "react-material-ui-form-validator";

const ValidationRules = () => {
  /**
   * Checking the input value is numeric or not
   * Validation Fails - if the input value has other than 0-9
   */
  ValidatorForm.addValidationRule("isNumeric", (value) => {
    const re = /^[0-9\b]+$/;
    let isValid = true;
    if (value !== "" && !re.test(value)) {
      isValid = false;
    }
    return isValid;
  });

  /**
   * Checking the input value is positive numbers or not
   * Validation Fails - if the input value has any characters other than + or 0-9
   */
  ValidatorForm.addValidationRule("isPositiveNumber", (value) => {
    const re = /^[+]?[0-9]*(\.[0-9]{0,2})?$/;
    let isValid = true;
    if (value !== "" && !re.test(value)) {
      isValid = false;
    }
    return isValid;
  });

  /**
   * Checking the input value is positive/negative numbers or not
   * Validation Fails - if the input value has any characters other than +, -, or 0-9
   */
  ValidatorForm.addValidationRule("isPosNegNumber", (value) => {
    const re = /^[+-]?[0-9]*(\.[0-9]{0,2})?$/;
    let isValid = true;
    if (value !== "" && !re.test(value)) {
      isValid = false;
    }
    return isValid;
  });

  /**
   * Checking the input value is number with decimal or not
   * Validation Fails - if the input value has other number/decimal number
   */
  ValidatorForm.addValidationRule("isNumber", (value) => {
    let isValid = true;
    if (value !== "" && isNaN(value)) {
      isValid = false;
    }
    return isValid;
  });

  /**
   * Checking the 2nd input value is bigger than the first input value
   * Validation Fails - If the 'eleVal' input value is less than the 'value'
   */
  ValidatorForm.addValidationRule("isBigger", (eleVal, value) => {
    let isValid = true;
    /*        if (eleVal.length === 0 || isNaN(eleVal) || isNaN(value)) {
                       // isValid = true;
                    } else*/
    if (parseInt(eleVal) <= parseInt(value)) {
      isValid = false;
    }
    return isValid;
  });

  /**
   * Checking if the TO value is 'over' or 'OVER'
   * Validation Fails - If the value input is not equal
   */
  ValidatorForm.addValidationRule("isOverText", (eleValue, value) => {
    let isValid = true;

    if (value && isNaN(value) && value.toUpperCase() !== "OVER") {
      isValid = false;
    }
    return isValid;
  });

  /**
   * Checking if the field is empty on a particular row only if that row is not the last row
   * eleValue - value for checking empty or not
   * params[] - params[0] accepts the currentRow and params[1] accepts current Number of Rows
   * Validation Fails - if the value is empty and if its not the last row
   */
  ValidatorForm.addValidationRule("requiredToValue", (eleValue, params) => {
    let isValid = true;

    if (eleValue.length <= 0 && parseInt(params[0]) + 1 < parseInt(params[1])) {
      isValid = false;
    }
    return isValid;
  });

  ValidatorForm.addValidationRule(
    "isNumericWithTwoDecimalPlaces",
    (eleValue) => {
      const re = /^[0-9]*(\.[0-9]{0,2})?$/;

      let isValid = true;
      if (eleValue !== "" && !re.test(eleValue)) {
        isValid = false;
      }
      return isValid;
    }
  );

  ValidatorForm.addValidationRule(
    "isSignedFloatWithTwoDecimalPlaces",
    (eleValue) => {
      const re = /^(\+|-)?[0-9]*(\.[0-9]{1,2})?$/;

      let isValid = true;
      if (eleValue !== "" && !re.test(eleValue)) {
        isValid = false;
      }
      return isValid;
    }
  );

  ValidatorForm.addValidationRule(
    "SignRequiredForFloatWithTwoDecimalPlaces",
    (eleValue) => {
      const re = /^(((0)*(\.(0){1,2})?)|((\+|-)[0-9]*(\.[0-9]{1,2})?))$/;

      let isValid = true;
      if (eleValue !== "" && !re.test(eleValue)) {
        isValid = false;
      }
      return isValid;
    }
  );

  ValidatorForm.addValidationRule(
    "LeadTimesFirstLessThanSecond",
    (eleValue) => {
      if (eleValue.length !== 5 || eleValue[2] !== "-") return false;
      const firstNum = eleValue.substring(0, 2);
      const secondNum = eleValue.substring(3);
      if (isNaN(firstNum) || isNaN(secondNum)) return false;
      return parseInt(firstNum) < parseInt(secondNum);
    }
  );

  ValidatorForm.addValidationRule("maxLength", (eleValue, maxLength) => {
    return eleValue.length <= maxLength;
  });

  ValidatorForm.addValidationRule("maxValue", (eleValue, maxValue) => {
    if (eleValue !== null && eleValue !== "") {
      return parseInt(eleValue) <= maxValue;
    }
    return true;
  });

  ValidatorForm.addValidationRule("minValue", (eleValue, minValue) => {
    if (eleValue !== null && eleValue !== "") {
      return parseInt(eleValue) >= minValue;
    }
    return true;
  });
};

export default ValidationRules;
