import React from "react";
import styles from "./BackToButton.module.scss";
import { Link } from "react-router-dom";

const BackToButton = (props) => {
  return (
    <div className={`${styles.backButtonDiv} pagePaddingLeft pagePaddingTop`}>
      <Link
        className={styles.backButton}
        name="backButton"
        to={{
          pathname: props.backToUrl,
        }}
      >
        <div className={styles.arrowLeft}>&nbsp;</div>
        <div>{props.display}</div>
      </Link>
    </div>
  );
};

export default BackToButton;
