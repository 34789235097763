import styles from "./help.module.scss";
import React from "react";

const SpainHelp = (props) => {
  const contactLink =
    "https://www.eufmcdealer.dealerconnection.com/sites/esp/DCC/Paginas/New-Query.aspx";
  return (
    <div className="pageWrapper" data-testid="spainHelp">
      <div className="pagePadding">
        <div className={styles.headerWrapper}>
          <span
            id="helpHeader"
            data-testid="helpHeader"
            className={styles.helpHeader}
          >
            {props.t("Help.header")}
          </span>
          <span data-testid="contactSubHeader" className={styles.helpSubheader}>
            {props.t("Help.subheader1")}
            <span className={styles.boldText}>
              {props.t("Help.subheader2")}
            </span>
            {props.t("Help.subheader3")}
          </span>
        </div>
        <div className={styles.contactWrapper}>
          <span
            id="contactHeader"
            data-testid="contactHeader"
            className={styles.contactHeader}
          >
            {props.t("Help.contactHeader")}
          </span>
          <hr className={styles.line} />
          <div className={styles.contactInfo}>
            <ol>
              <li>
                {props.t("Help.step1")}
                <a href={contactLink} target="_blank" rel="noreferrer">
                  {contactLink}
                </a>
              </li>
              <li>
                {props.t("Help.step2")}
                <span className={styles.boldText}>{props.t("Help.step3")}</span>
              </li>
              <li>
                {props.t("Help.step4")}
                <span className={styles.boldText}>{props.t("Help.step5")}</span>
                {props.t("Help.step6")}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpainHelp;
