import fordStyles from "./Loading.module.scss";
import lincolnStyles from "./Loading-Lincoln.module.scss";
import React, { Component } from "react";
import { ofType } from "../../common/PubSub";
import { IS_FORD_BRAND } from "../../utils/EmpUtil";

export default class Loading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      styles:
        props.brand && !IS_FORD_BRAND(props.brand) ? lincolnStyles : fordStyles,
    };

    ofType("brandChanged").subscribe((response) => {
      this.setState({
        styles: IS_FORD_BRAND(response.data) ? fordStyles : lincolnStyles,
      });
    });
  }

  render() {
    return (
      <div
        className={this.state.styles.homeLoaderWrapper}
        id="loading"
        style={{ display: "none" }}
      >
        <span>
          <div className={this.state.styles.loaderImageHomeLoaderRegular} />
        </span>
      </div>
    );
  }
}
