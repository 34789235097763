import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./VehicleSpecs.module.scss";
import sharedStyles from "../OrderDetailsNewVersion.module.scss";
import crStyles from "../../../customerReservation/crStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Grid from "@material-ui/core/Grid";
import { GetFloatToFixedValue } from "../../../../utils/GetFloatValue";
import {
  StyledBasicExpansionPanel,
  StyledBasicExpansionPanelSummary,
} from "../../../../shared/ExpansionPanels/ExpansionPanel";
import UpperCaseText from "../../../../utils/UpperCaseText";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import {
  IS_AUS_MARKET,
  IS_THA_MARKET,
  IS_ZAF_MARKET,
} from "../../../../utils/EmpUtil";

class VehicleSpecs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandSpecsInfo: true,
      expandAdditionalItems: true,
    };
  }

  expandCustomerInfo() {
    this.setState((prevState) => ({
      expandSpecsInfo: !prevState.expandSpecsInfo,
    }));
  }

  expandAdditionalItems() {
    this.setState((prevState) => ({
      expandAdditionalItems: !prevState.expandAdditionalItems,
    }));
  }

  concatAccessoriesLists(chargingAccessoriesData, ecommAccessoriesData) {
    return chargingAccessoriesData.concat(ecommAccessoriesData);
  }

  render() {
    const data = this.props.pricingStack;
    const defaultCurrency = this.props.defaultCurrency;

    function commaSeparateAmount(rawAmount) {
      return rawAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function specsRow(styling, displayText, secondText, key = "") {
      return (
        displayText && (
          <Grid container key={key === "" ? displayText : key}>
            <Grid item xs={10} className={styling}>
              <div>{displayText}</div>
            </Grid>
            <Grid item xs={2} className={styling}>
              {secondText && (
                <div className={styles.priceValue}>{secondText}</div>
              )}
            </Grid>
          </Grid>
        )
      );
    }

    function formatPriceValue(value, market, hidePrice = false, precision = 2) {
      if (hidePrice) {
        return "";
      }
      if (value !== undefined && value !== null) {
        if (market === "THA") {
          precision = 0;
        }
        value = GetFloatToFixedValue(value, precision);
        value = value.toString();
        value = commaSeparateAmount(value);
        if (market === "THA") {
          value =
            value.charAt(0) === "+" || value.charAt(0) === "-"
              ? value.charAt(0).concat(value.substr(1), defaultCurrency)
              : value === "0"
              ? "--" //Show Dashes if amount is Zero
              : value.concat(defaultCurrency); // Amount first then Currency Symbol
        } else {
          value =
            value.charAt(0) === "+" || value.charAt(0) === "-"
              ? value.charAt(0).concat(defaultCurrency, value.substr(1))
              : defaultCurrency.concat(value); //Currency Symbol show first and then Amount
        }
      } else {
        value = "--";
      }
      return value;
    }

    function getSpecs(specs, nameplate, details) {
      if (details.vehicleFeaturesForIMG.AF8) {
        specs.push(details.vehicleFeaturesForIMG.AF8);
      }
      if (details.vehicleFeaturesForIMG.AF9) {
        specs.push(details.vehicleFeaturesForIMG.AF9);
      }
      let specsDesc = nameplate ? nameplate + ": " : "";
      specsDesc = specs && specsDesc + specs.join(" / ");
      return specsDesc;
    }

    function getOTRPrice(market) {
      let otrPrice = data.grossRetailPriceWithoutIncentives;
      if (
        IS_ZAF_MARKET(market) &&
        data.grossRetailWithOTRWithAccessoriesAndAdditionalCharge
      ) {
        otrPrice = Math.round(
          data.grossRetailWithOTRWithAccessoriesAndAdditionalCharge,
          10
        );
      } else if (IS_AUS_MARKET(market) && data.vehicleDriveAwayPrice) {
        otrPrice = Math.round(data.vehicleDriveAwayPrice, 10);
      } else if (IS_THA_MARKET(market) && data.totalPriceWithAddOns) {
        otrPrice = Math.round(data.totalPriceWithAddOns, 10);
      } else if (data.finalGrossRetail) {
        otrPrice = data.finalGrossRetail;
      }
      return otrPrice;
    }

    return (
      <StyledBasicExpansionPanel
        expanded={
          this.props.offlinePricingUpdate.partialOffline
            ? false
            : this.state.expandSpecsInfo
        }
        disabled={this.props.offlinePricingUpdate.partialOffline}
        onChange={() => this.expandCustomerInfo()}
      >
        <StyledBasicExpansionPanelSummary
          style={crStyles.headerBarNeutral}
          id="exp"
        >
          {/*Vehicle Specs Accordion open/close*/}
          <div className={sharedStyles.headerBar}>
            <div className={sharedStyles.boldHeader}>
              {this.props.offlinePricingUpdate.partialOffline
                ? this.props.t(
                    "CustomerOrderDetails.vehicleSpecsHeaderPricingAgreed"
                  )
                : this.props.t("CustomerOrderDetails.vehicleSpecsHeader")}
            </div>
            {!this.props.offlinePricingUpdate.partialOffline &&
              (this.state.expandSpecsInfo ? (
                <ExpandLessIcon id="less" />
              ) : (
                <ExpandMoreIcon id="more" />
              ))}
          </div>
          <br />
        </StyledBasicExpansionPanelSummary>

        <div className={styles.vehicleInfoGrid}>
          <div className={styles.bottomBorder} />

          {data ? (
            <>
              <Grid container className={styles.specsContainer}>
                {/*Gross Retail price, very first line*/}
                {specsRow(
                  styles.lightHeader,
                  getSpecs(
                    data.specs,
                    this.props.nameplate,
                    this.props.customerReservation
                  ),
                  formatPriceValue(
                    data.grossRetail,
                    UpperCaseText(this.props.market),
                    this.props.market === "AUS" ? true : false
                  )
                )}

                {/*Configured Options*/}
                {specsRow(
                  styles.darkHeader,
                  this.props.t("VehicleSpecs.configureOptions"),
                  formatPriceValue(
                    data.selectedFeaturePrice,
                    UpperCaseText(this.props.market)
                  )
                )}

                {/*User Selected Features*/}
                {data.userSelectedFeatures &&
                  data.userSelectedFeatures.map((feature, index) =>
                    specsRow(
                      styles.darkIndentDetails,
                      feature.type,
                      formatPriceValue(
                        feature.value,
                        UpperCaseText(this.props.market)
                      ),
                      index
                    )
                  )}
                {
                  <>
                    {/*List of Dealer OTR Costs - no. of costs varies*/}
                    {data.dealerOTRCosts &&
                      Object.keys(data.dealerOTRCosts).map((price) => {
                        return specsRow(
                          styles.darkIndentDetails,
                          this.props.t("VehicleSpecs." + price),
                          formatPriceValue(
                            data.dealerOTRCosts[price],
                            UpperCaseText(this.props.market)
                          )
                        );
                      })}

                    {/*Dealer Estimated Driveaway Price Total Price*/}
                    {specsRow(
                      styles.lightHeader,
                      this.props.market === "AUS"
                        ? this.props.t("VehicleSpecs.estimatedDriveawayPrice")
                        : this.props.t("VehicleSpecs.dealerOtrTotalPrice"),
                      formatPriceValue(
                        getOTRPrice(UpperCaseText(this.props.market)),
                        UpperCaseText(this.props.market),
                        false,
                        this.props.market === "AUS" ? 0 : 2
                      )
                    )}
                  </>
                }
              </Grid>

              <HasPermissionTo
                perform={["additionalItemsAccordion"]}
                permissions={this.props.user.permissions.rolePermissions}
                render={() => {
                  return (
                    <StyledBasicExpansionPanel
                      defaultExpanded={true}
                      onChange={() => this.expandAdditionalItems()}
                    >
                      <StyledBasicExpansionPanelSummary
                        style={crStyles.headerBarNeutral}
                        id="exp"
                      >
                        <div className={styles.headerBar}>
                          <div>
                            {this.props.t("VehicleSpecs.additionalItems")}
                          </div>
                          {this.state.expandAdditionalItems ? (
                            <ExpandLessIcon id="less" />
                          ) : (
                            <ExpandMoreIcon id="more" />
                          )}
                        </div>
                        <br />
                      </StyledBasicExpansionPanelSummary>

                      {specsRow(
                        styles.darkHeader,
                        this.props.t("VehicleSpecs.accessories"),
                        formatPriceValue(
                          data.ecommAccessoriesPriceTotal,
                          UpperCaseText(this.props.market)
                        )
                      )}

                      {this.concatAccessoriesLists(
                        data.chargingAccessoriesData.accessoriesList,
                        data.ecommAccessoriesData
                      ).map((accessoriesOption) => {
                        return specsRow(
                          styles.darkIndentDetails,
                          accessoriesOption.shortDescription +
                            this.props.t("VehicleSpecs.partNo") +
                            accessoriesOption.code,
                          formatPriceValue(
                            accessoriesOption.dealerSellingPrice,
                            UpperCaseText(this.props.market)
                          )
                        );
                      })}
                    </StyledBasicExpansionPanel>
                  );
                }}
              />
            </>
          ) : (
            <span className={styles.labelError}>
              {" "}
              {this.props.t("CustomerOrderDetails.errorLoadingDetails")}
            </span>
          )}
        </div>
      </StyledBasicExpansionPanel>
    );
  }
}

export default withTranslation("emp")(VehicleSpecs);
