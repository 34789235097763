import React from "react";
import fordStyles from "../globalNavBar/GlobalNavBar.module.scss";
import lincolnStyles from "../globalNavBar/GlobalNavBar-Lincoln.module.scss";
import { Dialog, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import "../../styles/sharedStyles.scss";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { IS_FORD_BRAND } from "../../utils/EmpUtil";
import commonFordStyles from "../../styles/common/CommonStyles.module.scss";
import commonLincolnStyles from "../../styles/common/CommonStyles-Lincoln.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import CheckBox from "../checkBox/CheckBox";

const StyledDialog = withStyles({
  paper: {
    width: 600,
    maxWidth: 600,
  },
})(Dialog);

class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
      checked: false,
    };
  }

  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open });
    }
  }

  changeCheckbox = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  render() {
    const { showWarningIcon, dialogProps, showSettings } = this.props;
    const isFordBrand = IS_FORD_BRAND(this.props.user && this.props.user.brand);
    const styles = isFordBrand ? fordStyles : lincolnStyles;
    const commonStyles = isFordBrand ? commonFordStyles : commonLincolnStyles;

    const StyledClose = withStyles({
      root: {
        color: isFordBrand ? "#102b4e" : "#324047",
        cursor: "pointer",
        height: 30,
        width: 30,
      },
    })(ClearIcon);
    return (
      <StyledDialog
        open={this.state.open}
        className={styles.dialog}
        onClose={this.props.onClose}
        maxWidth="sm"
      >
        <Grid container id="deleteDialog" data-testid="confirmSaveSettings">
          <Grid item md={12}>
            <div className={styles.headerContainer}>
              <div className={showWarningIcon && styles.titleContainer}>
                {showWarningIcon && <div className={styles.warning} />}
                {this.props.greenTitle ? (
                  <div className={styles.greenTitle}>
                    {dialogProps && dialogProps.title}
                  </div>
                ) : (
                  <div
                    className={
                      this.props.boldTitle
                        ? styles.dialogTitleBold
                        : styles.dialogTitle
                    }
                  >
                    {dialogProps && dialogProps.title}
                  </div>
                )}
              </div>
              {showWarningIcon && (
                <StyledClose
                  onClick={this.props.onCancel}
                  className={styles.icon}
                />
              )}
            </div>
          </Grid>
          <Grid item md={12}>
            <div className={styles.descriptionContainer}>
              <div className={styles.dialogDescription}>
                {dialogProps && dialogProps.bodyText}
              </div>
            </div>
          </Grid>

          {showSettings && (
            <Grid container className={styles.settingsContainer}>
              <Grid item xs={4} className={styles.settingsHeader}>
                {dialogProps && dialogProps.markup}
              </Grid>
              <Grid item xs={4} className={styles.settingsHeader}>
                {dialogProps && dialogProps.laborRate}
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4} className={styles.settingsInfo}>
                {this.props.markupValue}%
              </Grid>
              <Grid item xs={4} className={styles.settingsInfo}>
                ${this.props.laborRateValue}
              </Grid>
            </Grid>
          )}

          {showSettings && (
            <Grid item xs={12}>
              <div className={styles.checkboxDiv}>
                <CheckBox
                  checked={this.state.checked}
                  isDisabled={false}
                  name="checkboxBrandSettings"
                  id="checkboxBrandSettings"
                  label={dialogProps && dialogProps.checkbox}
                  onChange={(e) => this.changeCheckbox(e)}
                />
              </div>
            </Grid>
          )}

          {this.props.showWarning && (
            <Grid item md={12} data-testid="dealerList">
              {this.props.dealerList.map((dealer, index) => {
                const attribute =
                  this.props.attributes &&
                  this.props.attributes[dealer.commonId];
                return (
                  <div key={index} className={styles.nonStyledListItem}>
                    {dealer.name} ({dealer.dealerId + dealer.dealerSubcode}){" "}
                    {attribute}
                  </div>
                );
              })}
            </Grid>
          )}
          {this.props.showOneButton ? (
            <Grid item md={12}>
              <div className={styles.singleDialogActions}>
                <div className={styles.oneButtonContainer}>
                  <Button
                    className={commonStyles.confirmDialogButton}
                    id="confirmDialog"
                    data-testid="confirmDialog"
                    disabled={showSettings && !this.state.checked}
                    onClick={this.props.onConfirm}
                  >
                    {dialogProps.confirm}
                  </Button>
                </div>
              </div>
            </Grid>
          ) : (
            <Grid item md={12}>
              <div className={styles.dialogActions}>
                <div className={styles.buttonContainer}>
                  <Button
                    className={commonStyles.cancelledDialogButton}
                    id="cancelDialog"
                    data-testid="closeDialog"
                    onClick={this.props.onCancel}
                  >
                    {dialogProps && dialogProps.cancel}
                  </Button>
                </div>
                <div className={styles.buttonContainer}>
                  <Button
                    className={commonStyles.confirmDialogButton}
                    id="confirmDialog"
                    data-testid="confirmDialog"
                    disabled={showSettings && !this.state.checked}
                    onClick={this.props.onConfirm}
                  >
                    {dialogProps && dialogProps.confirm}
                  </Button>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </StyledDialog>
    );
  }
}

export default withTranslation("emp")(ConfirmDialog);
