import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./OrderDetailsActionNotifications.module.scss";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import UpperCaseText from "../../../../utils/UpperCaseText";
import HasPermissionTo from "../../../../common/HasPermissionTo";

const financeFailedStatuses = [
  "DENIED",
  "DENIED_SUPPRESSED",
  "FINANCE_SUPPRESSED",
  "FINANCE_EID_CHECKED_FAIL",
  "FINANCE_REFERRED",
  "ERROR",
];

const OrderDetailsNotification = (props) => {
  return (
    <div>
      {props.displayNotification && (
        <Grid
          container
          className={
            props.warningColor
              ? `${styles.actionNotificationBox} ${styles.warningBoxColor}`
              : `${styles.actionNotificationBox} ${styles.whiteBoxColor}`
          }
        >
          <Grid item xs={1} className={styles.actionNotificationText}>
            {props.warningColor ? (
              <div className={styles.actionWarningIcon} />
            ) : (
              <InfoOutlinedIcon />
            )}
          </Grid>
          <Grid item xs={2} className={styles.actionNotificationText}>
            <div>{props.showActionRequired && props.showActionRequired}</div>
          </Grid>
          <Grid item xs={5} className={styles.actionNotificationMsg}>
            <div>{props.showMessage && props.showMessage}</div>
          </Grid>
          <Grid item xs={3} className={styles.actionNotificationText}>
            <div>{props.showPriority && props.showPriority}</div>
          </Grid>
          <Grid item xs={1}>
            <HasPermissionTo
              perform={["deleteNotification"]}
              permissions={props.user.permissions.rolePermissions}
              condition={props.showCloseIcon}
              render={() => {
                return (
                  <div className={styles.closeDiv}>
                    <div
                      className={styles.closeIcon}
                      data-testid="closeNotification"
                      onClick={() => props.handleClose()}
                    />
                  </div>
                );
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

class OrderDetailsActionNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tradeInNotification: true,
      optOfflineNotification: true,
      paymentNotification: true,
    };
    this.isOrderCancelled = this.props.isOrderCancelled(
      UpperCaseText(this.props.customerReservation.status)
    );
    this.isReservation = this.props.isReservation(
      this.props.customerReservation.orderType
    );
  }

  handleTradeInNotification = () => {
    this.setState({ tradeInNotification: false });
  };
  handleOptOfflineNotification = () => {
    this.setState({ optOfflineNotification: false });
  };
  handlePaymentNotification = () => {
    this.setState({ paymentNotification: false });
  };

  getLatestFinanceStatus = () => {
    const customerReservation = this.props.customerReservation;
    if (
      customerReservation &&
      customerReservation.paymentDescription &&
      customerReservation.paymentDescription.length > 0
    ) {
      const statusObject =
        customerReservation.paymentDescription[
          customerReservation.paymentDescription.length - 1
        ];
      return statusObject.status;
    } else {
      return null;
    }
  };

  render() {
    return (
      <div>
        {/*Notification for Failed financial status*/}
        {!this.isOrderCancelled &&
          financeFailedStatuses.includes(this.getLatestFinanceStatus()) && (
            <OrderDetailsNotification
              user={this.props.user}
              showActionRequired={this.props.t(
                "CustomerOrderDetails.actionRequired"
              )}
              showMessage={this.props.t(
                "CustomerOrderDetails.customerNeedsSupport"
              )}
              showPriority={this.props.t("CustomerOrderDetails.mediumPriority")}
              warningColor={true}
              showCloseIcon={true}
              displayNotification={this.state.paymentNotification}
              handleClose={this.handlePaymentNotification}
            />
          )}

        {/*Notification for Trade In requested status*/}
        {!this.isReservation && this.props.tradeIn && (
          <OrderDetailsNotification
            user={this.props.user}
            showActionRequired={this.props.t(
              "CustomerOrderDetails.informationRequired"
            )}
            showMessage={this.props.t("CustomerOrderDetails.tradeInRequest")}
            warningColor={false}
            showCloseIcon={true}
            displayNotification={this.state.tradeInNotification}
            handleClose={this.handleTradeInNotification}
          />
        )}

        {/*Notification for Customer opt offline*/}
        {!this.isOrderCancelled &&
          this.props.customerReservation.offlineDocInfo &&
          this.props.customerReservation.offlineDocInfo.signAtStore &&
          !this.props.customerReservation.offlineDocInfo.dealerActionDate && (
            <OrderDetailsNotification
              user={this.props.user}
              showActionRequired={this.props.t(
                "CustomerOrderDetails.actionRequired"
              )}
              showMessage={this.props.t("CustomerOptOffline.wetSignRequired")}
              warningColor={true}
              displayNotification={this.state.optOfflineNotification}
              handleClose={this.handleOptOfflineNotification}
            />
          )}
      </div>
    );
  }
}

export default withTranslation("emp")(OrderDetailsActionNotifications);
