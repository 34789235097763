import React from "react";

const Item = (props) => {
  return (
    <span
      className={"item " + (props.className ? props.className : "")}
      data-testid={props.datatestid}
    >
      {props.value}
    </span>
  );
};

export default Item;
