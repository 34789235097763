import React, { Component } from "react";
import crStyles from "../../../customerReservation/crStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import styles from "./CustomerOptOffline.module.scss";
import sharedStyles from "../OrderDetailsNewVersion.module.scss";
import FormControl from "@material-ui/core/FormControl";
import { withTranslation } from "react-i18next";
import CapitalizeText from "../../../../utils/CapitalizeText";
import UiDateUtils from "../../../../utils/UiDateUtils";
import CalendarDialog from "../calendarDialog/CalendarDialog";
import { OFFLINEDOCSIGN, PARTIALOFFLINE } from "../../../../common/Constants";
import { HAS_VALUE, IS_CRC_USER } from "../../../../utils/EmpUtil";
import Grid from "@material-ui/core/Grid";
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
} from "../../../../shared/ExpansionPanels/ExpansionPanel";

const OrderInfo = ({
  actionDate: actionDate,
  userId: userId,
  dateAgreed: dateAgreed,
  recordedBy: recordedBy,
  dateSigned: dateSigned,
}) => {
  return (
    actionDate && (
      <Grid container className={styles.detailsDiv}>
        <Grid item xs={1}>
          <span className={styles.subTitleText}>
            {UiDateUtils.formattedDate(actionDate)}
          </span>
        </Grid>
        {userId && (
          <Grid item xs={11}>
            <span className={styles.recordedBy}>{recordedBy}</span>
            <span className={styles.dataText}>{CapitalizeText(userId)}</span>
          </Grid>
        )}
        <Grid item xs={1} />
        {dateAgreed && (
          <Grid item xs={3}>
            <span className={styles.recordedBy}>{dateSigned}</span>
            <span className={styles.dataText}>
              {UiDateUtils.formattedDate(dateAgreed)}
            </span>
          </Grid>
        )}
      </Grid>
    )
  );
};

class CustomerOptOffline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandCustomerOptOffline: true,
    };

    this.canEditOptOffline =
      this.props.user.permissions.rolePermissions.hasOwnProperty(
        "customerOptOffline:write"
      );
  }

  expandCustomerOptOffline() {
    this.setState({
      expandCustomerOptOffline: !this.state.expandCustomerOptOffline,
    });
  }

  saveCustomerOptOffline = (customerSignedDate) => {
    this.props.handleCustomerOptOffline(
      OFFLINEDOCSIGN,
      customerSignedDate,
      null
    );
  };

  savePricingUpdate = (customerSignedDate) => {
    this.props.handleCustomerOptOffline(
      PARTIALOFFLINE,
      customerSignedDate,
      null
    );
  };
  showPricingUpdate = (signAtStore, actionDate) => {
    return signAtStore ? actionDate : true;
  };

  render() {
    return (
      <StyledExpansionPanel
        defaultExpanded={true}
        onChange={() => this.expandCustomerOptOffline()}
      >
        <StyledExpansionPanelSummary style={crStyles.headerBarBg}>
          <div className={sharedStyles.headerBar}>
            <div
              data-testid="customerOptOfflineTitle"
              className={sharedStyles.header}
            >
              {this.props.offlineDocInfo.signAtStore
                ? this.props.t("CustomerOptOffline.customerOptAndPayTitle")
                : this.props.t("CustomerOptOffline.customerOptTitle")}
            </div>
            <div>
              {this.state.expandCustomerOptOffline ? (
                <ExpandLessIcon id="less" />
              ) : (
                <ExpandMoreIcon id="more" />
              )}
            </div>
          </div>
        </StyledExpansionPanelSummary>
        <div className={styles.infoDiv}>
          {this.props.offlineDocInfo.signAtStore && (
            <FormControl>
              <CalendarDialog
                {...this.props}
                id="customerOrderForm"
                saveCalendarDate={this.saveCustomerOptOffline}
                todayDate={this.props.customerReservation.todayDate}
                checkBoxText={this.props.t(
                  "CustomerOptOffline.customerHasSignedOrder"
                )}
                isDisabled={
                  IS_CRC_USER(this.props.user.userType) ||
                  !this.canEditOptOffline ||
                  HAS_VALUE(this.props.offlineDocInfo.dealerActionDate)
                }
                isChecked={HAS_VALUE(
                  this.props.offlineDocInfo.dealerActionDate
                )}
                hasMaxDate={true}
                dialogTitle={this.props.t("CustomerOptOffline.dialogTitle")}
                dialogDescription={this.props.t(
                  "CustomerOptOffline.dialogDescription"
                )}
              />
              <OrderInfo
                {...this.props}
                actionDate={this.props.offlineDocInfo.dealerActionDate}
                userId={this.props.offlineDocInfo.dealerUserId}
                dateAgreed={this.props.offlineDocInfo.customerSignedDate}
                recordedBy={this.props.t("CustomerOptOffline.recordedBy")}
                dateSigned={this.props.t("CustomerOptOffline.dateSigned")}
              />
            </FormControl>
          )}

          {this.showPricingUpdate(
            this.props.offlineDocInfo.signAtStore,
            this.props.offlineDocInfo.dealerActionDate
          ) && (
            <FormControl>
              <CalendarDialog
                {...this.props}
                id="pricingUpdateForm"
                saveCalendarDate={this.savePricingUpdate}
                showTooltip={true}
                todayDate={this.props.customerReservation.todayDate}
                checkBoxText={this.props.t("CustomerOptOffline.pricingUpdate")}
                isDisabled={
                  IS_CRC_USER(this.props.user.userType) ||
                  this.props.offlinePricingUpdate.partialOffline ||
                  this.props.isOrderCancelled
                }
                hasMaxDate={true}
                dialogTitle={this.props.t(
                  "CustomerOptOffline.pricingDialogTitle"
                )}
                dialogDescription={this.props.t(
                  "CustomerOptOffline.pricingDialogDescription"
                )}
                isChecked={this.props.offlinePricingUpdate.partialOffline}
              />
              {this.props.offlinePricingUpdate.partialOffline && (
                <OrderInfo
                  {...this.props}
                  actionDate={this.props.offlinePricingUpdate.dealerActionDate}
                  userId={this.props.offlinePricingUpdate.dealerActionUserId}
                  dateAgreed={
                    this.props.offlinePricingUpdate.dealerAcceptedDate
                  }
                  recordedBy={this.props.t("CustomerOptOffline.recordedBy")}
                  dateSigned={this.props.t("CustomerOptOffline.dateSigned")}
                />
              )}
            </FormControl>
          )}
        </div>
      </StyledExpansionPanel>
    );
  }
}

export default withTranslation("emp")(CustomerOptOffline);
