import React, { Component } from "react";
import styles from "./DealerDeliveryRanges.module.scss";
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ValidationRules from "../../../../common/ValidationRules";
import { Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import DeliverRangeAndPriceRow from "./deliveryRangesRow/DealerDeliveryRangesRow";
import MultiToggleWidget from "../../../../shared/toggles/multiToggleWidget/MultiToggleWidget";
import EditIcon from "../../../../shared/Edit/EditIcon";
import { logMsg } from "../../../../common/Logger";
import { DELIVERY_BANDS } from "../../../../common/Constants";
import PendingActionPrompt from "../../../../shared/pendingActionPrompt/PendingActionPrompt";
import DeliveryRangesClient from "../../../../shared/clients/DeliveryRangesClient";
import PendingActionClient from "../../../../shared/clients/PendingActionClient";
import UpperCaseText from "../../../../utils/UpperCaseText";
import HasPermissionTo from "../../../../common/HasPermissionTo";

class DealerDeliveryRanges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryData: {},
      isEditActive: false,
      enableSaveButton: true,
      dataLoaded: false,
      pendingActionPrompt: false,
    };
    this.currencySymbol =
      this.props.localizedInfo && this.props.localizedInfo.defaultCurrency
        ? this.props.localizedInfo.defaultCurrency.symbol
        : "NA";
    this.distanceUnit =
      this.props.localizedInfo && this.props.localizedInfo.defaultDistanceUnit
        ? this.props.localizedInfo.defaultDistanceUnit
        : "NA";

    this.displayToggle = this.displayToggle.bind(this);
    this.onboardedDealers = this.props.user.getOnboardedDealerGroup(true);
  }

  componentDidMount() {
    ValidationRules();
    this.determinePAStatus();
    this.getDeliveryRanges();
  }

  handlePriceChange = (e, index) => {
    const deliveryData = this.copyDeliveryData();
    deliveryData.deliveryRangeOptions[index].value = e.target.value;
    this.setState({ deliveryData, enableSaveButton: true });
  };

  handleDeliveryChargeChange = (e) => {
    const deliveryData = this.copyDeliveryData();
    deliveryData.nonMainlandDeliveryCharge = e.target.value;
    this.setState({ deliveryData, enableSaveButton: true });
  };

  handleInternationalCharge = (e) => {
    const deliveryData = this.copyDeliveryData();
    deliveryData.deliverInternationalCharge = e.target.value;
    this.setState({ deliveryData, enableSaveButton: true });
  };

  handleSave = () => {
    const deliveryData = this.copyDeliveryData();
    deliveryData.deliveryRangeOptions =
      this.state.deliveryData.deliveryRangeOptions.filter(
        // eslint-disable-next-line
      (range) => {
          if (range.acceptedByDealer) {
            return range;
          }
        }
      );

    this.dataToSendToDialog = deliveryData;

    if (
      this.props.user.showAll &&
      this.onboardedDealers.length > 1 &&
      !this.disablePendingActionPrompt
    ) {
      this.setState({ pendingActionPrompt: true });
    } else {
      this.saveDeliveryRanges(
        this.dataToSendToDialog,
        this.props.user.getCommonIdsWithoutSDLR(),
        this.props.user.getDealerIdsWithoutSDLR()
      );
    }
  };

  getDeliveryRanges = () => {
    let foundFirstFalse = false;
    this.props.hideOrShow(true);
    DeliveryRangesClient.getDealerDeliveryRanges(this.props.user)
      .then((deliveryData) => {
        deliveryData.nonMainlandDeliveryCharge =
          deliveryData.nonMainlandDeliveryCharge
            ? deliveryData.nonMainlandDeliveryCharge.toFixed(2)
            : "0";
        deliveryData.deliverInternationalCharge =
          deliveryData.deliverInternationalCharge
            ? deliveryData.deliverInternationalCharge.toFixed(2)
            : "0";
        deliveryData.deliveryRangeOptions.forEach((range) => {
          range.value = range.value.toFixed(2);
          if (!range.acceptedByDealer) {
            if (foundFirstFalse) {
              range.disabled = true;
            } else {
              range.disabled = false;
              foundFirstFalse = true;
            }
          } else {
            range.disabled = false;
          }
        });
        this.setState({ deliveryData });
      })
      .catch((err) => {
        logMsg("err", err);
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({ dataLoaded: true });
      });
  };

  copyDeliveryData() {
    return JSON.parse(JSON.stringify(this.state.deliveryData));
  }

  deliverInNonMainLandToggle = () => {
    const deliveryData = this.copyDeliveryData();
    deliveryData.nonMainlandDelivery =
      !this.state.deliveryData.nonMainlandDelivery;
    this.setState({ deliveryData, enableSaveButton: true });
  };

  sellInternationalToggle = () => {
    const deliveryData = this.copyDeliveryData();
    deliveryData.sellInternational = !this.state.deliveryData.sellInternational;
    deliveryData.deliverInternational = false;
    this.setState({ deliveryData, enableSaveButton: true });
  };

  deliverInternationalToggle = () => {
    const deliveryData = this.copyDeliveryData();
    deliveryData.deliverInternational =
      !this.state.deliveryData.deliverInternational;
    this.setState({ deliveryData, enableSaveButton: true });
  };

  updateToggleStatus = (index) => {
    const deliveryData = this.copyDeliveryData();
    const deliveryRanges = deliveryData.deliveryRangeOptions;
    deliveryRanges[index].acceptedByDealer =
      !this.state.deliveryData.deliveryRangeOptions[index].acceptedByDealer;
    if (!deliveryRanges[index].acceptedByDealer) {
      for (let i = parseInt(index) + 1; i < deliveryRanges.length; ++i) {
        deliveryRanges[i].acceptedByDealer = false;
        deliveryRanges[i].disabled = true;
      }
    } else {
      // Enable the next toggle
      if (parseInt(index) + 1 < deliveryRanges.length) {
        deliveryRanges[parseInt(index) + 1].disabled = false;
      }
    }
    deliveryData.deliveryRangeOptions = deliveryRanges;
    this.setState({ deliveryData, enableSaveButton: true });
  };

  saveDeliveryRanges(data, commonIds, dealerIds) {
    DeliveryRangesClient.saveDealerDeliveryRanges(
      data,
      this.props.user,
      commonIds
    )
      .then(() => {
        PendingActionClient.removeDealerPendingAction(
          this.props.user,
          dealerIds,
          DELIVERY_BANDS,
          1
        ).then(() => {
          this.getDeliveryRanges();
          this.props.getPendingActions().then(() => {
            this.determinePAStatus();
          });
        });
        this.setState({
          isEditActive: false,
          enableSaveButton: false,
          pendingActionPrompt: false,
        });
      })
      .catch(() => {});
  }

  determinePAStatus() {
    this.pendingActionStatusForDealers =
      this.props.user.getPendingActionStatusForDealers(
        this.props.user.pendingActions,
        "DELIVERY_BANDS"
      );
    this.disablePendingActionPrompt =
      this.pendingActionStatusForDealers.length === 0;
  }

  pendingActionPromptClose = () => {
    this.setState({ pendingActionPrompt: false });
  };

  pendingActionPromptApplyAll = () => {
    const commonIds = this.pendingActionStatusForDealers[0].map((dealer) => {
      return dealer.commonId;
    });
    const dealerIds = this.pendingActionStatusForDealers[0].map((dealer) => {
      return dealer.dealerId;
    });
    this.saveDeliveryRanges(
      this.dataToSendToDialog,
      commonIds.toString().split("|").join("%7C"),
      dealerIds
    );
  };

  pendingActionPromptApplySelective = () => {
    //only apply values to dealers who have NOT completed pendingAction
    const commonIds = this.pendingActionStatusForDealers[0]
      .filter((dealer) => !dealer.pendingActionStatus)
      .map((dealer) => {
        return dealer.commonId;
      });
    const dealerIds = this.pendingActionStatusForDealers[0]
      .filter((dealer) => !dealer.pendingActionStatus)
      .map((dealer) => {
        return dealer.dealerId;
      });
    this.saveDeliveryRanges(
      this.dataToSendToDialog,
      commonIds.toString().split("|").join("%7C"),
      dealerIds
    );
  };

  displayToggle(toggleName, status, isDisabled, callBack) {
    isDisabled =
      isDisabled ||
      this.props.user.readOnly ||
      !this.props.user.permissions.rolePermissions.hasOwnProperty(
        "deliveryRanges:write"
      );
    return (
      <div>
        <MultiToggleWidget
          settings={{
            acceptedText: this.props.t("GeneralEMP.statusYes"),
            rejectedText: this.props.t("GeneralEMP.statusNo"),
            suppClass: `${isDisabled ? "" : styles.toggleWrapper}`,
          }}
          isToggleDisabled={isDisabled}
          toggleName={toggleName}
          currentStatus={status ? "yes" : "no"}
          onSelect={() => callBack()}
        />
      </div>
    );
  }

  render() {
    return (
      this.state.dataLoaded && (
        <section className="pageWrapper">
          <div
            id="businessSettingsHeader"
            className={`${styles.businessSettings} pagePaddingRight pagePaddingLeft`}
          >
            <p>{this.props.t("dealerDeliveryBands.header1")}</p>
            <p>{this.props.t("dealerDeliveryBands.header2")}</p>
          </div>
          <div
            id="businessSettingsSubHeader"
            className={styles.headerBackGround}
          >
            <span className={`${styles.taskHeader} pagePaddingLeft`}>
              {`${UpperCaseText(this.props.user.market)} ${this.props.t(
                "dealerDeliveryBands.mainlandBands"
              )}`}
            </span>
            <span className={`${styles.taskDetails} pagePaddingLeft`}>
              {this.props.t("dealerDeliveryBands.headerNote")}
            </span>
          </div>

          <ValidatorForm onSubmit={this.handleSave}>
            <div className="pagePaddingLeft pagePaddingRight">
              <Grid
                id="deliveryRangeTableHeader"
                container
                className={styles.divider}
              >
                <Grid item md={4}>
                  <div className={styles.headerWrap}>
                    <span>
                      {this.props.t("dealerDeliveryBands.distance") +
                        "  (" +
                        this.distanceUnit +
                        ")"}{" "}
                    </span>
                  </div>
                </Grid>

                <Grid item md={4} className={styles.center}>
                  <div className={styles.headerWrap}>
                    {this.props.t("dealerDeliveryBands.delivery")}
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  className={`${styles.rowHeader} ${styles.right}`}
                >
                  {this.state.isEditActive || this.props.user.readOnly ? (
                    <div className={styles.headerWrap}>
                      {this.props.t("dealerDeliveryBands.deliveryCharge") +
                        "  (" +
                        this.currencySymbol +
                        ")"}
                    </div>
                  ) : (
                    <HasPermissionTo
                      perform={["deliveryRanges:write"]}
                      permissions={this.props.user.permissions.rolePermissions}
                      render={() => {
                        return (
                          <div
                            className={styles.headerWrap}
                            data-testid="editCharge"
                          >
                            {this.props.t("dealerDeliveryBands.deliveryCharge")}
                            <EditIcon
                              id="Ranges"
                              onClick={() =>
                                this.setState({
                                  isEditActive: !this.state.isEditActive,
                                  enableSaveButton: true,
                                })
                              }
                            />
                          </div>
                        );
                      }}
                      noRender={() => {
                        return (
                          <div className={styles.headerWrap}>
                            {this.props.t("dealerDeliveryBands.deliveryCharge")}
                          </div>
                        );
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </div>

            <div
              id="deliveryRangesRow"
              className={`${styles.tableBottom} pagePaddingLeft pagePaddingRight`}
            >
              {this.state.deliveryData.deliveryRangeOptions &&
                this.state.deliveryData.deliveryRangeOptions.map(
                  (item, index) => {
                    return (
                      <DeliverRangeAndPriceRow
                        key={index}
                        rowNo={index}
                        dataRow={item}
                        editActive={this.state.isEditActive}
                        currency={this.currencySymbol}
                        index={index}
                        displayToggle={this.displayToggle}
                        updateToggleStatus={this.updateToggleStatus}
                        onPriceChange={(e, index) =>
                          this.handlePriceChange(e, index)
                        }
                      />
                    );
                  }
                )}
            </div>
            <div
              id="doUDeliverHeader"
              className={`${styles.subSectionBackGround} pagePaddingLeft pagePaddingRight`}
            >
              <div>
                <p className={styles.taskHeader}>
                  {this.props.user.market.toUpperCase() === "CHE"
                    ? this.props.t("dealerDeliveryBands.doUDeliverHeaderChe")
                    : this.props.t("dealerDeliveryBands.doUDeliverHeader")}
                </p>
                <p className={styles.taskDetails}>
                  {this.props.t("dealerDeliveryBands.doUDeliverBody")}
                </p>
              </div>
              {this.displayToggle(
                "deliverInUK",
                this.state.deliveryData.nonMainlandDelivery,
                false,
                this.deliverInNonMainLandToggle
              )}
            </div>
            {this.state.deliveryData.nonMainlandDelivery && (
              <div
                id="doUDeliverDiv"
                className={`${styles.subSectionPriceBackGround} pagePaddingLeft pagePaddingRight`}
              >
                <span className={styles.headerWrap}>
                  {" "}
                  {this.props.t("dealerDeliveryBands.delveryChargeNational") +
                    "  (" +
                    this.currencySymbol +
                    ")"}{" "}
                  &nbsp;
                </span>
                {this.state.isEditActive ? (
                  <TextValidator
                    style={{ borderRadius: "0px" }}
                    name="nonMainlandDelivery"
                    variant="outlined"
                    value={
                      this.state.deliveryData.nonMainlandDeliveryCharge === null
                        ? ""
                        : this.state.deliveryData.nonMainlandDeliveryCharge
                    }
                    onChange={(e) => this.handleDeliveryChargeChange(e)}
                    validators={[
                      "required",
                      "minNumber:0",
                      "isNumber",
                      "isNumericWithTwoDecimalPlaces",
                    ]}
                    errorMessages={[
                      this.props.t("nscDeliveryBands.required"),
                      this.props.t("nscDeliveryBands.invalid"),
                      this.props.t("nscDeliveryBands.invalid"),
                      this.props.t("nscDeliveryBands.invalid"),
                    ]}
                  />
                ) : (
                  <p>{this.state.deliveryData.nonMainlandDeliveryCharge}</p>
                )}
              </div>
            )}

            <div
              id="doUSellHeader"
              className={`${styles.subSectionBackGround} pagePaddingLeft pagePaddingRight`}
            >
              <div>
                <p className={`${styles.taskHeader}`}>
                  {this.props.t("dealerDeliveryBands.doUSellHeader")}
                </p>
                <p className={`${styles.taskDetails}`}>
                  {this.props.t("dealerDeliveryBands.doUSellBody")}
                </p>
              </div>
              {this.displayToggle(
                "sellInternational",
                this.state.deliveryData.sellInternational,
                false,
                this.sellInternationalToggle
              )}
            </div>

            {this.state.deliveryData.sellInternational && (
              <div
                id="doUDeliverInterHeader"
                className={`${styles.subSectionBackGround} pagePaddingLeft pagePaddingRight`}
              >
                <div>
                  <p className={`${styles.taskHeader}`}>
                    {this.props.t("dealerDeliveryBands.doUDeliverInHeader")}
                  </p>
                  <p className={`${styles.taskDetails}`}>
                    {this.props.t("dealerDeliveryBands.doUDeliverInBody")}
                  </p>
                </div>
                {this.displayToggle(
                  "deliverInternational",
                  this.state.deliveryData.deliverInternational,
                  false,
                  this.deliverInternationalToggle
                )}
              </div>
            )}
            {this.state.deliveryData.deliverInternational &&
              this.state.deliveryData.sellInternational && (
                <div
                  id="doUDeliverInterDiv"
                  className={`${styles.subSectionPriceBackGround} pagePaddingLeft pagePaddingRight`}
                >
                  <span className={styles.headerWrap}>
                    {" "}
                    {this.props.t(
                      "dealerDeliveryBands.delveryChargeInterNational"
                    ) +
                      "  (" +
                      this.currencySymbol +
                      ")"}{" "}
                    &nbsp;
                  </span>
                  {this.state.isEditActive ? (
                    <TextValidator
                      style={{ borderRadius: "0px" }}
                      name="deliverInternationalText"
                      variant="outlined"
                      value={
                        this.state.deliveryData.deliverInternationalCharge ===
                        null
                          ? ""
                          : this.state.deliveryData.deliverInternationalCharge
                      }
                      onChange={(e) => this.handleInternationalCharge(e)}
                      validators={[
                        "required",
                        "minNumber:0",
                        "isNumber",
                        "isNumericWithTwoDecimalPlaces",
                      ]}
                      errorMessages={[
                        this.props.t("nscDeliveryBands.required"),
                        this.props.t("nscDeliveryBands.invalid"),
                        this.props.t("nscDeliveryBands.invalid"),
                        this.props.t("nscDeliveryBands.invalid"),
                      ]}
                    />
                  ) : (
                    <p>{this.state.deliveryData.deliverInternationalCharge}</p>
                  )}
                </div>
              )}

            <div className={styles.actionSection}>
              {this.state.enableSaveButton ? (
                <HasPermissionTo
                  perform={["deliveryRanges:write"]}
                  permissions={this.props.user.permissions.rolePermissions}
                  render={() => {
                    return (
                      <Button
                        type="submit"
                        id="savePricing"
                        className={styles.saveBtn}
                        data-testid="savePricing"
                        disabled={this.props.user.readOnly}
                        component="button"
                      >
                        {this.props.t("dealerDeliveryBands.save")}
                      </Button>
                    );
                  }}
                />
              ) : (
                <span className={styles.pricesApplied}>
                  {this.props.t("dealerDeliveryBands.pricesApplied")}
                </span>
              )}
            </div>
          </ValidatorForm>

          <PendingActionPrompt
            {...this.props}
            disabled={this.disablePendingActionPrompt}
            dealersActioned={
              this.disablePendingActionPrompt
                ? []
                : this.pendingActionStatusForDealers[0].filter(
                    (dealer) => dealer.pendingActionStatus
                  )
            }
            dealersNotActioned={
              this.disablePendingActionPrompt
                ? []
                : this.pendingActionStatusForDealers[0].filter(
                    (dealer) => !dealer.pendingActionStatus
                  )
            }
            title={this.props.t("PendingActionPrompt.deliveryRanges")}
            buttonTitle={this.props.t(
              "PendingActionPrompt.deliveryRangesButton"
            )}
            onClose={this.pendingActionPromptClose}
            open={this.state.pendingActionPrompt}
            applyAll={this.pendingActionPromptApplyAll}
            applySome={this.pendingActionPromptApplySelective}
          />
        </section>
      )
    );
  }
}

export default withTranslation("emp")(DealerDeliveryRanges);
