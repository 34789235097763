import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import styles from "./TermsDialog.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import FileBrowser from "../fileBrowser/FileBrowser";
import pdfIcon from "../../imgs/pdf-acrobat-icon.svg";
import Grid from "@material-ui/core/Grid";

const StyledClearIcon = withStyles({
  root: {
    color: "#102b4e",
  },
})(ClearIcon);

class TermsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileUploaded: false,
      warningMsg: false,
      overSizeErr: false,
    };
  }

  onClose = () => {
    this.removeFile();
    this.props.onClose();
  };

  onFileSelected = (file) => {
    if (file.type === "application/pdf") {
      if (file.size > 1000000) {
        this.setState({ overSizeErr: true });
      } else {
        this.setState({ file: file, fileUploaded: true });
      }
    } else {
      this.setState({ warningMsg: true });
    }
  };

  getAppliedList = () => {
    return (
      this.props.contracts &&
      this.props.contracts.filter((contract) => contract.contract !== null)
    );
  };
  getPendingList = () => {
    return (
      this.props.contracts &&
      this.props.contracts.filter((contract) => contract.contract === null)
    );
  };

  applyToDealer = () => {
    this.sendFileToSave(this.props.currentDealerId);
  };

  applyToAll = () => {
    let dealerIds = [];
    this.props.contracts.forEach((contract) => {
      dealerIds = [...dealerIds, contract.dealerId];
    });
    this.sendFileToSave(dealerIds);
  };

  applyToBranches = () => {
    let dealerIds = [];
    this.getPendingList().forEach((contract) => {
      dealerIds = [...dealerIds, contract.dealerId];
    });
    this.sendFileToSave(dealerIds);
  };

  sendFileToSave = (dealerIds) => {
    const file = this.state.file;
    this.removeFile();
    this.props.confirmAction(dealerIds, file);
  };

  removeFile = () => {
    this.setState({ file: null, fileUploaded: false, warningMsg: false });
  };

  showList = (list) => {
    return list.length > 0;
  };

  render() {
    const StyledDialog = withStyles({
      paper: {
        maxWidth: 600,
        width: 750,
      },
    })(Dialog);

    const StyledClose = withStyles({
      root: {
        color: "#102b4e",
      },
    })(ClearIcon);

    return (
      <StyledDialog open={this.props.open} onClose={this.onClose}>
        <div className={styles.dialogBody}>
          <div className={styles.cancelButton}>
            <button
              data-testid="closeDialogBtn"
              className={styles.closeDialogBtn}
              onClick={this.onClose}
              type="submit"
            >
              <StyledClose />
            </button>
          </div>
          <div className={styles.dialogHeader}>{this.props.dialogTitle}</div>
          <div className={styles.dialogDescription}>
            {this.props.dialogBody}
          </div>
          {this.state.fileUploaded ? (
            <>
              <div className={styles.dialogPadding}>
                <div className={styles.fileNameTextBox}>
                  <div>
                    <img src={pdfIcon} />
                    <p className={styles.fileName}>{this.state.file.name}</p>
                  </div>
                  <div
                    className={styles.clearImage}
                    id="removeFile"
                    onClick={this.removeFile}
                  >
                    <StyledClearIcon />
                  </div>
                </div>
              </div>
              {this.props.forAll ? (
                this.showList(this.getPendingList()) &&
                this.showList(this.getAppliedList()) ? (
                  <>
                    <div className={styles.dialogPadding}>
                      <div className={styles.dialogSubHeader}>
                        {this.props.t("dealerTermsDialog.overViewApplied")}
                      </div>
                      <div className={styles.dialogBox}>
                        <Grid container>
                          {this.getAppliedList().map((contract) => {
                            return (
                              <Grid
                                item
                                md={6}
                                key={contract.dealerId}
                                className={styles.dialogDealerName}
                              >
                                {contract.name}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </div>
                      <div className={styles.dialogSubHeader}>
                        {this.props.t("dealerTermsDialog.overViewNotApplied")}
                      </div>
                      <div className={styles.dialogBox}>
                        <Grid container>
                          {this.getPendingList().map((contract) => {
                            return (
                              <Grid
                                item
                                md={6}
                                key={contract.dealerId}
                                className={styles.dialogDealerName}
                              >
                                {contract.name}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </div>
                    </div>
                    <div className={styles.cancelBtnDiv} onClick={this.onClose}>
                      <Button className={styles.closeBtn}>
                        <span> {this.props.t("dealerTermsDialog.cancel")}</span>
                      </Button>
                    </div>
                    <div
                      className={styles.applyBranchBtnDiv}
                      onClick={this.applyToBranches}
                    >
                      <Button className={styles.actionBtn}>
                        <span>
                          {this.props.t("dealerTermsDialog.applyToBranches")}
                        </span>
                      </Button>
                    </div>
                    <div
                      className={styles.applyAllBtnDiv}
                      onClick={this.applyToAll}
                    >
                      <Button className={styles.actionBtn}>
                        <span>
                          {this.props.t("dealerTermsDialog.applyToAll")}
                        </span>
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className={styles.applyBtnDiv} onClick={this.applyToAll}>
                    <Button className={styles.actionBtn}>
                      <span>
                        {this.props.t("dealerTermsDialog.applyToAll")}
                      </span>
                    </Button>
                  </div>
                )
              ) : (
                <div
                  className={styles.applyBtnDiv}
                  onClick={this.applyToDealer}
                >
                  <Button className={styles.actionBtn}>
                    <span>{this.props.saveText}</span>
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <div className={styles.dialogPadding}>
                <FileBrowser
                  fileType=".pdf"
                  onFileSelected={this.onFileSelected}
                  data-testId="pdf-file"
                  id="pdf-file"
                >
                  <Button className={styles.uploadBtn} component="span">
                    {this.props.t(
                      "dealerTermsDialog.uploadNewTermsAndConditions"
                    )}
                  </Button>
                </FileBrowser>
              </div>
              {this.state.overSizeErr ? (
                <div className={styles.warningMessage}>
                  {this.props.t("dealerTermsDialog.bigFile")}
                </div>
              ) : this.state.warningMsg ? (
                <div className={styles.warningMessage}>
                  {this.props.t("dealerTermsDialog.wrongFile")}
                </div>
              ) : (
                <div className={styles.fileTypeDesc}>
                  {this.props.t("dealerTermsDialog.fileType")}
                </div>
              )}
              <div className={styles.actionBtnDiv}>
                <Button disabled className={styles.disableActionBtn}>
                  <span className={styles.closeText}>
                    {this.props.saveText}
                  </span>
                </Button>
              </div>
            </>
          )}
        </div>
      </StyledDialog>
    );
  }
}

export default withTranslation("emp")(TermsDialog);
