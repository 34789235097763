import React, { Component } from "react";
import styles from "./accessDenied.module.scss";
import { withTranslation } from "react-i18next";
import FordLogo from "../../common/FordLogo";

class InvalidRoute extends Component {
  render() {
    return (
      <div className="pageWrapper">
        <div className={styles.center}>
          <FordLogo className={styles.brandLogo} />
          <div className={styles.deniedTitle}>
            {this.props.t("InvalidRoute.header")}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(InvalidRoute);
