import React, { Component } from "react";
import GlobalNavBar from "../shared/globalNavBar/GlobalNavBar";
import Search from "../components/search/searchWrapper/SearchWrapper";
import { withTranslation } from "react-i18next";
import Footer from "../shared/Footer/Footer";
import Crc from "./pure/Crc";
import withIdleTimer from "../common/WithIdleTimer";
import { Route, Router } from "react-router-dom";
import ErrorScreen from "../errors/ErrorScreen";
import Help from "../components/help/help";
import SearchWrapper from "../components/search/searchWrapper/SearchWrapper";
import OrdersSearch from "../components/search/searchWrapper/orders/OrdersSearch";

class CRCApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      padding: "70px",
      renderApp: false,
    };
    this.currentCrc = new Crc(
      this.props.token,
      this.props.userDetails,
      this.props.history
    );
  }

  componentDidMount() {
    this.props.history.push(`/`);
    this.setState({ renderApp: true });
  }

  setPadding = (padding) => {
    if (padding > 70) {
      this.setState({ padding: padding + "px" });
    }
  };

  render() {
    return (
      this.state.renderApp && (
        <Router history={this.props.history}>
          <div className="appWrapper">
            <div style={{ paddingTop: this.state.padding }}>
              <GlobalNavBar
                {...this.props}
                urlPath={window.location.pathname}
                setPadding={this.setPadding}
                user={this.currentCrc}
              />
            </div>
            <Route
              exact
              path="/"
              component={withIdleTimer(() => (
                <Search {...this.props} user={this.currentCrc} />
              ))}
            />
            <Route
              exact
              path="/search"
              component={withIdleTimer(() => (
                <SearchWrapper
                  {...this.props}
                  localizedInfo={this.localizedInfo}
                  user={this.currentCrc}
                />
              ))}
            />
            <Route
              exact
              path="/search/orders"
              component={withIdleTimer(() => (
                <OrdersSearch
                  {...this.props}
                  localizedInfo={this.localizedInfo}
                  user={this.currentCrc}
                />
              ))}
            />
            <Route
              exact
              path="/error"
              render={(props) => <ErrorScreen {...props} />}
            />
            <Route
              exact
              path="/help"
              component={withIdleTimer(() => (
                <Help {...this.props} market={this.currentCrc.market} />
              ))}
            />
            <Footer permissions={this.props.userDetails.permissions} />
          </div>
        </Router>
      )
    );
  }
}

export default withTranslation("emp")(CRCApp);
