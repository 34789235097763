import {
  ExpansionPanel,
  ExpansionPanelSummary,
  withStyles,
} from "@material-ui/core";

export const StyledExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    marginBottom: 0,
    "&::before": {
      backgroundColor: "transparent",
    },
    "&:last-child": {
      paddingRight: 0,
    },
  },
  expanded: {
    "&$expanded": {
      margin: "0",
    },
  },
})(ExpansionPanel);

export const StyledExpansionPanelSummary = withStyles({
  root: {
    "&$expanded": {
      margin: "0",
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
    "& > :last-child": {
      paddingRight: 50,
    },
  },
  expanded: {
    margin: 0,
  },
})(ExpansionPanelSummary);

export const StyledBasicExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    margin: 0,
    "&::before": {
      backgroundColor: "transparent",
    },
  },
})(ExpansionPanel);

export const StyledBasicExpansionPanelSummary = withStyles({
  content: {
    margin: 0,
    "& > :last-child": {
      paddingRight: 50,
    },
  },
  root: {
    "&$disabled": {
      opacity: 1,
      background: "white",
    },
  },
  disabled: {
    opacity: 1,
    background: "white",
  },
})(ExpansionPanelSummary);
