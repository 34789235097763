import React from "react";
import { Button } from "@material-ui/core";
import styles from "./DownloadButton.module.scss";
import { withStyles } from "@material-ui/core/styles";
import DownloadButton from "../../imgs/download.svg";

const DownloadButtonV2 = (props) => {
  const StyledButton = withStyles({
    root: {
      minWidth: 0,
    },
  })(Button);

  return (
    <StyledButton onClick={props.download}>
      <div className={styles.downloadV2}>
        <img
          alt="Download"
          className={styles.downloadV2Img}
          src={DownloadButton}
        />
        <span className={styles.downloadV2Text}>{props.text}</span>
      </div>
    </StyledButton>
  );
};

export default DownloadButtonV2;
