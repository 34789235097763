import React from "react";
import styles from "./PricingTooltip.module.scss";
import Clear from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const StyledClear = withStyles({
  root: {
    color: "#102b4e",
  },
})(Clear);

const PricingTooltip = (props) => {
  return (
    <div onClick={props.closeTooltip}>
      <button data-testid="closeTooltip" className={styles.exitButton}>
        <StyledClear />
      </button>
      <div className={styles.header}>
        {props.t("CustomerOptOffline.pricingTooltipHeader")}
      </div>
      <div className={styles.bodyContent}>
        {props.t("CustomerOptOffline.pricingTooltipBody1")}
      </div>
      <div className={styles.bodyContent}>
        {props.t("CustomerOptOffline.pricingTooltipBody2")}
      </div>
    </div>
  );
};

export default withTranslation("emp")(PricingTooltip);
