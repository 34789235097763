import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import styles from "../paymentThreshold/PaymentThreshold.module.scss";
import { withTranslation } from "react-i18next";
import EditIcon from "../../../shared/Edit/EditIcon";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MarketPreferencesClient from "../../../shared/clients/MarketPreferencesClient";
import HasPermissionTo from "../../../common/HasPermissionTo";
import { PAYMENT_THRESHOLD_LIMIT } from "../../../common/Constants";

class PaymentThreshold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: this.props.fromQuickstart ? this.props.isNsc : false,
      paymentThreshold: undefined,
      paymentThresholdCopy: undefined,
      saved: false,
      maxLimit: 0,
      maxLimitFormatted: "",
      validationMaxLimitMsg: "",
      validationInvalidFormat: "",
      validationRequired: "",
    };
  }

  componentDidMount() {
    this.props.hideOrShow(true);
    MarketPreferencesClient.getPaymentThreshold(this.props.user)
      .then((response) => {
        const paymentThresholdFromDB = response.result.paymentThreshold;
        this.setState({
          paymentThreshold: paymentThresholdFromDB || "",
          paymentThresholdCopy: paymentThresholdFromDB || "",
        });
        this.props.updateParentState({
          paymentThreshold: paymentThresholdFromDB,
        });
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
      });

    this.setState({
      validationMaxLimitMsg:
        this.props.t("PaymentThreshold.validations.maxLimit") || "",
      validationInvalidFormat:
        this.props.t("PaymentThreshold.validations.invalidFormat") || "",
      validationRequired:
        this.props.t("PaymentThreshold.validations.required") || "",
    });

    this.setState({
      maxLimit: PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market]
        ? PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market].maxLimit
        : 99999.99,
      maxLimitFormatted: PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market]
        ? PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market]
            .maxLimitFormatted
        : "99,999.99",
    });
  }

  handleEdit = () => {
    this.setState({ editMode: true, saved: false });
  };

  handleBlur = (e) => {
    if (e.target.value) {
      if (this.paymentThresholdRef.current.isValid()) {
        this.setState({ editMode: false });
      }
    }
  };

  handleChange = (event) => {
    this.setState({ paymentThreshold: event.target.value });
  };

  handleSubmit = () => {
    this.props.hideOrShow(true);

    const paymentThresholdRequest = {
      paymentThreshold: this.state.paymentThreshold,
    };

    MarketPreferencesClient.savePaymentThresholdPreferences(
      this.props.user,
      paymentThresholdRequest
    )
      .then(() => {
        this.setState({
          editMode: false,
          paymentThresholdCopy: this.state.paymentThreshold,
          saved: true,
        });
        // from quick start
        if (this.props.fromQuickstart)
          this.props.onSubmit({
            paymentThreshold: this.state.paymentThreshold,
          });
      })
      .finally(() => this.props.hideOrShow(false));
  };

  minNumber = () => {
    const localMarket = this.props.user.market;
    if (localMarket === "ZAF") {
      return 0;
    }
    return 1;
  };

  paymentThresholdRef = React.createRef();

  render() {
    return (
      <div className="pageWrapper">
        <ValidatorForm onSubmit={this.handleSubmit} ref={this.props.formRef}>
          <div
            className={`${styles.subHeader} pagePaddingTop pagePaddingLeft pagePaddingRight`}
          >
            {this.props.t("PaymentThreshold.subheader")}
          </div>
          <div className="pagePaddingTop">
            <Grid
              container
              className={`${styles.tableRowHeader} pagePaddingLeft pagePaddingRight`}
            >
              <Grid item xs={6}>
                <div className={`${styles.thresholdInputGridLeftCell}`}>
                  {this.props.t("PaymentThreshold.typeColumnCaption")}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.thresholdInputGridRightCell}>
                  {this.props.t("PaymentThreshold.amountColumnCaption")}
                  <HasPermissionTo
                    perform={["paymentThreshold:write"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    condition={!this.state.editMode}
                    render={() => {
                      return (
                        <div className={styles.icon} onClick={this.handleEdit}>
                          <EditIcon id="PaymentThreshold" />
                        </div>
                      );
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              className={`${styles.tableRowContent} pagePaddingRight`}
            >
              <Grid item xs={6}>
                <div className={`pagePaddingLeft ${styles.rowCellLeft}`}>
                  {this.props.t("PaymentThreshold.rowDescription")}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  className={`${styles.thresholdInputGridRightCell} ${styles.validatorCell}`}
                >
                  {this.state.editMode ? (
                    <div className={styles.amountColumn}>
                      <div>
                        {this.props.localizedInfo.defaultCurrency.symbol}{" "}
                        &nbsp;&nbsp;
                      </div>
                      <TextValidator
                        fullWidth
                        onBlur={this.handleBlur}
                        ref={this.paymentThresholdRef}
                        data-testid="thresholdInputTestId"
                        name="thresholdInputName"
                        className={styles.inputField}
                        variant="outlined"
                        value={this.state.paymentThreshold}
                        onChange={this.handleChange}
                        placeholder="-"
                        validators={[
                          "required",
                          "matchRegexp:^[0-9]*(\\.[0-9]{0,2})?$",
                          "minNumber:" + this.minNumber(),
                          "maxNumber:" + this.state.maxLimit,
                        ]}
                        errorMessages={[
                          this.state.validationRequired,
                          this.state.validationInvalidFormat,
                          this.state.validationInvalidFormat,
                          this.state.validationMaxLimitMsg +
                            " " +
                            this.state.maxLimitFormatted,
                        ]}
                      />
                    </div>
                  ) : (
                    <div data-testid="thresholdText">
                      {this.state.paymentThreshold === ""
                        ? "-"
                        : this.props.localizedInfo.defaultCurrency.symbol}
                      &nbsp; {this.state.paymentThreshold}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>

          {this.props.fromQuickstart ? (
            ""
          ) : (
            <HasPermissionTo
              perform={["paymentThreshold:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => {
                return this.state.saved ? (
                  <div className={styles.saved}>
                    {this.props.t("PaymentThreshold.savedThreshold")}
                  </div>
                ) : (
                  <div className={styles.applyButton}>
                    <button
                      type="submit"
                      data-testid="thresholdSubmitBtn"
                      disabled={
                        this.state.paymentThreshold ===
                        this.state.paymentThresholdCopy
                      }
                    >
                      {this.props.t("OptIn.apply")}
                    </button>
                  </div>
                );
              }}
            />
          )}
        </ValidatorForm>
      </div>
    );
  }
}

export default withTranslation("emp")(PaymentThreshold);
