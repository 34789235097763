import React from "react";
import fordStyles from "./SharedToolTip.module.scss";
import lincolnStyles from "./LincolnSharedToolTip.module.scss";
import Clear from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Info from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import LincolnTooltipIcon from "../../imgs/lincoln-tooltip-icon.svg";

const StyledClear = withStyles({
  root: {
    color: "#102b4e",
  },
})(Clear);

const StyledInfo = withStyles({
  root: {
    fill: "#4a90e2",
    width: 13,
    marginLeft: 3,
    position: "relative",
    cursor: "pointer",
  },
})(Info);

const StyledTooltip = withStyles({
  popper: {
    opacity: 1,
    pointerEvents: "auto",
  },
  tooltip: {
    background: "white",
    boxShadow: "1px 1px 9px 0 rgba(0, 0, 0, 0.27)",
    paddingTop: 28,
    paddingLeft: 28,
    paddingRight: 28,
    paddingBottom: 18,
    maxWidth: 350,
  },
})(Tooltip);

// Props: open, title, body, openTooltip(), closeTooltip(), closeTestid, openTestid, isLincolnBrand

const SharedToolTip = (props) => {
  const styles = props.isLincolnBrand ? lincolnStyles : fordStyles;
  return (
    <StyledTooltip
      placement="bottom-start"
      open={props.open}
      title={
        <div>
          <button
            onClick={props.closeTooltip}
            className={styles.exitButton}
            data-testid={props.closeTestid}
          >
            <StyledClear />
          </button>
          <div className={styles.header}>{props.title}</div>
          <div className={styles.bodyContent}>{props.body}</div>
        </div>
      }
    >
      {props.isLincolnBrand ? (
        <img
          alt="Tooltip"
          data-testid={props.openTestid}
          className={styles.tooltipImg}
          onClick={(event) => props.openTooltip(event)}
          src={LincolnTooltipIcon}
        />
      ) : (
        <StyledInfo
          data-testid={props.openTestid}
          onClick={(event) => props.openTooltip(event)}
          fontSize="small"
        />
      )}
    </StyledTooltip>
  );
};

export default withTranslation("emp")(SharedToolTip);
