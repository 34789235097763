import React from "react";
import styles from "./NscDeliveryRangesRow.module.scss";
import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";

const NscDeliveryRowDisplay = (props) => {
  function displayTo(val) {
    if (val === -1 || val === null) return "OVER";
    return val;
  }

  return (
    <div
      id="DeliverRangeAndPriceRow"
      className={`${styles.rowBorder} pagePaddingLeft`}
    >
      <Grid style={{ padding: "20px 0 20px 0" }} container spacing={0}>
        <Grid item xs={5} className={styles.rowItem}>
          <div id="paperFrom">
            <span className={styles.rangeText}>
              {props.range.from} - {displayTo(props.range.to)}
            </span>
          </div>
        </Grid>
        <Grid item xs={3} className={`${styles.rowItem} ${styles.right}`}>
          <div id="paperCharge">{props.range.value}</div>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </div>
  );
};

export default withTranslation("emp")(NscDeliveryRowDisplay);
