import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { RESERVATION_ACTION_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

/**
 * This client is catching Dealer Actions
 * and calling Dealer Action Microservice to log actions in system database
 */
export default class AppActionsLogClient {
  static appActionsLogUrl = RESERVATION_ACTION_URL;

  static urlEncodeCommonId(user) {
    return user.commonId.split("|").join("%7C");
  }

  static recordEmailPhoneUpdatedAction(user, logPayload) {
    const url = `${this.appActionsLogUrl}/appActionLog/${this.urlEncodeCommonId(
      user
    )}/cust-facing/email-phone-updated`;

    return this.recordAppActionLog(url, logPayload, user);
  }

  static recordEmailNotificationToggle(user, logPayload) {
    const url = `${this.appActionsLogUrl}/appActionLog/${this.urlEncodeCommonId(
      user
    )}/emailNotificationToggle`;

    return this.recordAppActionLog(url, logPayload, user);
  }

  static recordEmployeePendingActionsEmailsToggle(user, logPayload) {
    const url = `${this.appActionsLogUrl}/appActionLog/${this.urlEncodeCommonId(
      user
    )}/employeePendingActionToggle`;

    return this.recordAppActionLog(url, logPayload, user);
  }

  static recordAddEmployee(user, logPayload) {
    const url = `${this.appActionsLogUrl}/appActionLog/${this.urlEncodeCommonId(
      user
    )}/employeeAdded`;

    return this.recordAppActionLog(url, logPayload, user);
  }

  static recordDeleteEmployee(user, logPayload) {
    const url = `${this.appActionsLogUrl}/appActionLog/${this.urlEncodeCommonId(
      user
    )}/employeeDeleted`;

    return this.recordAppActionLog(url, logPayload, user);
  }

  static recordParticipationStatus(user, logPayload) {
    const url = `${this.appActionsLogUrl}/appActionLog/${this.urlEncodeCommonId(
      user
    )}/toggle-membership`;

    return this.recordAppActionLog(url, logPayload, user);
  }

  static recordEditEmployee(user, logPayload) {
    const url = `${this.appActionsLogUrl}/appActionLog/${this.urlEncodeCommonId(
      user
    )}/recordEditEmployee`;

    return this.recordAppActionLog(url, logPayload, user);
  }

  static recordMerchantIdChange(user, logPayload) {
    const url = `${this.appActionsLogUrl}/appActionLog/${this.urlEncodeCommonId(
      user
    )}/recordMerchantIdChange`;

    return this.recordAppActionLog(url, logPayload, user);
  }

  static recordAppActionLog(url, logPayload, user) {
    return axios
      .put(url, logPayload, getHeaders(user.token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
