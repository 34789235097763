export default function TitleCase(str) {
  return str
    ? str.replace(/\w\S*/g, function (txt) {
        return (
          txt.charAt(0).toUpperCase() +
          (txt.length > 1 ? txt.substring(1).toLowerCase() : txt)
        );
      })
    : "";
}
