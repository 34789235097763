export default class PO {
  constructor(token, userDetails) {
    if (userDetails) {
      this.userId = userDetails.userId;
      this.commonName = userDetails.commonName;
      this.market = userDetails.market;
      this.fullName = userDetails.fullName;
      this.userType = userDetails.userType;
      this.permissions = userDetails.permissions;
      this.brand = userDetails.brand;
      if (userDetails.permissions) {
        this.originalPermissions = JSON.parse(
          JSON.stringify(userDetails.permissions)
        );
      }
    }
    this.token = token;
  }
  addReadOnlyPermissions(permissions) {
    this.permissions.rolePermissions = Object.assign(
      {},
      permissions.rolePermissions,
      this.permissions.rolePermissions
    );
  }

  removeReadOnlyPermissions() {
    this.permissions.rolePermissions = this.originalPermissions.rolePermissions;
  }
}
