import React, { Component } from "react";
import styles from "../translations/Translation.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import { TextField, Grid } from "@material-ui/core";

class AddNewTranslation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDiv: true,
      newtrans: {
        moduleKey: "",
        key: "",
        en: "",
        es: "",
        vi: "",
        th: "",
        ph: "",
        pt: "",
      },
      key: "",
      en: "",
      translatedValue: "",
    };

    this.lan = this.props.localizedInfo.defaultLanguage.isocode.substr(0, 2);
  }

  handleChangeKey = (event) => {
    this.setState({ key: event.target.value });
  };
  handleChangeEn = (event) => {
    this.setState({ en: event.target.value });
  };
  handleChangeTranslatedValue = (event) => {
    this.setState({ translatedValue: event.target.value });
  };
  cancelTranslation = () => {
    this.setState({ showDiv: false });
    this.props.cancelSpecificTranslation();
  };
  addTranslation = () => {
    this.setState({ showDiv: false });
    const updatedLanguage = { ...this.state.newtrans };
    updatedLanguage.en = this.state.en;
    updatedLanguage.es = this.state.en;
    updatedLanguage.vi = this.state.en;
    updatedLanguage.th = this.state.en;
    updatedLanguage.ph = this.state.en;
    updatedLanguage.pt = this.state.en;

    // instead of loop overwriting the value
    updatedLanguage[this.lan] = this.state.translatedValue;
    updatedLanguage.key = this.state.key;
    updatedLanguage.moduleKey = this.props.moduleKey;
    this.props.addSpecificTranslation(updatedLanguage);
  };

  render() {
    return (
      <div>
        {this.state.showDiv && (
          <Grid container className={styles.translation_header}>
            <Grid item md={3}>
              <TextField
                className={styles.inputField}
                type="text"
                name="topicBox"
                variant="outlined"
                placeholder={this.props.t("Translations.enterKey")}
                onChange={this.handleChangeKey}
              />
            </Grid>

            <Grid item md={3}>
              <TextField
                className={styles.inputField}
                type="text"
                name="topicBox"
                variant="outlined"
                placeholder={this.props.t("Translations.enterEng")}
                onChange={this.handleChangeEn}
              />
            </Grid>

            <Grid item md={3}>
              <TextField
                className={styles.inputField}
                type="text"
                variant="outlined"
                name="topicBox"
                placeholder={this.props.t("Translations.enterLan")}
                onChange={this.handleChangeTranslatedValue}
              />
            </Grid>

            <div className={styles.lanUpdate}>
              <DoneIcon
                fontSize="small"
                style={{ color: "green" }}
                className={styles.button_hover}
                onClick={this.addTranslation}
              />
              <ClearIcon
                fontSize="small"
                color="secondary"
                className={styles.button_hover}
                onClick={this.cancelTranslation}
              />
            </div>
          </Grid>
        )}
      </div>
    );
  }
}

export default AddNewTranslation;
