import React, { Component } from "react";
import styles from "./accessDenied.module.scss";
import { withTranslation } from "react-i18next";
import FordLogo from "../../common/FordLogo";
import { invalidateSession } from "../../common/InvalidateSession";

class AccessDeniedAsNotOnBoarded extends Component {
  componentDidMount() {
    this.props.history.push("/notonboarded");
    invalidateSession();
  }

  render() {
    return (
      <div className="pageWrapper">
        <div className={`pagePadding ${styles.deniedContainer}`}>
          <div className={styles.center}>
            <FordLogo className={styles.brandLogo} />
          </div>
          <div className={styles.center}>
            <div className={styles.deniedTitle}>
              {this.props.t("AccessDeniedAsNotOnBoarded.header")}
            </div>
          </div>
          <div className={styles.awaitingManagement}>
            {this.props.t("AccessDeniedAsNotOnBoarded.subheader")}
          </div>
          <div className={styles.inOrderToAccess}>
            {this.props.t("AccessDeniedAsNotOnBoarded.sublineOne")}
          </div>
          <div className={styles.toCompleteTheOnboarding}>
            {this.props.t("AccessDeniedAsNotOnBoarded.sublineTwo")}
          </div>
          <div className={styles.number}>
            <span className={styles.lineNumber}>1 </span>
            <span className={styles.lineText}>
              {" "}
              {this.props.t("AccessDeniedAsNotOnBoarded.stepOne")}
            </span>
          </div>
          <div className={styles.number}>
            <span className={styles.lineNumber}>2 </span>
            <span className={styles.lineText}>
              {" "}
              {this.props.t("AccessDeniedAsNotOnBoarded.stepTwo")}
              <span className={styles.normalFontWeight}>
                {" "}
                {this.props.t("AccessDeniedAsNotOnBoarded.stepTwoSub")}{" "}
              </span>
            </span>
          </div>
          <div className={styles.number}>
            <span className={styles.lineNumber}>3 </span>
            <span className={styles.lineText}>
              {" "}
              {this.props.t("AccessDeniedAsNotOnBoarded.stepThree")}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(AccessDeniedAsNotOnBoarded);
