import React, { Component } from "react";
import SubNav from "../../shared/subnav/SubNav";
import CustomerReservationTable from "./CustomerReservationTable";
import { withTranslation } from "react-i18next";
import styles from "./CustomerReservation.module.scss";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import CustomerReservationsClient from "../../shared/clients/CustomerReservationsClient";

class CustomerReservations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      isDataLoaded: false,
      tables: {},
      searchEnabled: false,
      fetchFailed: false,
      scrollPos:
        this.props.lastPath &&
        this.props.lastPath.includes("/customer-reservations/order-details/") &&
        sessionStorage.getItem("scrollPos")
          ? sessionStorage.getItem("scrollPos")
          : 0,
    };
    this.fetchFailed = false;
  }

  componentDidMount() {
    this.props.hideOrShow(true, true);
    this.fetchReservations();
  }

  componentWillUnmount() {
    sessionStorage.setItem("scrollPos", window.scrollY);
  }

  fetchReservations = () => {
    const dealers = this.props.user.getCommonIdsWithSDLR().split(",");

    this.getReservations(dealers).finally(() => {
      if (!this.fetchFailed) {
        this.setState({ isDataLoaded: true });
        window.scrollTo(0, this.state.scrollPos);
      }
      this.props.hideOrShow(false);
    });
  };

  createTablesCopy = () => {
    this.tablesCopy = JSON.parse(JSON.stringify(this.state.tables));
  };

  updateOrder = async (
    reservationId,
    dealerActionType,
    dealerCommonId,
    rejectReason
  ) => {
    const dealerActionInfo = {
      reservationId: reservationId,
      dealerAction: dealerActionType,
    };

    if (rejectReason) {
      dealerActionInfo.dealerRejectedReason = rejectReason;
    }

    this.props.hideOrShow(true);
    CustomerReservationsClient.updateOrder(
      this.props.token,
      dealerCommonId,
      dealerActionInfo,
      this.props.user.userId
    )
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        if (this.state.showSearchResults) {
          this.searchOrder();
        } else {
          this.fetchReservations();
        }

        this.props.hideOrShow(false);
      });
  };

  updateTables = (
    reservations,
    numPages,
    numResults,
    commonDealerId,
    newPage
  ) => {
    const tablesCopy = JSON.parse(JSON.stringify(this.state.tables));
    tablesCopy[commonDealerId] = {
      reservations: reservations,
      numberOfPages: numPages,
      totalNumberOfResults: numResults,
      currentPage: newPage,
    };
    this.setState({ tables: tablesCopy }, () => {
      this.createTablesCopy();
    });
  };

  async getReservations(dealers) {
    this.fetchFailed = false;
    this.setState({ fetchFailed: false });
    for (const dealerCommonId in dealers) {
      const currentPage =
        this.props.lastPath &&
        this.props.lastPath.includes("/customer-reservations/order-details/") &&
        sessionStorage.getItem("pageNumber_" + dealers[dealerCommonId])
          ? parseInt(
              sessionStorage.getItem("pageNumber_" + dealers[dealerCommonId])
            )
          : 1;
      await CustomerReservationsClient.getCustomerReservations(
        this.props.user.token,
        dealers[dealerCommonId],
        currentPage - 1,
        this.props.lang
      )
        .then((result) => {
          result.reservations.length &&
            this.parseReservations(
              result.reservations,
              result.pagination.numberOfPages,
              result.pagination.totalNumberOfResults,
              currentPage
            );
        })
        .catch(() => {
          this.setState({ fetchFailed: true });
          this.fetchFailed = true;
        })
        .finally(() => {
          this.createTablesCopy();
        });
    }
  }

  parseReservations(reservations, numPages, numResults, currentPage) {
    const currentTables = JSON.parse(JSON.stringify(this.state.tables));
    const tempReservations = [];
    const dealerId = reservations[0].dealerId;

    reservations.forEach((reservation) => {
      reservation.orderType =
        reservation.orderType === "pre-order"
          ? this.props.t("CustomerReservations.preOrder")
          : reservation.orderType;

      tempReservations.push(reservation);
    });
    currentTables[dealerId] = {
      reservations: tempReservations,
      numberOfPages: numPages,
      totalNumberOfResults: numResults,
      currentPage: currentPage,
    };
    this.setState({ tables: currentTables });
  }

  getSubNav() {
    return (
      <SubNav
        dashboard={false}
        title={this.props.t("GlobalNavBar.orders")}
        {...this.props}
      />
    );
  }

  searchOrders = (e) => {
    e.preventDefault();
    this.props.hideOrShow(true);
    const dealers = this.props.user.getCommonIdsForSearch();
    CustomerReservationsClient.searchCustomerReservations(
      this.state.searchQuery.trim(),
      this.props.user,
      this.props.lang,
      dealers
    )
      .then((reservations) => {
        const pageSize = 10;
        const numberOfPages = Math.ceil(reservations.length / pageSize);
        this.setState({ tables: {} });
        this.parseReservations(
          reservations,
          numberOfPages,
          reservations.length,
          1
        );
      })
      .catch(() => {
        this.setState({ tables: {} });
      })
      .finally(() => {
        this.setState({ searchEnabled: true });
        this.props.hideOrShow(false);
      });
  };

  clearSearch = () => {
    this.state.searchEnabled &&
      this.setState({
        tables: this.tablesCopy,
        searchQuery: "",
        searchEnabled: false,
      });
  };

  searchOrdersComponent() {
    return (
      <div className="pagePadding">
        <div className={styles.searchWrapper}>
          <div>{this.props.t("CustomerReservations.searchBy")}</div>
          <ValidatorForm className={styles.form} onSubmit={this.searchOrders}>
            <TextValidator
              variant="outlined"
              name="orderSearch"
              value={this.state.searchQuery}
              validators={["required"]}
              errorMessages={[this.props.t("Error.requiredField")]}
              onChange={(e) => {
                this.setState({
                  searchQuery: e.target.value,
                });
              }}
              className={styles.inputField}
              data-testid="orderSearch"
              placeholder={this.props.t(
                "CustomerReservations.searchPlaceholder"
              )}
            />

            <button
              className={styles.searchButton}
              type="submit"
              data-testid="searchBtn"
            >
              <div className={styles.searchIcon}></div>
            </button>
          </ValidatorForm>
          <button
            className={styles.clear}
            data-testid="clearSearch"
            onClick={this.clearSearch}
          >
            {this.props.t("CustomerReservations.clear")}
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="pageWrapper">
        {this.getSubNav()}

        {this.searchOrdersComponent()}

        {this.state.isDataLoaded ? (
          Object.keys(this.state.tables).length !== 0 ? (
            Object.keys(this.state.tables).map((commonDealerId, index) => {
              return (
                <CustomerReservationTable
                  {...this.props}
                  searchEnabled={this.state.searchEnabled}
                  key={index}
                  fromCrc={false}
                  index={index}
                  currentPage={this.state.tables[commonDealerId].currentPage}
                  commonDealerId={commonDealerId}
                  reservations={this.state.tables[commonDealerId].reservations}
                  numberOfPages={
                    this.state.tables[commonDealerId].numberOfPages
                  }
                  updateTables={this.updateTables}
                  updateOrder={this.updateOrder}
                  totalNumberOfResults={
                    this.state.tables[commonDealerId].totalNumberOfResults
                  }
                />
              );
            })
          ) : (
            <div
              className="pagePadding"
              data-testid="notFound"
              key="none-found"
            >
              {this.props.t("CustomerReservations.notFound")}
            </div>
          )
        ) : this.state.fetchFailed ? (
          <div className={styles.noDataFoundMsg}>No data found</div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation("emp")(CustomerReservations);
