import axios from "axios";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { AUTHORIZE_BASE_URL } from "../../utils/EmpUtil";
import { is401, is500 } from "../../errors/ErrorConstants";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";

export default class AuthenticationClient {
  static baseUrl = AUTHORIZE_BASE_URL;

  static status(token, authenticationRequest) {
    const url = `${this.baseUrl}/authorize`;

    return axios
      .post(url, authenticationRequest, getHeaders(token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (!is500(error)) {
          AuthenticationFailureHandler.execute(authenticationRequest);
        }
        return Promise.reject(error);
      });
  }
  static getReadOnlyPermissions(user, market) {
    const url = `${this.baseUrl}/read-only-permissions?market=${market}&userType=${user.userType}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
