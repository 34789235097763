const AVAILABLE_MARKETS = {
  AUS: "Australia",
  NZL: "New Zealand",
  PHL: "Philippines",
  ZAF: "South Africa",
  THA: "Thailand",
  VNM: "Vietnam",
};

export { AVAILABLE_MARKETS };
