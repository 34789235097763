import PNG from "../imgs/FordOval_Logo.png";
import React from "react";

const FordLogo = (props) => {
  return (
    <img
      data-testid="ford-logo"
      className={props.className}
      alt="FORD"
      src={PNG}
    />
  );
};

export default FordLogo;
