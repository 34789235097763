import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import styles from "../dealerDashBoard/DashboardStyles.module.scss";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withStyles } from "@material-ui/core/styles";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { IS_CANADA_MARKET } from "../../../utils/EmpUtil";
import UiDateUtils from "../../../utils/UiDateUtils";
import {
  ACCESSORIES_PRICING,
  ALD_PRICING,
  CUPID_ID,
  CUSTOMER_CONTRACT,
  DELIVERY_BANDS,
  GPIS_PRICING,
  LEAD_TIMES,
  NEW_VEHICLE_LINE,
  OPT_IN,
  PAYMENT_THRESHOLD,
  PRICING,
  RESERVATION_DEPOSIT,
  STORE_ID,
  MERCHANT_ID,
  TERMS_AND_CONDITIONS,
  VARIABLE_MARKETING,
} from "../../../common/Constants";

export const PendingActionsRow = (pendingAction, index, props, vehicleLine) => {
  const translationKey = props.isNsc
    ? "NscPendingActions"
    : "DealerPendingActions";
  const actionName = props.t(translationKey + "." + pendingAction.actionName);
  const actionDescription =
    props.t(translationKey + "Description." + pendingAction.actionName) +
    (vehicleLine ? " for " + vehicleLine.year + " " + vehicleLine.model : "");
  const actions = actionToPerform();
  const rowColor = getColor();
  const dueDate =
    pendingAction.status === "OVERDUE"
      ? props.t(translationKey + ".overdue")
      : IS_CANADA_MARKET(props.user.market)
      ? UiDateUtils.formatDateDDMMYYYYToMMDDYYYY(
          pendingAction.dueDate,
          "/",
          "/"
        )
      : pendingAction.dueDate;

  function getColor() {
    switch (pendingAction.status) {
      case "GREEN":
        return "";
      case "AMBER":
        return styles.amber;
      case "RED":
        return styles.red;
      case "OVERDUE":
        return styles.red;
      default:
        return "";
    }
  }

  function getBusinessTabs(actionName) {
    if (actionName === DELIVERY_BANDS) {
      return 2;
    } else if (
      actionName === VARIABLE_MARKETING ||
      actionName === OPT_IN ||
      actionName === ALD_PRICING ||
      actionName === GPIS_PRICING
    ) {
      return 5;
    } else if (actionName === CUSTOMER_CONTRACT) {
      return 4;
    } else if (actionName === STORE_ID || actionName === MERCHANT_ID) {
      return 3;
    } else if (actionName === TERMS_AND_CONDITIONS) {
      //returning -1 rather than 0 so we can identify in Business Settings if user is coming from Pending Actions or other tabs within BusinessSettings
      return -1;
    }
  }

  function actionToPerform() {
    switch (pendingAction.actionName) {
      case TERMS_AND_CONDITIONS:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case PRICING:
      case NEW_VEHICLE_LINE:
        return {
          pathname: "/pricing/vehicles",
          navigateText: props.t(translationKey + ".go"),
        };
      case STORE_ID:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case MERCHANT_ID:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case RESERVATION_DEPOSIT:
        return {
          pathname: "/pricing/deposit",
          navigateText: props.t(translationKey + ".go"),
        };
      case PAYMENT_THRESHOLD:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case "VEHICLE_PRICE_CHANGE":
        return {
          pathname: "/pricing/vehicles",
          navigateText: props.t(translationKey + ".go"),
        };
      case ACCESSORIES_PRICING:
        return {
          pathname: "/pricing/accessories",
          navigateText: props.t(translationKey + ".go"),
        };
      case LEAD_TIMES:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case CUPID_ID:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case DELIVERY_BANDS:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case VARIABLE_MARKETING:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case OPT_IN:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case CUSTOMER_CONTRACT:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case ALD_PRICING:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      case GPIS_PRICING:
        return {
          pathname: "/business-settings",
          navigateText: props.t(translationKey + ".go"),
        };
      default:
        return "";
    }
  }

  function updateBusinessSettings(actionName) {
    !props.isNsc &&
      props.user.updateBusinessSettingsTab(getBusinessTabs(actionName));
  }

  const LeftTableCell = withStyles({
    root: {
      paddingLeft: "3%",
      paddingRight: 15,
    },
  })(TableCell);

  const CenterTableCell = withStyles({
    root: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  })(TableCell);

  const EmptyTableCell = withStyles({
    root: {
      paddingLeft: 15,
      paddingRight: "6%",
      paddingTop: 0,
      paddingBottom: 0,
      width: "9%",
    },
  })(TableCell);

  const ChevronRightIconStyle = withStyles({
    root: {
      height: "17.5px",
    },
  })(ChevronRightIcon);

  return (
    <React.Fragment key={index}>
      <TableRow
        className={`${styles.textContentPendingActions} ${rowColor}`}
        key={index}
        data-testid={pendingAction.actionName}
      >
        <LeftTableCell>
          <div className={styles.alignStart}>
            <ReportProblemOutlinedIcon className={styles.actionIcon} />
            <span className={styles.actionName}>{actionName}</span>
          </div>
        </LeftTableCell>
        <CenterTableCell className={styles.col2}>
          <div className={styles.alignStart}>
            <span className={styles.description}>{actionDescription}</span>
          </div>
          {
            // Commenting this block for current release, will be enable for future release
            /*{vehicleLine && vehicleLine["dealerInfoList"] && (
            <ul>Dealer Issue is fixed
              {vehicleLine["dealerInfoList"].map((item, index) => (
                <li key={index}>{item.dealerId}</li>
              ))}
            </ul>
          )}*/
          }
        </CenterTableCell>
        <CenterTableCell className={styles.col3}>
          <div className={styles.alignStart}>{dueDate}</div>
        </CenterTableCell>
        <CenterTableCell>
          <div>
            <Link
              onClick={() => updateBusinessSettings(pendingAction.actionName)}
              data-testid={pendingAction.actionName + "_GO"}
              to={{
                pathname: actions.pathname,
                query: { fromPendingActions: true },
                actionName: pendingAction.actionName,
              }}
              className={styles.goToPage}
            >
              {actions.navigateText}
              <ChevronRightIconStyle />
            </Link>
          </div>
        </CenterTableCell>
        <EmptyTableCell />
      </TableRow>
    </React.Fragment>
  );
};
