import Dialog from "@material-ui/core/Dialog";
import styles from "./HelpAndSupportDialog.module.scss";
import Clear from "@material-ui/icons/Clear";
import DownloadButton from "../../imgs/download.svg";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import HelpAndSupportClient from "../clients/HelpAndSupportClient";
import { base64FileDownload } from "../../utils/FileDownloadUtil";
import { withStyles } from "@material-ui/core/styles";

const StyledDialog = withStyles({
  paper: {
    width: 600,
    maxWidth: 600,
  },
})(Dialog);

class HelpAndSupportDialog extends Component {
  downloadFile = () => {
    HelpAndSupportClient.getFAQs(this.props.user)
      .then((response) => {
        base64FileDownload(response, "application/pdf", "USA-FAQs.pdf");
      })
      .catch();
  };

  render() {
    return (
      <StyledDialog open={this.props.open}>
        <div className={styles.wrapper}>
          <button className={styles.close} onClick={this.props.closeModal}>
            <Clear />
          </button>
          <div className={styles.header}>{this.props.t("Footer.help")}</div>
          <div className={styles.description}>
            {this.props.t("Footer.description")}
          </div>
          <button className={styles.downloadButton} onClick={this.downloadFile}>
            <img src={DownloadButton} />
            <span>{this.props.t("Footer.downloadFAQs")}</span>
          </button>
          <div className={styles.contactHeader}>
            {this.props.t("Footer.contactHeader")}
          </div>
          <div className={styles.contactInfoContainer}>
            <div className={styles.contactInfo}>
              <div>{this.props.t("Footer.byEmail")}</div>
              <a href="mailto:dlrcon@ford.com" className={styles.email}>
                dlrcon@ford.com
              </a>
            </div>
            <div className={styles.contactInfo}>
              <div>{this.props.t("Footer.byPhone")}</div>
              <div>1-800-790-4357 opt 2, 2</div>
            </div>
          </div>
          <div className={styles.operationInfo}>
            {this.props.t("Footer.hoursOfOperation")}
          </div>
        </div>
      </StyledDialog>
    );
  }
}

export default withTranslation("emp")(HelpAndSupportDialog);
