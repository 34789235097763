import { GET_DEALER_NAME } from "../../utils/EmpUtil";

export default class Dealer {
  constructor(token, userDetails, history) {
    if (userDetails) {
      this.dealerType = userDetails.dealerType;
      this.dealerId = userDetails.dealerId;
      this.loggedInDealerId = userDetails.dealerId;
      this.userId = userDetails.userId;
      this.commonName = userDetails.commonName;
      this.market = userDetails.market;
      this.fullName = userDetails.fullName;
      this.userType = userDetails.userType;
      this.isDealerAdmin = userDetails.dealerAdmin;
      this.isDealerSalesManager = userDetails.dealerSalesManager;
      this.isDealerUsedCarsSalesManager =
        userDetails.dealerUsedCarsSalesManager;
      this.isDealerPrincipal = userDetails.dealerPrincipal;
      this.permissions = userDetails.permissions;
      this.commonId = userDetails.commonId;
      this.brand = userDetails.brand;
    }
    this.showAll = true;
    this.token = token;
    this.history = history;
  }

  isMainDealer(dealerType) {
    return dealerType === "MDLR" || dealerType === "HUB";
  }

  isBranchDealer(dealerType) {
    return dealerType === "BRAN" || dealerType === "SDLR";
  }

  generateResourcePath() {
    return `dealer/${this.dealerId}`;
  }

  updateBusinessSettingsTab(tab) {
    this.businessSettingsPage = tab;
  }

  updateDashboardTab(tab) {
    this.dashboardPage = tab;
  }

  updatePendingActions(pendingActions) {
    this.pendingActions = pendingActions;
  }

  showPricingWarning(showWarning) {
    this.showPricingWarningMessage = showWarning;
  }

  updateCurrentDealer(currentDealer, showAll) {
    this.dealerId = currentDealer.completeDealerId;
    this.salesCode = currentDealer.salesCode;
    this.commonId = currentDealer.commonId;
    this.name = currentDealer.name;
    this.locationName = currentDealer.locationName;
    this.localLocationName = currentDealer.localLocationName;
    this.showAll = showAll;
    this.isActive = currentDealer.isOnboarded;
    this.dealerType = currentDealer.dealerType;
    this.readOnly = !!(this.isLoggedInUserMDLR && "SDLR" === this.dealerType);
    return this;
  }

  setDealerGroup(dealerGroup) {
    this.dealerGroup = dealerGroup;
    this.isLoggedInUserMDLR =
      dealerGroup.filter(
        (d) => d.dealerType === "HUB" || "MDLR" === d.dealerType
      ).length >= 1;

    const dealerGroupingsMap = {};
    const dealerGroupingsMapByDealerId = {};
    this.dealerGroup.forEach((dealer) => {
      dealerGroupingsMap[dealer.commonId] = dealer;
      dealerGroupingsMapByDealerId[dealer.completeDealerId] = dealer;
    });

    this.dealerGroupingsMap = dealerGroupingsMap;
    this.dealerGroupingsMapByDealerId = dealerGroupingsMapByDealerId;
  }

  getCommonIdsWithSDLR(filterByOnboarded = false) {
    return this.getDealerIdsOrCommonIds(filterByOnboarded, false, true);
  }

  getCurrentDealer() {
    return this.dealerGroup.filter(
      (dealer) => dealer.completeDealerId === this.dealerId
    )[0];
  }

  getCommonIdsWithoutSDLR(filterByOnboarded = false) {
    return this.getDealerIdsOrCommonIds(filterByOnboarded, true, true);
  }

  getDealerIdsWithSDLR(filterByOnboarded = false) {
    return this.getDealerIdsOrCommonIds(filterByOnboarded, false, false);
  }

  getDealerIdsWithoutSDLR(filterByOnboarded = false) {
    return this.getDealerIdsOrCommonIds(filterByOnboarded, true, false);
  }

  getDealerIdsOrCommonIds(filterByOnboarded, filterSDLR, isCommonId) {
    const ids = [];

    function getDealerOrCommonId(dealer, isCommonId) {
      let id;
      if (isCommonId) {
        id = dealer.commonId.split("|").join("%7C");
      } else {
        id = dealer.completeDealerId;
      }
      return id;
    }

    if (this.dealerGroup) {
      const dealerGroup = filterByOnboarded
        ? this.dealerGroup.filter((dealer) => dealer.isOnboarded)
        : this.dealerGroup;

      const currentDealer = dealerGroup.filter(
        (dealer) => dealer.completeDealerId === this.dealerId
      )[0];

      if (
        currentDealer &&
        (currentDealer.dealerType === "HUB" ||
          "MDLR" === currentDealer.dealerType) &&
        this.showAll
      ) {
        //If logged in as Main and viewing as ALL
        dealerGroup.forEach((dealer) => {
          if (filterSDLR && dealer.dealerType === "SDLR") return;
          ids.push(getDealerOrCommonId(dealer, isCommonId));
        });
      } else if (
        this.loggedInDealerId === this.dealerId ||
        (currentDealer && "SDLR" !== currentDealer.dealerType) ||
        !filterSDLR
      ) {
        //If Main is viewing as Individual or SDLR/BRAN login individually or If Main not selects SDLR
        ids.push(getDealerOrCommonId(currentDealer, isCommonId));
      }
    }
    return ids.toString();
  }

  getCommonIdsForSearch() {
    const ids = [];

    function getDealerOrCommonId(dealer, isCommonId) {
      let id;
      if (isCommonId) {
        id = dealer.commonId.split("|").join("%7C");
      } else {
        id = dealer.completeDealerId;
      }
      return id;
    }

    if (this.dealerGroup) {
      const dealerGroup = this.dealerGroup.filter(
        (dealer) => dealer.isOnboarded
      );
      const currentDealer = dealerGroup.filter(
        (dealer) => dealer.completeDealerId === this.dealerId
      )[0];

      if (
        currentDealer &&
        (currentDealer.dealerType === "HUB" ||
          "MDLR" === currentDealer.dealerType) &&
        this.showAll
      ) {
        //If logged in as Main and viewing as ALL
        dealerGroup.forEach((dealer) => {
          ids.push(getDealerOrCommonId(dealer, true));
        });
      } else {
        // If Main is viewing as Individual or SDLR/BRAN login individually
        ids.push(getDealerOrCommonId(currentDealer, true));
      }
    }
    return ids.toString();
  }

  reformatCommonId(commonId) {
    return commonId.split("%7C").join("|");
  }

  getPendingActionStatusForDealers(pendingActions, actionName) {
    if (
      pendingActions.filter(
        (pendingAction) => pendingAction.actionName === actionName
      ).length === 0
    ) {
      return [];
    }
    return pendingActions
      .filter((pendingAction) => pendingAction.actionName === actionName)
      .map((pendingAction) => {
        return this.dealerGroup
          .filter(
            (dealer) => this.isLoggedInUserMDLR && dealer.dealerType !== "SDLR"
          )
          .filter((dealer) => dealer.isOnboarded)
          .map((dealer) => {
            return {
              dealerId: dealer.completeDealerId,
              dealerName: GET_DEALER_NAME(dealer, this.market),
              commonId: dealer.commonId,
              pendingActionStatus: !pendingAction.attributes.dealerIds.includes(
                dealer.completeDealerId
              ),
            };
          });
      });
  }

  // This function gets dealergroup and filters out non-onboarded
  // Differs from above function because it does not consider what current view is (Main v. all, etc)
  getOnboardedDealerGroup(filterSDLR = false) {
    if (this.dealerGroup) {
      return filterSDLR
        ? this.dealerGroup.filter((dealer) => dealer.dealerType !== "SDLR")
        : this.dealerGroup;
    } else {
      return [];
    }
  }
}
