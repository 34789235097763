import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

const subject = new Subject();
// This function is used to publish data to the Subject via next().
export const publish = (type, data) => {
  subject.next({ type, data });
};

// This function is used to subscribe the event for type.
export const ofType = (type) => subject.pipe(filter((a) => a.type === type));
