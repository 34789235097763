import React, { Component } from "react";
import DealerContactInfo from "./DealerContactInfo";
import { withTranslation } from "react-i18next";
import styles from "./DealerShipDetails.module.scss";
import { InfoIconWithMessage } from "../../../shared/infoIcon/InfoIcon";

class DealershipDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderAgain: false,
    };

    this.enableLiveUpdateEmployees = this.enableLiveUpdateEmployees.bind(this);
  }

  enableLiveUpdateEmployees() {
    this.setState({ renderAgain: true });
  }

  disableLiveUpdateEmployees = () => {
    this.setState({ renderAgain: false });
  };

  render() {
    return (
      <>
        <div className="pageInfoWrapper pagePaddingLeft pagePaddingTop pagePaddingRight">
          <InfoIconWithMessage
            message={this.props.t("DealerShipDetails.pageInfo")}
          />
        </div>
        <div className={`${styles.topPadding} pageWrapper`}>
          {Object.keys(this.props.filteredDealers).length !== 0
            ? Object.keys(this.props.filteredDealers).map((commonId) => {
                return (
                  <DealerContactInfo
                    {...this.props}
                    renderAgain={this.state.renderAgain}
                    disableLiveUpdateEmployees={this.disableLiveUpdateEmployees}
                    dealers={this.props.filteredDealers[commonId]}
                    enableLiveUpdateEmployees={this.enableLiveUpdateEmployees}
                    key={commonId}
                  />
                );
              })
            : null}
        </div>
      </>
    );
  }
}

export default withTranslation("emp")(DealershipDetails);
