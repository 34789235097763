/**
 * File Browser Component
 *
 * This component require few properties as props:
 * 1. unique id => Id is required to associate child elements.
 * 2. onFileSelected() => Function which will trigger on file change.
 *
 * Other Optional fields are:
 * 1: fileType => list of file types allowed.
 * 2. enableNativeElement => This will enable native html element, by default set to false
 *
 * Child element need to be passed in order to render the the file upload element.
 * Child element can be anything valid html element except native button element.
 *
 *
 *
 *
 */

import React from "react";

const FileBrowser = (props) => {
  let className;
  if (!props.enableNativeElement) {
    className = "display-none";
  } else {
    className = "display-block";
  }
  return (
    <div>
      <input
        type="file"
        className={className}
        id={props.id}
        onChange={(e) => {
          if (e.target.files.length > 0) {
            props.onFileSelected(e.target.files[0]);
          }
        }}
        accept={props.fileType}
      />
      {!props.enableNativeElement ? (
        <label htmlFor={props.id}>{props.children}</label>
      ) : null}
    </div>
  );
};
export default FileBrowser;
