import React, { Component } from "react";
import "../../../styles/sharedStyles.scss";
import { base64FileDownload } from "../../../utils/FileDownloadUtil";
import TermsDialog from "../../../shared/termsDialog/TermsDialog";
import FileEncoder from "../../../utils/FileEncoder";
import styles from "./CustomerContract.module.scss";
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import CustomerContractHeader from "./CustomerContractHeader";
import { withTranslation } from "react-i18next";
import UploadButton from "../../../imgs/upload.svg";
import CustomerContractRow from "./CustomerContractRow";
import { CUSTOMER_CONTRACT } from "../../../common/Constants";
import PendingActionClient from "../../../shared/clients/PendingActionClient";
import CustomerContractClient from "../../../shared/clients/CustomerContractClient";
import HasPermissionTo from "../../../common/HasPermissionTo";

class CustomerContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      updateContractForAll: false,
      updateContractForADealer: false,
      uploadSuccessForAll: false,
      //uploadSuccessForADealer: false,
      showErrorMessage: false,
      contractEmails: {},
      changedEmails: {},
      editMode: false,
      saveSuccess: false,
      saveError: false,
    };
    this.selectedDealer = null;
    this.contractEmailsCopy = {};
    this.dealerIds = this.props.filteredDealers.map(
      (dealer) => dealer.completeDealerId
    );
    this.langCode = this.props.lang ? this.props.lang.substr(0, 2) : "";
    this.viewingAll = this.props.filteredDealers.length > 1;
  }

  componentDidMount() {
    this.getCustomerContract();
  }

  handleContractDownload = (file) => {
    base64FileDownload(file.contract, "application/pdf", file.fileName);
  };

  handleEmailChange = (dealerId, event) => {
    const currentContractEmails = JSON.parse(
      JSON.stringify(this.state.contractEmails)
    );
    currentContractEmails[dealerId] = event.target.value;
    this.updateChangedList(dealerId, event.target.value, currentContractEmails);
  };

  getCustomerContract = (dealerWhoSaved, showMessage) => {
    this.props.hideOrShow(true);
    CustomerContractClient.getCustomerContracts(
      this.props.user.token,
      this.dealerIds
    )
      .then((contracts) => {
        const contractsList = [];
        const contractEmails = {};
        this.props.filteredDealers.forEach((dealer) => {
          const contract = contracts[dealer.completeDealerId];

          if (contract) {
            // name and dealer id used to display dealership name
            contract.name = dealer.name;
            contract.dealerId = dealer.completeDealerId;

            // create dealer type property required for hiding SDLR
            contract.dealerType = dealer.dealerType;

            // use dealership email as fallback email if no contract uploaded
            if (!contract.email) {
              contract.email = dealer.emailAddress;
            }
            // check whether to display the success message when the individual user is saved
            if (showMessage && dealerWhoSaved.includes(contract.dealerId)) {
              contract.showMessage = true;
            }
            contractsList.push(contract);
            contractEmails[contract.dealerId] = contract.email;
          }
        });
        this.setState(
          {
            contracts: contractsList,
            contractEmails,
          },
          () =>
            (this.contractEmailsCopy = JSON.parse(
              JSON.stringify(this.state.contractEmails)
            ))
        );
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  updateAlltheFlags = () => {
    this.setState({
      uploadSuccessForAll: false,
      //uploadSuccessForADealer: false,
      showErrorMessage: false,
      saveError: false,
      saveSuccess: false,
    });
  };

  saveCustomerContract = (dealerIds, file) => {
    this.props.hideOrShow(true);
    this.updateAlltheFlags();
    const updateContractForAll = this.state.updateContractForAll;
    const updateContractForADealer = this.state.updateContractForADealer;
    new FileEncoder()
      .encode(file)
      .then((data) => {
        const customerContract = {
          contract: data,
          fileName: file.name,
          fileType: "pdf",
          langCode: this.langCode,
        };
        CustomerContractClient.uploadCustomerContract(
          this.props.user.token,
          dealerIds,
          customerContract
        )
          .then(() => {
            PendingActionClient.removeDealerPendingAction(
              this.props.user,
              dealerIds,
              CUSTOMER_CONTRACT,
              1
            ).then(() => {
              this.props.getPendingActions();
            });
            this.getCustomerContract(dealerIds, updateContractForADealer);
            if (updateContractForAll) {
              this.setState({ uploadSuccessForAll: true });
            } else if (updateContractForADealer) {
              //this.setState({ uploadSuccessForADealer: true });
            }
          })
          .catch(() => {
            this.setState({ showErrorMessage: true });
          })
          .finally(() => {
            this.props.hideOrShow(false);
          });
      })
      .finally(() => {
        this.setState({
          updateContractForAll: false,
          updateContractForADealer: false,
        });
      });
  };

  hideForRetail = (dealerType) =>
    this.props.isMainViewingSDLR(this.props.user) ||
    (this.props.user.isMainDealer(this.props.user.dealerType) &&
      dealerType === "SDLR");

  updateShowDialogForADealerCall = (dealer) => {
    const updateContractForADealer = !this.state.updateContractForADealer;
    this.setState({ updateContractForADealer });
    this.selectedDealer = dealer.dealerId;
  };

  editEmails = () => {
    this.setState({ editMode: true, saveSuccess: false });
  };

  updateChangedList(dealerId, newEmail, currentContractEmails) {
    const currentChangedEmails = JSON.parse(
      JSON.stringify(this.state.changedEmails)
    );
    if (newEmail !== this.contractEmailsCopy[dealerId]) {
      currentChangedEmails[dealerId] = newEmail;
    } else {
      delete currentChangedEmails[dealerId];
    }
    this.setState({
      changedEmails: currentChangedEmails,
      contractEmails: currentContractEmails,
    });
  }

  saveChanges = () => {
    this.props.hideOrShow(true);
    this.updateAlltheFlags();
    CustomerContractClient.updateContractEmails(this.props.user.token, {
      contractEmails: this.state.changedEmails,
      documentLanguage: this.langCode,
    })
      .then(() => {
        this.setState({
          editMode: false,
          changedEmails: {},
          saveSuccess: true,
          saveError: false,
        });
        this.contractEmailsCopy = JSON.parse(
          JSON.stringify(this.state.contractEmails)
        );
      })
      .catch(() => {
        this.setState({ saveError: true });
      })
      .finally(() => this.props.hideOrShow(false));
  };

  cancelChanges = () => {
    this.setState({
      editMode: false,
      contractEmails: this.contractEmailsCopy,
      changedEmails: {},
      saveError: false,
    });
  };

  render() {
    return (
      <ValidatorForm onSubmit={this.saveChanges}>
        <TermsDialog
          open={this.state.updateContractForAll}
          forAll={true}
          contracts={this.state.contracts}
          dialogBody={this.props.t("dealerTermsDialog.branchSubHeader")}
          dialogTitle={this.props.t("dealerTermsDialog.branchTitle")}
          saveText={this.props.t("dealerTermsDialog.apply")}
          confirmAction={this.saveCustomerContract}
          onClose={() => this.setState({ updateContractForAll: false })}
        />

        <TermsDialog
          open={this.state.updateContractForADealer}
          forAll={false}
          dialogBody={this.props.t("dealerTermsDialog.allSubHeader")}
          dialogTitle={this.props.t("dealerTermsDialog.allTitle")}
          saveText={this.props.t("dealerTermsDialog.apply")}
          currentDealerId={this.selectedDealer}
          confirmAction={this.saveCustomerContract}
          onClose={() => this.setState({ updateContractForADealer: false })}
        />
        <div className="pageWrapper">
          <div className="pagePadding">
            {this.viewingAll ? (
              <>
                <div className={styles.subHeader}>
                  {this.props.t("CustomerContract.subTitleMain")}
                </div>
                <HasPermissionTo
                  perform={["customerContract:write"]}
                  permissions={this.props.user.permissions.rolePermissions}
                  render={() => {
                    return (
                      <div className={styles.buttonDiv}>
                        <Button
                          onClick={() =>
                            this.setState({ updateContractForAll: true })
                          }
                        >
                          <img alt="Upload" src={UploadButton} />
                          <div className={styles.buttonText}>
                            {this.props.t("CustomerContract.setForAll")}
                          </div>
                        </Button>
                        {this.state.uploadSuccessForAll && (
                          <span className={styles.updateCompleteText}>
                            {this.props.t("CustomerContract.updateCompleted")}
                          </span>
                        )}
                      </div>
                    );
                  }}
                />

                {this.state.showErrorMessage && (
                  <div className={styles.errorMessage}>
                    {this.props.t("CustomerContract.errorMessage")}{" "}
                  </div>
                )}
              </>
            ) : (
              <div className={styles.subHeader}>
                {this.props.t("CustomerContract.subTitleBranch")}
              </div>
            )}
          </div>

          {/** GRID HEADER */}
          <CustomerContractHeader
            {...this.props}
            editMode={this.state.editMode}
            editEmails={this.editEmails}
          />

          {
            /** GRID BODY */
            this.state.contracts.map((contract, key) => {
              return (
                <CustomerContractRow
                  {...this.props}
                  contract={contract}
                  hideForRetail={this.hideForRetail}
                  editMode={this.state.editMode}
                  updateShowDialogForADealer={
                    this.updateShowDialogForADealerCall
                  }
                  handleContractDownload={this.handleContractDownload}
                  contractEmails={this.state.contractEmails}
                  handleEmailChange={this.handleEmailChange}
                  key={key}
                />
              );
            })
          }

          {this.state.saveError && (
            <div className={styles.saveError}>
              {this.props.t("CustomerContract.saveError")}
            </div>
          )}
          {this.state.saveSuccess && (
            <div className={styles.saveSuccess}>
              {this.props.t("CustomerContract.emailUpdated")}
            </div>
          )}
          {this.state.editMode ? (
            Object.keys(this.state.changedEmails).length > 0 ? (
              <div className={styles.applyButtonDiv}>
                <button type="submit" data-testid="saveBtn">
                  {this.props.t("CustomerContract.apply")}
                </button>
              </div>
            ) : (
              <div className={styles.closeButtonDiv}>
                <button onClick={this.cancelChanges}>
                  {this.props.t("CustomerContract.close")}
                </button>
              </div>
            )
          ) : null}
        </div>
      </ValidatorForm>
    );
  }
}

export default withTranslation("emp")(CustomerContract);
