import React, { Component } from "react";
import crStyles from "../../../customerReservation/crStyles";
import sharedStyles from "../OrderDetailsNewVersion.module.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import styles from "./WallBoxDetails.module.scss";
import FormControl from "@material-ui/core/FormControl";
import CheckBox from "../../../../shared/checkBox/CheckBox";
import checkboxStyles from "../../../../shared/checkBox/CheckBox.module.scss";
import { withTranslation } from "react-i18next";
import CapitalizeText from "../../../../utils/CapitalizeText";
import UiDateUtils from "../../../../utils/UiDateUtils";
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
} from "../../../../shared/ExpansionPanels/ExpansionPanel";

class WallBoxDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandWallBoxDetails: true,
    };
  }

  expandWallBoxDetails() {
    this.setState({ expandWallBoxDetails: !this.state.expandWallBoxDetails });
  }

  render() {
    return (
      <div>
        <StyledExpansionPanel
          defaultExpanded={true}
          onChange={() => this.expandWallBoxDetails()}
        >
          <StyledExpansionPanelSummary style={crStyles.headerBarBg}>
            <div className={sharedStyles.headerBar}>
              <div data-testid="wallBoxTitleId" className={sharedStyles.header}>
                {this.props.t("WallBox.wallBoxTitle")}
              </div>
              <div>
                {this.state.expandWallBoxDetails ? (
                  <ExpandLessIcon id="less_id" />
                ) : (
                  <ExpandMoreIcon id="more_id" />
                )}
              </div>
            </div>
          </StyledExpansionPanelSummary>
          {this.props.isOrderCancelled(
            this.props.customerReservation.status
          ) && <div className={styles.overlay} data-testid="wallboxOverlay" />}
          <div className={styles.wallBoxInfo}>
            <FormControl>
              <CheckBox
                id="wallboxRequestedDateCheckboxId"
                checked={true}
                isDisabled={true}
                label={CapitalizeText(
                  this.props.t("WallBox.installationRequestedTitle")
                )}
                textClass={checkboxStyles.boldLabel}
              />
              <div className={styles.info}>
                <span className={styles.installationRequestedDate}>
                  {UiDateUtils.formattedDate(
                    this.props.customerReservation
                      .chargingInstallationRequestDate
                  )}
                </span>
                <span className={styles.installationRequestedCaption}>
                  {this.props.t("WallBox.installationRequestedCaption")}
                </span>
              </div>
            </FormControl>
          </div>
        </StyledExpansionPanel>
      </div>
    );
  }
}

export default withTranslation("emp")(WallBoxDetails);
