import React, { Component } from "react";
import commonStyles from "../../../../styles/common/CommonStyles.module.scss";
import styles from "./ReleaseStyles.module.scss";
import Button from "@material-ui/core/Button";
import MarketPreferencesClient from "../../../../shared/clients/MarketPreferencesClient";
import HasPermissionTo from "../../../../common/HasPermissionTo";

class ReservationRelease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      releaseStatus: false,
      renderApp: false,
    };
  }

  componentDidMount() {
    let releaseStatus = false;
    this.props.hideOrShow(true);
    MarketPreferencesClient.getReservationReleaseStatus(this.props.user)
      .then((data) => {
        releaseStatus = data.result.status;
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({ renderApp: true, releaseStatus: releaseStatus });
      });
  }

  setReleaseFlag = (releaseFlag) => {
    const marketPreferences = {
      releaseOrderEnable: releaseFlag,
    };

    this.props.hideOrShow(true);
    MarketPreferencesClient.saveReservationReleasePreferences(
      this.props.user,
      marketPreferences
    )
      .then(() => {
        this.setState({ releaseStatus: releaseFlag });
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  render() {
    return (
      this.state.renderApp && (
        <div className="pagePadding">
          <p className={commonStyles.subHeader}>
            {this.props.t("ReservationRelease.subHeader")}
          </p>

          <div className={styles.releaseBtn}>
            <HasPermissionTo
              perform={["reservationRelease:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => (
                <Button
                  data-testid="releaseBtn"
                  disabled={this.state.releaseStatus}
                  variant="contained"
                  onClick={() => {
                    this.setReleaseFlag(true);
                  }}
                  className={commonStyles.greenBtn}
                  component="button"
                >
                  <span>{this.props.t("ReservationRelease.publish")}</span>
                </Button>
              )}
              noRender={() => (
                <Button
                  data-testid="releaseBtn"
                  disabled={true}
                  variant="contained"
                  className={commonStyles.greenBtn}
                  component="button"
                >
                  <span>{this.props.t("ReservationRelease.publish")}</span>
                </Button>
              )}
            />
          </div>
        </div>
      )
    );
  }
}

export default ReservationRelease;
