import React from "react";
import { Dialog, withStyles } from "@material-ui/core";
import "../../styles/sharedStyles.scss";
import Grid from "@material-ui/core/Grid";
import Clear from "@material-ui/icons/Clear";
import styles from "./DeliveryScheduleDialog.module.scss";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import CheckBox from "../../shared/checkBox/CheckBox";
import checkboxStyles from "../../shared/checkBox/CheckBox.module.scss";

const StyledClear = withStyles({
  root: {
    width: 27,
    height: 27,
    fill: "#102b4e",
    align: "right",
  },
})(Clear);

const StyledDialog = withStyles({
  paper: {
    width: 650,
    maxWidth: 650,
  },
})(Dialog);

const StyledGrid = withStyles({
  root: {
    width: 650,
    maxWidth: 650,
  },
  container: {
    width: 650,
    maxWidth: 650,
  },
})(Grid);

class DeliveryScheduleDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkBox: false,
    };
  }

  changeConfirmButtonState = () => {
    this.setState({
      checkBox: !this.state.checkBox,
    });
  };

  render() {
    return (
      <StyledDialog
        open={this.props.open}
        className={styles.dialog}
        onClose={this.props.onClose}
      >
        <StyledGrid
          container
          data-testid="deliveryScheduleDialogId"
          className={styles.dialogContainer}
          justify="center"
        >
          <Grid item md={12} className={styles.cancel}>
            <div>
              <button data-testid="closeDialog" onClick={this.props.onClose}>
                <StyledClear />
              </button>
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={styles.dialogTitle}>
              <span className={styles.warning} />
              <div>
                {this.props.t(
                  "CustomerReservations.deliveryScheduleDialogTitle"
                )}
              </div>
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={styles.dialogDescription}>
              <ul>
                <li className={styles.dialogDescriptionBold}>
                  {this.props.t(
                    "CustomerReservations.deliveryScheduleDialogBullet1"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "CustomerReservations.deliveryScheduleDialogBullet2"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "CustomerReservations.deliveryScheduleDialogBullet3"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "CustomerReservations.deliveryScheduleDialogBullet4"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "CustomerReservations.deliveryScheduleDialogBullet5"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "CustomerReservations.deliveryScheduleDialogBullet6"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "CustomerReservations.deliveryScheduleDialogBullet7"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "CustomerReservations.deliveryScheduleDialogBullet8"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "CustomerReservations.deliveryScheduleDialogBullet9"
                  )}
                </li>
              </ul>
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={styles.checkbox}>
              <CheckBox
                boldCheck={true}
                textClass={checkboxStyles.cancelLabel}
                checked={this.state.checkBox}
                isDisabled={false}
                name="chkBxConfirm"
                id="chkBxConfirm"
                label={this.props.t(
                  "CustomerReservations.deliveryScheduleDialogAgree"
                )}
                onChange={(e) => this.changeConfirmButtonState(e)}
              />
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={styles.dialogActions}>
              <Button
                className={styles.goBack}
                name="cancel"
                data-testid="cancel"
                onClick={this.props.onClose}
              >
                {this.props.t(
                  "CustomerReservations.deliveryScheduleDialogCancel"
                )}
              </Button>
              <Button
                className={styles.confirmShared}
                name="confirm"
                data-testid="confirm"
                disabled={this.state.checkBox === false}
                onClick={this.props.confirmAction}
              >
                {this.props.t(
                  "CustomerReservations.deliveryScheduleDialogConfirm"
                )}
              </Button>
            </div>
          </Grid>
        </StyledGrid>
      </StyledDialog>
    );
  }
}

export default withTranslation("emp")(DeliveryScheduleDialog);
