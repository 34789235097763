import React, { Component } from "react";
import GlobalNavBar from "../shared/globalNavBar/GlobalNavBar";
import PO from "./pure/PO";
import Footer from "../shared/Footer/Footer";
import withIdleTimer from "../common/WithIdleTimer";
import { Route, Router } from "react-router-dom";
import SearchWrapper from "../components/search/searchWrapper/SearchWrapper";
import OrdersSearch from "../components/search/searchWrapper/orders/OrdersSearch";

class POApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderApp: false,
      padding: "70px",
    };

    this.user = new PO(this.props.token, this.props.userDetails);
  }

  componentDidMount() {
    this.props.history.push(`/`);
    this.props.i18n.changeLanguage("en");
    this.setState({ renderApp: true });
  }

  setPadding = (padding) => {
    if (padding > 70) {
      this.setState({ padding: padding + "px" });
    }
  };

  render() {
    return (
      this.state.renderApp && (
        <Router history={this.props.history}>
          <div className="appWrapper" id="appWrapper">
            <div style={{ paddingTop: this.state.padding }}>
              <GlobalNavBar
                {...this.props}
                setPadding={this.setPadding}
                user={this.user}
              />
            </div>
            <Route
              exact
              path="/search"
              component={withIdleTimer(() => (
                <SearchWrapper {...this.props} user={this.user} />
              ))}
            />
            <Route
              exact
              path="/search/orders"
              component={withIdleTimer(() => (
                <OrdersSearch
                  {...this.props}
                  localizedInfo={this.localizedInfo}
                  user={this.user}
                />
              ))}
            />
            <Footer permissions={this.props.userDetails.permissions} />
          </div>
        </Router>
      )
    );
  }
}

export default POApp;
