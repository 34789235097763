import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class DealerMerchantIdClient {
  static baseUrl = BASE_URL;

  static getMerchantIds(user) {
    const url = `${
      this.baseUrl
    }/dealer/storeIds?dealerIds=${user.getCommonIdsWithSDLR()}`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static saveMerchantIds(data, token) {
    const url = `${this.baseUrl}/dealer/storeIds`;

    return axios
      .post(url, data, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
