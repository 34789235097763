import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ERROR_PROCESSING, LOAD_ERROR, PAGE_ERROR } from "./ErrorConstants";
import PageError from "./PageError";
import ErrorProcessingDialog from "./ErrorProcessingDialog";
import { IS_CANADA_MARKET } from "../utils/EmpUtil";
import { MARKET } from "../common/Constants";

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    const hasError = {};
    hasError[error.message] = true;
    return { hasError: hasError };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: {} };
  }

  closeErrorDialog = () => {
    const hasError = {};
    hasError[ERROR_PROCESSING] = false;
    this.setState({ hasError: hasError });
  };

  render() {
    const market = sessionStorage.getItem(MARKET);

    if (this.state.hasError[PAGE_ERROR]) {
      return (
        <PageError
          close={this.closeErrorDialog}
          code={this.props.t("Error.404")}
          body={this.props.t("Error.pageError")}
        />
      );
    } else if (this.state.hasError[LOAD_ERROR]) {
      return (
        <PageError
          close={this.closeErrorDialog}
          code={null}
          body={this.props.t("Error.loadError")}
        />
      );
    } else if (this.state.hasError[ERROR_PROCESSING]) {
      return (
        <ErrorProcessingDialog
          open={true}
          close={this.closeErrorDialog}
          title={this.props.t("Error.processingErrorTitle")}
          body={
            IS_CANADA_MARKET(market)
              ? this.props.t("Error.processingErrorMessageCAN")
              : this.props.t("Error.processingErrorMessage")
          }
        />
      );
    }

    return this.props.children;
  }
}

export default withTranslation("emp")(ErrorBoundary);
