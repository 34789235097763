import React, { Component } from "react";
import styles from "./NAOrderDetailsNewVersion.module.scss";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import crStyles from "../../customerReservation/crStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  StyledBasicExpansionPanel,
  StyledBasicExpansionPanelSummary,
} from "../../../shared/ExpansionPanels/ExpansionPanel";
import { InfoDetail } from "./InfoDetail";

class DealerInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandDealerInfo: false,
    };
  }

  expandDealerInfo = () => {
    this.setState((prevState) => ({
      expandDealerInfo: !prevState.expandDealerInfo,
    }));
  };

  render() {
    const { dealerInfo } = this.props;

    return (
      <StyledBasicExpansionPanel
        defaultExpanded={true}
        onChange={() => this.expandDealerInfo()}
      >
        <StyledBasicExpansionPanelSummary
          style={crStyles.orderDetailsExpansion}
          id="exp"
        >
          <div className={styles.headerBar}>
            <div className={styles.headerMargin}>
              <span className={styles.boldHeader}>
                {this.props.t("DealerInfo.dealerInformation")}
              </span>
            </div>
            {!this.state.expandDealerInfo ? (
              <ExpandLessIcon id="less" />
            ) : (
              <ExpandMoreIcon id="more" />
            )}
          </div>
        </StyledBasicExpansionPanelSummary>
        <div className={styles.customerInfoGrid}>
          <div className={styles.bottomBorder} />
          {dealerInfo ? (
            <Grid container className={styles.customerContainer}>
              <Grid item xs={3}>
                <div className={styles.subHeaderText}>
                  {this.props.t("DealerInfo.dealerDetails")}
                </div>

                <InfoDetail
                  header={this.props.t("DealerInfo.dealerName")}
                  value={dealerInfo.name}
                />
                <>
                  <div className={styles.itemHeaderText}>
                    {this.props.t("DealerInfo.dealerAddress")}
                  </div>

                  <div
                    className={`${styles.smallHeaderMargin} ${styles.customerInfoContainer}`}
                  >
                    <div className={styles.customerInfoTextBold}>
                      {dealerInfo.addressLine1}
                    </div>
                    <div className={styles.customerInfoTextBold}>
                      {dealerInfo.addressLine2}
                    </div>
                  </div>
                </>
                <InfoDetail
                  header={this.props.t("DealerInfo.dealerPhone")}
                  value={dealerInfo.phone}
                />
                {this.props.isNAMarket ? (
                  <>
                    <InfoDetail
                      header={this.props.t("DealerInfo.dealerPACode")}
                      value={dealerInfo.paCode}
                    />
                    <InfoDetail
                      header={this.props.t("DealerInfo.dealerSalesCode")}
                      value={dealerInfo.salesCode}
                    />
                  </>
                ) : (
                  <InfoDetail
                    header={this.props.t("DealerInfo.dealerId")}
                    value={dealerInfo.dealerId}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <span className={styles.labelError}>
              {" "}
              {this.props.t("CustomerOrderDetails.errorLoadingCustomerInfo")}
            </span>
          )}
        </div>
      </StyledBasicExpansionPanel>
    );
  }
}

export default withTranslation("emp")(DealerInformation);
