import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_REPORTING_URL, BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class NscReportingClient {
  static baseUrl = BASE_URL;
  static reportingUrl = BASE_REPORTING_URL;

  static downloadDealerActivationReportInCsv(user) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/activation-report`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static downloadDealerReservationReport(user, selectedMarket) {
    const requestBody = {
      userType: user.userType,
      marketId: selectedMarket,
      filterData: false,
    };

    const url = `${this.reportingUrl}/reporting/${selectedMarket}/img-reservations-report`;

    return axios
      .post(url, requestBody, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadAppActionsLogReport(user) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/app-actions-log-report`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
