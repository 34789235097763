import React, { Component } from "react";
import styles from "../customerInfo/CustomerInfo.module.scss";
import sharedStyles from "../OrderDetailsNewVersion.module.scss";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import crStyles from "../../../customerReservation/crStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import UpperCaseText from "../../../../utils/UpperCaseText";
import {
  StyledBasicExpansionPanel,
  StyledBasicExpansionPanelSummary,
} from "../../../../shared/ExpansionPanels/ExpansionPanel";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import { BEV_VEHICLES, TRANSIT_VEHICLES } from "../../../../common/Constants";

const InfoDetail = ({ header: header, value: value }) => (
  <>
    <div className={styles.itemHeaderText}>{header}</div>
    <div className={styles.itemValueText}>{value || "--"}</div>
  </>
);

class OrderInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandOrderInfo: true,
    };
  }

  expandOrderInfo() {
    this.setState((prevState) => ({
      expandOrderInfo: !prevState.expandOrderInfo,
    }));
  }

  canCancel = (status) => {
    const cancellableStatuses = [
      "CREATED",
      "AMENDED",
      "ORDER_CONTRACTED",
      "ORDER_RESERVED",
      "DEALER_REJECTED",
      "ORDERED",
    ];
    return cancellableStatuses.includes(status);
  };

  render() {
    const order = this.props.customerReservation;
    const permissions = this.props.permissions;
    const date_string = "DATE";
    return (
      <div>
        <StyledBasicExpansionPanel
          defaultExpanded={true}
          onChange={() => this.expandOrderInfo()}
        >
          <StyledBasicExpansionPanelSummary
            style={crStyles.headerBarNeutral}
            id="exp"
          >
            <div className={sharedStyles.headerBar}>
              <div className={sharedStyles.boldHeader}>
                {this.props.t("OrderInfo.header")}
              </div>

              <div className={styles.cancelDiv}>
                <HasPermissionTo
                  perform={["cancelOrder:write"]}
                  permissions={permissions}
                  condition={this.canCancel(order.status)}
                  render={() => (
                    <button
                      className={styles.cancelOrderButton}
                      id="cancelOrderBtn"
                      data-testid="cancelOrderBtn"
                      onClick={(e) => this.props.showCancelConfirmationPopup(e)}
                    >
                      {this.props.t("CustomerOrderDetails.cancelBtnTxt")}
                    </button>
                  )}
                />

                {this.state.expandOrderInfo ? (
                  <ExpandLessIcon id="less" />
                ) : (
                  <ExpandMoreIcon id="more" />
                )}
              </div>
            </div>
            <br />
          </StyledBasicExpansionPanelSummary>
          <div className={styles.customerInfoGrid}>
            <div className={styles.bottomBorder} />

            {order ? (
              <Grid container className={styles.customerContainer}>
                <Grid item xs={3}>
                  <div className={styles.infoHeader}>
                    {this.props.t("OrderInfo.orderDetailsSubheader")}
                  </div>
                  <InfoDetail
                    header={this.props.t("OrderInfo.orderNumber")}
                    value={order.id}
                  />
                  <InfoDetail
                    header={this.props.t("OrderDetails.reservationDate")}
                    value={order.reservationDate}
                  />
                  <InfoDetail
                    header={this.props.t("OrderInfo.orderStatus")}
                    value={order.businessStatus}
                  />
                  {order.cancellation &&
                    order.cancellation.cancellationDate && (
                      <InfoDetail
                        header={this.props.t("OrderInfo.cancellationDate")}
                        value={order.cancellation.cancellationDate}
                      />
                    )}
                  {UpperCaseText(order.status) === "CONVERTED_TO_OFFLINE" ? (
                    <InfoDetail
                      header={`${this.props.t(
                        "CustomerReservations.deliveryScheduled"
                      )} ${date_string}`}
                      value={order.modifiedDate}
                    />
                  ) : null}
                  <InfoDetail
                    header={this.props.t("OrderDetails.channel")}
                    value={
                      UpperCaseText(order.orderType) === "RESERVATION"
                        ? order.reservationChannel
                        : order.orderChannel
                    }
                  />
                  {order.orderAmendedDate && (
                    <InfoDetail
                      header={this.props.t("OrderDetails.amendedDate")}
                      value={order.orderAmendedDate}
                    />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <div className={styles.infoHeader}>&nbsp;</div>
                  <InfoDetail
                    header={this.props.t("OrderInfo.vistaDate")}
                    value={order.orbitIdCreatedDate}
                  />
                  <InfoDetail
                    header={this.props.t("OrderInfo.vistaNumber")}
                    value={order.wbdoOrVistaOrderId}
                  />
                  <InfoDetail
                    header={this.props.t("OrderInfo.orbitNumber")}
                    value={order.orbitId}
                  />
                </Grid>

                <Grid item xs={3}>
                  <div className={styles.infoHeader}>
                    {this.props.t("OrderInfo.vehicleDetailsSubheader")}
                  </div>
                  <InfoDetail
                    header={this.props.t("OrderInfo.model")}
                    value={order.nameplate}
                  />
                  <InfoDetail
                    header={this.props.t("OrderInfo.series")}
                    value={
                      order.vehicleFeaturesForIMG &&
                      order.vehicleFeaturesForIMG.MarketedSeries
                    }
                  />
                  {this.props.market === "NZL" && (
                    <InfoDetail
                      header={
                        BEV_VEHICLES.includes(UpperCaseText(order.nameplate))
                          ? this.props.t("OrderInfo.battery")
                          : this.props.t("OrderInfo.engine")
                      }
                      value={
                        order.vehicleFeaturesForIMG &&
                        order.vehicleFeaturesForIMG.PowerTrain
                      }
                    />
                  )}
                  <InfoDetail
                    header={this.props.t("OrderInfo.bodyStyle")}
                    value={
                      order.vehicleFeaturesForIMG &&
                      order.vehicleFeaturesForIMG.BodyStyle
                    }
                  />
                  <InfoDetail
                    header={this.props.t("OrderInfo.colour")}
                    value={
                      order.vehicleFeaturesForIMG &&
                      order.vehicleFeaturesForIMG.PaintCode
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <div className={styles.infoHeader}>&nbsp;</div>
                  <InfoDetail
                    header={this.props.t("OrderInfo.interior")}
                    value={
                      order.vehicleFeaturesForIMG &&
                      order.vehicleFeaturesForIMG.Interior
                    }
                  />
                  <InfoDetail
                    header={this.props.t("OrderInfo.vin")}
                    value={order.vinNumber}
                  />
                  {TRANSIT_VEHICLES === UpperCaseText(order.nameplate) && (
                    <>
                      <InfoDetail
                        header={this.props.t("OrderInfo.capacity")}
                        value={
                          order.vehicleFeaturesForIMG &&
                          order.vehicleFeaturesForIMG.AF8
                        }
                      />
                      <InfoDetail
                        header={this.props.t("OrderInfo.gvm")}
                        value={
                          order.vehicleFeaturesForIMG &&
                          order.vehicleFeaturesForIMG.AF9
                        }
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            ) : (
              <span className={styles.labelError}>
                {" "}
                {this.props.t("CustomerOrderDetails.errorLoadingCustomerInfo")}
              </span>
            )}
          </div>
        </StyledBasicExpansionPanel>
      </div>
    );
  }
}

export default withTranslation("emp")(OrderInfo);
