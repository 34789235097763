import React, { Component } from "react";
import LoadTermsAndConditions from "./termsAndConditions/LoadTermsAndConditions";
import NscDeliveryRanges from "./deliveryRanges/nsc/NscDeliveryRanges";
import HasPermissionTo from "../../common/HasPermissionTo";
import AldCupidId from "./AldCupidId";
import LeadTimes from "./leadTimes/LeadTimes";
import { withTranslation } from "react-i18next";
import SubNav from "../../shared/subnav/SubNav";
import PaymentThreshold from "./paymentThreshold/PaymentThreshold";
import TranslationDetails from "../translations/TranslationDetails";
class NscBusinessSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: this.props.startingTab,
    };
  }

  handleTabChange = (event, selectedTab) => {
    this.setState({ selectedTab });
  };

  getTabs() {
    return [
      {
        menuName: this.props.t("ProgressBar.termsConditions"),
        enabled: true,
        id: "empMembership",
      },
      {
        menuName: this.props.t("ProgressBar.deliveryRanges"),
        enabled: true,
        id: "deliveryRanges",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "deliveryRangesTab"
        ),
      },
      {
        menuName: this.props.t("BusinessSettings.aldCupid"),
        enabled: true,
        id: "aldCupidId",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "aldCupidIdTab"
        ),
      },
      {
        menuName: this.props.t("ProgressBar.leadTimes"),
        enabled: true,
        id: "leadTimes",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "leadTimesTab"
        ),
      },
      {
        menuName: this.props.t("PaymentThreshold.tabCaption"),
        enabled: true,
        id: "paymentThreshold",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "paymentThresholdTab"
        ),
      },
      {
        menuName: this.props.t("ProgressBar.translations"),
        enabled: true,
        id: "translations",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "translationsTab"
        ),
      },
      {
        menuName: this.props.t("ConversionRate.tabCaption"),
        enabled: true,
        id: "conversionRate",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "conversionRateTab"
        ),
      },
    ];
  }

  render() {
    return (
      <div>
        <SubNav
          dashboard={false}
          selectedTab={this.state.selectedTab}
          title={this.props.t("GlobalNavBar.settings")}
          action={this.handleTabChange}
          tabs={this.getTabs()}
          restrictDropdown={this.state.restrictDropdown}
          {...this.props}
        />
        <div className="pageWrapper">
          {this.state.selectedTab === 0 && (
            <LoadTermsAndConditions {...this.props} />
          )}
          <HasPermissionTo
            perform={["deliveryRangesTab"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() =>
              this.state.selectedTab === 1 && (
                <NscDeliveryRanges {...this.props} />
              )
            }
          />
          <HasPermissionTo
            perform={["aldCupidIdTab"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={this.state.selectedTab === 2}
            render={() => {
              return <AldCupidId {...this.props} />;
            }}
          />
          <HasPermissionTo
            perform={["leadTimesTab"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={this.state.selectedTab === 3}
            render={() => <LeadTimes {...this.props} />}
          />
          <HasPermissionTo
            perform={["paymentThresholdTab"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={this.state.selectedTab === 4}
            render={() => <PaymentThreshold {...this.props} />}
          />
          <HasPermissionTo
            perform={["translationsTab"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={this.state.selectedTab === 5}
            render={() => <TranslationDetails {...this.props} />}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(NscBusinessSettings);
