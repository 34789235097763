import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import styles from "./CustomerContract.module.scss";
import HasPermissionTo from "../../../common/HasPermissionTo";
import EditIcon from "../../../shared/Edit/EditIcon";

class CustomerContractHeader extends Component {
  render() {
    return (
      <Grid container className={styles.headerRow}>
        <Grid className={styles.gridHeader} item xs={3}>
          <span>{this.props.t("CustomerContract.dealer")}</span>
        </Grid>
        <Grid className={styles.gridHeaderCentered} item xs={2}>
          <span>{this.props.t("CustomerContract.dateUploaded")}</span>
        </Grid>
        <Grid className={styles.gridHeaderCentered} item xs={2}>
          <span>{this.props.t("CustomerContract.uploadedBy")}</span>
        </Grid>
        <Grid className={styles.gridHeader} item xs={2}>
          <span>{this.props.t("CustomerContract.document")}</span>
        </Grid>
        <Grid className={styles.gridHeaderRight} item xs={2}>
          <HasPermissionTo
            perform={["contractEmail:write"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={
              !this.props.editMode &&
              !this.props.isMainViewingSDLR(this.props.user)
            }
            render={() => {
              return (
                <div className={styles.emailHeader}>
                  <span>{this.props.t("CustomerContract.email")}</span>
                  <div>
                    <EditIcon
                      data-testid="editIcon"
                      onClick={this.props.editEmails}
                    />
                  </div>
                </div>
              );
            }}
            noRender={() => {
              return <span>{this.props.t("CustomerContract.email")}</span>;
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation("emp")(CustomerContractHeader);
