import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import styles from "./ModifyReservationPrice.module.scss";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../../../../common/HasPermissionTo";
import CheckBox from "../../../../../shared/checkBox/CheckBox";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { PAYMENT_THRESHOLD_LIMIT } from "../../../../../common/Constants";

class ReservationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 0,
      maxLimitFormatted: "",
      validationMaxLimitMsg: "",
      validationInvalidFormat: "",
      validationRequired: "",
    };
    ValidatorForm.addValidationRule("changeInPrice", (value, model) => {
      let changeInPrice = true;
      this.props.originalDeposit.forEach((item) => {
        changeInPrice = !(
          model === item.model && item.amount === parseFloat(value).toFixed(2)
        );
      });
      this.props.applyBtn(changeInPrice);
      return true;
    });
  }

  componentDidMount() {
    this.setState({
      maxLimit: PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market]
        ? PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market].maxLimit
        : 99999.99,
      maxLimitFormatted: PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market]
        ? PAYMENT_THRESHOLD_LIMIT[this.props.userDetails.market]
            .maxLimitFormatted
        : "99,999.99",
      validationMaxLimitMsg:
        this.props.t("PaymentThreshold.validations.maxLimit") || "",
      validationInvalidFormat:
        this.props.t("PaymentThreshold.validations.invalidFormat") || "",
      validationRequired: "Value is required" || "",
    });
  }

  priceRef = React.createRef();

  displayDepositPrice = (item, defaultCurrency) => {
    if (item.amount !== null && (!!item.amount || item.amount >= 0)) {
      return (
        <div>
          {defaultCurrency ? defaultCurrency.symbol : ""}{" "}
          {parseFloat(item.amount).toFixed(2)}
        </div>
      );
    }
  };

  render() {
    const { defaultCurrency } = this.props.localizedInfo || {
      defaultCurrency: { symbol: "" },
    };
    return (
      <div id="reservationPrices">
        <Grid
          container
          spacing={0}
          className={`${styles.tableRowHeader} pagePaddingLeft pagePaddingRight`}
        >
          <Grid item md={8}>
            <span id="model" className={styles.reservationInputGridModel}>
              {this.props.user.userType !== "dealer" ||
              this.props.fromQuickstart ? (
                <CheckBox
                  id="checkAllBoxes"
                  isDisabled={this.props.fromQuickstart}
                  checked={this.props.allChecked}
                  onChange={this.props.checkAllCheckBox}
                />
              ) : (
                ""
              )}
              {this.props.t("ModifyReservationPrice.model")}
            </span>
          </Grid>
          <Grid item md={4}>
            <div className={styles.reservationInputGridRightCell}>
              {this.props.t("ModifyReservationPrice.deposit")}
              <HasPermissionTo
                permissions={this.props.user.permissions.rolePermissions}
                perform={["depositPrice:write"]}
                condition={this.props.dealerCanEditReservationPrice()}
                render={() => (
                  <div
                    className={`${styles.Edit} ${
                      this.props.editButtonDisable && styles.disabled
                    }`}
                    id="editReservations"
                    onClick={this.props.editReservations}
                  >
                    <div className={styles.RectangleStart}>&nbsp;</div>
                    <div className={styles.RectangleEnd}>&nbsp;</div>
                  </div>
                )}
              />
            </div>
          </Grid>
        </Grid>
        <div>
          {this.props.reservations.map((item, index) => (
            <Grid
              container
              key={item.model}
              className={`${styles.tableRowContent}  pagePaddingRight ${
                item.checked && styles.selectedRow
              } `}
              style={{
                flexWrap: "nowrap",
                background:
                  item.newVehicleLine &&
                  !item.checked &&
                  "rgba(240, 153, 60, 0.1)",
              }}
            >
              <div className={styles.iconContainer}>
                {item.newVehicleLine && (
                  <ReportProblemOutlinedIcon className={styles.icon} />
                )}
              </div>

              <Grid
                item
                md={9}
                className={`pagePaddingLeft ${styles.rowCellLeft}`}
              >
                {this.props.user.userType !== "dealer" ||
                this.props.fromQuickstart ? (
                  <div className={styles.checkbox}>
                    <CheckBox
                      id={`checkbox${index}`}
                      value={index}
                      checked={item.checked}
                      isDisabled={this.props.fromQuickstart}
                      onChange={this.props.clickCheckBox}
                    />
                  </div>
                ) : (
                  ""
                )}
                {item.model}
              </Grid>
              <Grid
                item
                md={3}
                className={`${styles.reservationInputGridRightCell} ${styles.validatorCell}`}
              >
                {this.props.editMode ? (
                  <div className={styles.amountColumn}>
                    <div>
                      {defaultCurrency ? defaultCurrency.symbol : ""}{" "}
                      &nbsp;&nbsp;
                    </div>
                    <TextValidator
                      autoComplete="off"
                      name={item.model}
                      ref={this.priceRef}
                      variant="outlined"
                      className={styles.inputField}
                      value={
                        item.amount === null || item.amount === ""
                          ? ""
                          : item.amount
                      }
                      onChange={this.props.updateReservation}
                      validators={[
                        "required",
                        "matchRegexp:^[0-9]*(\\.[0-9]{0,2})?$",
                        "minNumber:0",
                        "maxNumber:" + this.state.maxLimit,
                        "changeInPrice:" + item.model,
                      ]}
                      errorMessages={[
                        this.state.validationRequired,
                        this.state.validationInvalidFormat,
                        this.state.validationInvalidFormat,
                        this.state.validationMaxLimitMsg +
                          " " +
                          this.state.maxLimitFormatted,
                        this.state.validationInvalidFormat,
                      ]}
                    />
                  </div>
                ) : (
                  this.displayDepositPrice(item, defaultCurrency)
                )}
              </Grid>
            </Grid>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(ReservationList);
