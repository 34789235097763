import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { withTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;

class PreviewTermsConditions extends Component {
  state = {
    numPages: 0,
    width: 300,
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    const parentDiv = document.querySelector("#pdfDocument");
    this.setState({ numPages: numPages, width: parentDiv.clientWidth });
  };

  render() {
    return (
      <div>
        <div style={this.props.cardStyle} className={this.props.className}>
          <div id="pdfDocument" data-testid="pdfDocument">
            <Document
              file={this.props.file}
              onLoadSuccess={this.onDocumentLoadSuccess}
              //onLoadError={console.error}
              noData={
                <h4 id="docNotFound">
                  {this.props.t("AcceptTermsConditions.docNotFound")}
                </h4>
              }
            >
              {Array.from(new Array(this.state.numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  width={this.state.width}
                  pageNumber={index + 1}
                  className="pdfPage"
                  renderMode="svg"
                />
              ))}
            </Document>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(PreviewTermsConditions);
