/**
 * @return {number}
 */

export default function GetFloatValue(data) {
  return (data && parseFloat(data)) || 0;
}

export function GetFloatToFixedValue(data, fixedValue) {
  return (data && parseFloat(data).toFixed(fixedValue)) || 0;
}
