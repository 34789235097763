import React from "react";
import Grid from "@material-ui/core/Grid";
import { TextValidator } from "react-material-ui-form-validator";
import wrapStyles from "../DealerDeliveryRanges.module.scss";
import { withTranslation } from "react-i18next";

const DealerDeliveryRangesRow = (props) => {
  function getInputField() {
    return (
      <div className={wrapStyles.inputField}>
        <TextValidator
          name={`rangeValue` + props.rowNo.toString()}
          margin="normal"
          variant="outlined"
          value={props.dataRow.value === null ? "" : props.dataRow.value}
          onChange={(e) => props.onPriceChange(e, props.index)}
          validators={[
            "required",
            "minNumber:0",
            "isNumber",
            "isNumericWithTwoDecimalPlaces",
          ]}
          errorMessages={[
            props.t("nscDeliveryBands.required"),
            props.t("nscDeliveryBands.invalid"),
            props.t("nscDeliveryBands.invalid"),
            props.t("nscDeliveryBands.invalid"),
          ]}
        />
      </div>
    );
  }

  return (
    <div className={wrapStyles.rowBorder}>
      <Grid container>
        <Grid item md={4} className={wrapStyles.rowItem}>
          <span> {props.dataRow.from}</span>
          {props.dataRow.to === null ? (
            <span>&nbsp;+</span>
          ) : (
            <span> &nbsp; - {props.dataRow.to}</span>
          )}
        </Grid>
        <Grid item md={4} className={wrapStyles.rowItem}>
          {props.displayToggle(
            props.rowNo.toString(),
            props.dataRow.acceptedByDealer,
            props.dataRow.disabled,
            () => props.updateToggleStatus(props.rowNo.toString())
          )}
        </Grid>
        <Grid
          item
          md={4}
          className={`${wrapStyles.rowItem} ${wrapStyles.right}`}
        >
          {props.editActive ? (
            props.dataRow.acceptedByDealer ? (
              getInputField()
            ) : (
              <span>--</span>
            )
          ) : props.dataRow.acceptedByDealer ? (
            <span>
              {props.currency}
              {props.dataRow.value}
            </span>
          ) : (
            <span>--</span>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation("emp")(DealerDeliveryRangesRow);
