import styles from "./NAOrderDetailsNewVersion.module.scss";
import React from "react";

export const InfoDetail = ({
  header: header,
  value: value,
  headerTooltip: headerTooltip,
  tooltip: tooltip,
  upperCase = true,
}) => {
  return (
    <>
      <div className={styles.itemHeaderText}>
        {header}
        {headerTooltip}
      </div>
      <div
        className={
          upperCase ? styles.itemValueText : styles.itemValueCapitalizeText
        }
      >
        {value || "-"}
        {value && tooltip}
      </div>
    </>
  );
};
