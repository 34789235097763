import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class DealerPreferencesClient {
  static baseUrl = BASE_URL;

  static updateAllOptInPreferences(user, optInRequest) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/preferences/update-preferences`;

    return axios
      .put(url, optInRequest, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getOptInPreferences(user) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/preferences/opt-in-status`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
