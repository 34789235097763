import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL, FORMAT_COMMON_ID } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

class DeliveryRangesClient {
  static baseUrl = BASE_URL;

  static getDeliveryRanges(user) {
    const url = `${this.baseUrl}/nsc/deliveryRanges`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data.result.nscDeliveryRanges)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async saveDeliveryRanges(data, user) {
    const url = `${this.baseUrl}/nsc/deliveryRanges`;
    return await axios
      .post(url, data, getHeaders(user.token))
      .then((response) => response.status)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async getDealerDeliveryRanges(user) {
    const url = `${
      this.baseUrl
    }/dealer/deliveryRanges?dealerCommonId=${FORMAT_COMMON_ID(user.commonId)}`;

    return await axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async saveDealerDeliveryRanges(data, user, commonIds) {
    const url = `${this.baseUrl}/dealer/deliveryRanges?dealerCommonIds=${commonIds}`;
    await axios.post(url, data, getHeaders(user.token));
  }
}

export default DeliveryRangesClient;
