import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class ReservationPriceClient {
  static baseUrl = BASE_URL;

  static retrieveReservationPrices(user) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/reservation-prices`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static updateNscReservationPrice(user, deposit) {
    const payload = {
      userId: user.userId,
      market: user.market.toUpperCase(),
      deposit,
    };

    const url = `${this.baseUrl}/nsc/reservation-deposit-price`;
    return axios
      .put(url, payload, getHeaders(user.token))
      .then(() => Promise.resolve())
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static updateDealerReservationPrice(user, deposit, dealers) {
    const payload = {
      deposit,
      dealerIds: dealers,
      userId: user.userId,
    };

    const url = `${this.baseUrl}/dealer/reservation-deposit-price`;
    return axios
      .put(url, payload, getHeaders(user.token))
      .then(() => Promise.resolve())
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
