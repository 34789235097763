import React, { Component } from "react";
import CustomerReservationTable from "../../../customerReservation/CustomerReservationTable";
import styles from "./OrdersSearch.module.scss";
import SubNav from "../../../../shared/subnav/SubNav";
import { LANGUAGE_MARKET_MAP } from "../../../../common/Constants";
import { AVAILABLE_MARKETS } from "./AvailableMarkets";
import CustomerReservationsClient from "../../../../shared/clients/CustomerReservationsClient";
import LocalizedInfoClient from "../../../../shared/clients/LocalizedInfoClient";
import OrderDetailsNewVersion from "../../../customerOrderDetail/orderDetailsNewVersion/OrderDetailsNewVersion";
import { is400 } from "../../../../errors/ErrorConstants";
import BackToButton from "../../../../shared/backToButton/BackToButton";
import { withTranslation } from "react-i18next";
import AuthenticationClient from "../../../../shared/clients/AuthenticationClient";

class OrdersSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      reservations: {},
      showOrderDetails: false,
      commonId: "",
      orderId: "",
      loaded: false,
      selectedMarket: undefined,
      localizedInfo: {},
      renderApp: false,
    };
    this.showCountryDropDown =
      this.props.user.permissions.rolePermissions.hasOwnProperty(
        "imgCountryDropDown:view"
      );
  }

  componentDidMount() {
    this.setState({
      selectedMarket: this.props.user.market,
      listOfMarkets: this.props.groups,
    });
    const defaultUserMarket = this.props.user.market.toUpperCase();

    this.setState({ selectedMarket: defaultUserMarket });
    this.fetchLocalizedInfo(defaultUserMarket);
  }

  componentWillUnmount() {
    this.props.user.removeReadOnlyPermissions();
  }

  onMarketChange = (event) => {
    event.preventDefault();
    const market = event.target.value;

    this.setState({
      selectedMarket: market,
      listOfMarkets: this.props.groups,
    });
  };

  showOrderDetails = (commonId, orderId, showDetails) => {
    if (showDetails) {
      AuthenticationClient.getReadOnlyPermissions(
        this.props.user,
        this.state.selectedMarket
      ).then((res) => {
        this.props.user.addReadOnlyPermissions(res);
        this.setState({ showOrderDetails: showDetails, commonId, orderId });
      });
    } else {
      this.props.user.removeReadOnlyPermissions();
      this.setState({ showOrderDetails: showDetails, commonId, orderId });
    }
  };

  searchOrders = (e) => {
    this.props.hideOrShow(true);
    e.preventDefault();
    CustomerReservationsClient.searchCustomerReservations(
      this.state.searchQuery.trim(),
      this.props.user,
      LANGUAGE_MARKET_MAP[this.state.selectedMarket.toUpperCase()],
      null,
      this.state.selectedMarket
    )
      .then((response) => {
        this.parseReservations(response);
      })
      .catch((error) => {
        if (is400(error)) {
          this.setState({ reservations: {} });
        }
        this.setState({ loaded: true, reservations: {} });
        this.props.hideOrShow(false);
      });
  };

  parseReservations(reservations) {
    const tempReservations = {};
    reservations.forEach((reservation) => {
      if (reservation.dealerId in tempReservations) {
        tempReservations[reservation.dealerId].push(reservation);
      } else {
        tempReservations[reservation.dealerId] = [];
        tempReservations[reservation.dealerId].push(reservation);
      }
    });

    this.setState({
      reservations: tempReservations,
      loaded: true,
    });
    this.props.hideOrShow(false);
  }

  updateSearchQuery = (event) =>
    this.setState({ searchQuery: event.target.value });

  changeMarket = (e) => {
    this.setState({
      selectedMarket: e.target.value,
      reservations: {},
      searchQuery: "",
      loaded: false,
    });
    this.fetchLocalizedInfo(e.target.value);
  };

  fetchLocalizedInfo = (marketID) => {
    LocalizedInfoClient.getLocalizedInfo(this.props.user, marketID)
      .then((result) => {
        this.setState({ localizedInfo: result });
      })
      .finally(() => {
        this.setState({ renderApp: true });
      });
  };

  render() {
    return this.state.renderApp ? (
      !this.state.showOrderDetails ? (
        <div>
          <section className="pageWrapper">
            {this.props.user.permissions.rolePermissions.hasOwnProperty(
              "searchLandingPage"
            ) && (
              <BackToButton
                backToUrl={this.props.isCrc ? "/" : "/search"}
                display={this.props.t("SearchWrapper.backToSearch")}
              />
            )}
            <SubNav
              dashboard={false}
              showCustomDropdown={this.showCountryDropDown}
              showCustomMarketDropdown={this.showMarketDropDown}
              selectedMarket={this.state.selectedMarket}
              listOfMarkets={this.state.listOfMarkets}
              updateMarket={this.onMarketChange}
              value={this.state.selectedMarket}
              dropDownValues={AVAILABLE_MARKETS}
              onChange={this.changeMarket}
              title="RESERVATIONS"
              {...this.props}
            />
            <div className={styles.bottomPadding}>
              <div className={styles.indent}>
                <p className={styles.subheader}>
                  To search for a consumer order, enter a valid customer email
                  address or Reservation ID number.
                </p>
                <div className={styles.searchWrapper}>
                  SEARCH BY:
                  <form className={styles.form} onSubmit={this.searchOrders}>
                    <input
                      value={this.state.searchQuery}
                      data-testid="crcInput"
                      name="search"
                      type="text"
                      className={styles.inputField}
                      onChange={this.updateSearchQuery}
                      placeholder="Customer email or Reservation ID"
                      required
                    />
                    <button
                      className={styles.searchButton}
                      type="submit"
                      id="searchBtn"
                      data-testid="searchBtn"
                    >
                      <div className={styles.searchIcon} />
                    </button>
                  </form>
                </div>
              </div>
              {Object.keys(this.state.reservations).length !== 0
                ? Object.keys(this.state.reservations).map(
                    (commonDealerId, index) => {
                      return (
                        <CustomerReservationTable
                          {...this.props}
                          showOrderDetails={this.showOrderDetails}
                          key={commonDealerId}
                          isNADealer={false}
                          index={index}
                          fromCrc={true}
                          commonDealerId={commonDealerId}
                          selectedMarket={this.state.selectedMarket}
                          totalNumberOfResults={
                            this.state.reservations[commonDealerId].length
                          }
                          reservations={this.state.reservations[commonDealerId]}
                        />
                      );
                    }
                  )
                : this.state.loaded && (
                    <div
                      id="noOrders"
                      className={styles.noOrders}
                      key="none-found"
                    >
                      There are no orders that match your search. Please try
                      again.
                    </div>
                  )}
            </div>
          </section>
        </div>
      ) : (
        <OrderDetailsNewVersion
          {...this.props}
          fromCrc={true}
          selectedMarket={this.state.selectedMarket}
          lang={LANGUAGE_MARKET_MAP[this.state.selectedMarket.toUpperCase()]}
          showOrderDetails={this.showOrderDetails}
          userDetails={this.props.userDetails}
          reservationId={this.state.orderId}
          commonId={this.state.commonId}
          localizedInfo={this.state.localizedInfo}
        />
      )
    ) : (
      ""
    );
  }
}

export default withTranslation("emp")(OrdersSearch);
