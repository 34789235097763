import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class DealerDetailsClient {
  static baseUrl = BASE_URL;

  static updateDealerEmailAddress(user, completeDealerId, emailAddress) {
    const url = `${this.baseUrl}/dealer/${completeDealerId}/info/email`;
    const payLoad = {
      emailAddress: emailAddress,
      dealerRegionalId: completeDealerId,
    };

    return this.axiosPut(user, url, payLoad);
  }

  static updateDealerPhoneNumber(user, completeDealerId, phoneNumber) {
    const url = `${this.baseUrl}/dealer/${completeDealerId}/info/phone`;
    const payLoad = {
      phoneNumber: phoneNumber,
      dealerRegionalId: completeDealerId,
    };

    return this.axiosPut(user, url, payLoad);
  }

  static axiosPut = (user, url, payLoad) => {
    return axios
      .put(url, payLoad, getHeaders(user.token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  };

  static getDealerEmployees(user, currentDealerId) {
    const url = `${this.baseUrl}/dealer/${currentDealerId}/employees`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static addEmployee(user, employee, currentDealerId) {
    const url = `${this.baseUrl}/dealer/${currentDealerId}/employees`;

    return axios
      .post(url, employee, getHeaders(user.token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static updateDealerEmployee(user, employee) {
    const url = `${this.baseUrl}/${user.generateResourcePath()}/employees/${
      employee.id
    }`;

    return axios
      .put(url, employee, getHeaders(user.token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static deleteDealerEmployee(user, employeeId) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/employees/${employeeId}`;

    return axios
      .delete(url, getHeaders(user.token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static updateDealerEmployeeEmailNotifications(user, employee, receiveEmails) {
    const url = `${this.baseUrl}/${user.generateResourcePath()}/employees/${
      employee.id
    }/receiveNotificationEmails`;
    return this.updateNotifications(
      user,
      url,
      receiveEmails,
      employee.dealerId
    );
  }

  static updateDealerEmployeePendingActionsEmails(
    user,
    employee,
    receiveEmails
  ) {
    const url = `${this.baseUrl}/${user.generateResourcePath()}/employees/${
      employee.id
    }/receivePendingActionEmails`;
    return this.updateNotifications(
      user,
      url,
      receiveEmails,
      employee.dealerId
    );
  }

  static updateNotifications(user, url, receiveEmails, dealerId) {
    return axios
      .put(
        url,
        { receiveEmails: receiveEmails, dealerId: dealerId },
        getHeaders(user.token)
      )
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
