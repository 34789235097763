/** =============================================================================================
 *  Progress bar along with header
 *  EXAMPLE:: <ProgressBar tabs={this.state.tabs} currentTab={this.state.tabs[0]}/>
 *
 *  REQUIRED PROPS:
 *  tabs: this will take array of objects with following structure
 *  [{key: 0, name: 'TERMS & CONDITIONS', status: 'visited'}, {key: 1, name: 'PRE-ORDER DEPOSIT PRICING', status: 'active'}]
 *  status can hold one of the following values: '', 'active', 'visited'
 *
 *  currentTab: this will take an object of active tabs with following structure
 *  {key: 0, name: 'TERMS & CONDITIONS', status: 'active'}
 *
 *  OPTIONAL PROPS:
 *  onTabChange: this is a click event listener which will send the object of clicked tab.
 *  pageTitle: This will be on top of the page. if not given page Header will be hidden.
 *
 * NOTE:
 * if key === 0, it will not display in the progress bar. progress bar will only display tabs with key values > 0
 **  ==========================================================================================**/

import React, { Component } from "react";
import styles from "./ProgressBar.module.scss";
import PageHeader from "../../shared/pageHeader/PageHeader";
import { withTranslation } from "react-i18next";
import { IS_SA_MARKET } from "../../utils/EmpUtil";

class ProgressBar extends Component {
  render() {
    return (
      <>
        {this.props.pageTitle ? (
          <PageHeader title={this.props.pageTitle} />
        ) : (
          ""
        )}

        <div
          className={`${styles.progressBar}`}
          id="progressBar"
          data-testid="progressBar"
        >
          {this.props.tabs
            .filter((item) => item.key > 0)
            .map((item, index) => {
              return (
                <div
                  className={`${styles.navTab} ${
                    this.props.currentTab.key === item.key ? styles.active : ""
                  }`}
                  onClick={() =>
                    this.props.onTabChange ? this.props.onTabChange(item) : ""
                  }
                  key={item.key}
                >
                  {index > 0 ? (
                    <div className={styles.connectingLine}>
                      <span>&nbsp;</span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={styles.navStepButton}>
                    {!IS_SA_MARKET(this.props.marketId) && (
                      <div className={styles.progressTabNumber}>
                        <span>{item.key}</span>
                      </div>
                    )}
                    <div className={styles.progressTab}>
                      {this.props.t(item.name)}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </>
    );
  }
}

export default withTranslation("emp")(ProgressBar);
