import React, { Component } from "react";
import DealershipDetails from "./dealershipdetails/DealershipDetails";
import MerchantId from "./merchantId/MerchantId";
import { withTranslation } from "react-i18next";
import AcceptTermsAndConditions from "./termsAndConditions/AcceptTermsAndConditions";
import { InfoIconWithMessage } from "../../shared/infoIcon/InfoIcon";
import LoadTermsAndConditions from "./termsAndConditions/LoadTermsAndConditions";
import EmpMembership from "./EmpMembership";
import DealerDeliveryRangeAndPrice from "./deliveryRanges/dealer/DealerDeliveryRanges";
import SubNav from "../../shared/subnav/SubNav";
import HasPermissionTo from "../../common/HasPermissionTo";
import CustomerContract from "./customerContract/CustomerContract";
import OptIn from "./optIn/OptIn";
import PaymentThreshold from "./paymentThreshold/PaymentThreshold";
import LeadTimes from "./leadTimes/LeadTimes";
import UpperCaseText from "../../utils/UpperCaseText";
import { IS_VNM_MARKET, IS_ZAF_MARKET } from "../../utils/EmpUtil";

class DealerBusinessSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: this.props.startingTab,
      renderPage: false,
    };
  }

  componentDidMount() {
    this.props.user.businessSettingsPage
      ? this.setState({ selectedTab: this.props.user.businessSettingsPage })
      : this.props.user.updateBusinessSettingsTab(this.state.selectedTab);
    this.setState({ renderPage: true });
  }

  handleTabChange = (event, selectedTab) => {
    this.setState({ selectedTab });
    this.props.user.updateBusinessSettingsTab(selectedTab);
    this.props.resetFromPending();
  };

  getTabs() {
    return [
      {
        menuName: this.props.t("ProgressBar.empMembership"),
        enabled: true,
        id: "empMembership",
      },
      {
        menuName: this.props.t("ProgressBar.dealershipDetails"),
        enabled: true,
        id: "dealership-details",
      },
      {
        menuName: this.props.t("ProgressBar.deliveryRanges"),
        enabled: true,
        id: "deliveryRanges",
        hide:
          !this.props.user.permissions.rolePermissions.hasOwnProperty(
            "dealerDeliveryRangesTab"
          ) || !this.props.user.hasNscCompletedBasicPAs,
      },
      {
        menuName:
          IS_ZAF_MARKET(this.props.user.market) ||
          IS_VNM_MARKET(this.props.user.market)
            ? this.props.t("StoreIdOriginal.header")
            : this.props.t("ProgressBar.storeId"),
        enabled: true,
        id: "merchantId",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "merchantIdTab"
        ),
      },
      {
        menuName: this.props.t("ProgressBar.customerContract"),
        enabled: true,
        id: "customerContract",
        hide:
          !this.props.user.permissions.rolePermissions.hasOwnProperty(
            "customerContractTab"
          ) || !this.props.user.hasNscCompletedBasicPAs,
      },
      {
        menuName: this.props.t("ProgressBar.optIn"),
        enabled: true,
        id: "optIn",
        hide:
          !this.props.user.permissions.rolePermissions.hasOwnProperty(
            "optInTab"
          ) || !this.props.user.hasNscCompletedBasicPAs,
      },
      {
        menuName: this.props.t("ProgressBar.leadTimes"),
        enabled: true,
        id: "leadTimes",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "leadTimesTab"
        ),
      },
      {
        menuName: UpperCaseText(this.props.t("PaymentThreshold.tabCaption")),
        enabled: true,
        id: "paymentThreshold",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "paymentThresholdTab"
        ),
      },
    ];
  }

  renderTermsConditionsTab() {
    return this.props.isDealerAcceptingTerms ? (
      <AcceptTermsAndConditions {...this.props} />
    ) : (
      <>
        <div className="pagePaddingLeft pagePaddingTop">
          <InfoIconWithMessage
            message={this.props.t("AcceptTermsConditions.subheader")}
          />
        </div>
        <LoadTermsAndConditions {...this.props} />
        <HasPermissionTo
          perform={["empMembership:view"]}
          permissions={this.props.user.permissions.rolePermissions}
          render={() => {
            return <EmpMembership {...this.props} />;
          }}
        />
      </>
    );
  }

  render() {
    return (
      this.state.renderPage && (
        <>
          <SubNav
            dashboard={false}
            selectedTab={this.state.selectedTab}
            title={this.props.t("GlobalNavBar.settings")}
            action={this.handleTabChange}
            tabs={this.getTabs()}
            restrictDropdown={this.props.restrictDropdown}
            {...this.props}
          />
          <div className="pageWrapper">
            {this.state.selectedTab === 0 && this.renderTermsConditionsTab()}
            {this.state.selectedTab === 1 && (
              <DealershipDetails {...this.props} />
            )}
            <HasPermissionTo
              perform={["dealerDeliveryRangesTab"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 2}
              render={() => {
                return <DealerDeliveryRangeAndPrice {...this.props} />;
              }}
            />
            <HasPermissionTo
              perform={["merchantIdTab"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 3}
              render={() => {
                return <MerchantId {...this.props} fromQuickstart={false} />;
              }}
            />
            <HasPermissionTo
              perform={["customerContractTab"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 4}
              render={() => {
                return <CustomerContract {...this.props} />;
              }}
            />
            <HasPermissionTo
              perform={["optInTab"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 5}
              render={() => {
                return <OptIn {...this.props} />;
              }}
            />
            <HasPermissionTo
              perform={["leadTimesTab"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 6}
              render={() => <LeadTimes {...this.props} />}
            />
            <HasPermissionTo
              perform={["paymentThresholdTab"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 7}
              render={() => <PaymentThreshold {...this.props} />}
            />
          </div>
        </>
      )
    );
  }
}

export default withTranslation("emp")(DealerBusinessSettings);
