const subNavStyles = {
  tab: {
    color: "#ffffff",
    whiteSpace: "nowrap",
    maxWidth: "none",
  },
  menuItem: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "ProximaNovaSemibold, sans-serif",
    //backgroundColor: "#102b4e",
    color: "white",
    whiteSpace: "nowrap",
    maxWidth: "none",
  },
  highlightedMenuItem: {
    fontSize: "16px",
    color: "white",
    fontFamily: "ProximaNovaSemibold, sans-serif",
    whiteSpace: "nowrap",
    boxShadow:
      "inset 0 1px 3px 0 rgba(18, 51, 88, 0.09), inset 12px 3px 11px 4px rgba(20, 57, 95, 0.27)",
    backgroundImage: "linear-gradient(to bottom, #324047, #324047)",
    maxWidth: "none",
  },
  highlighted: {
    color: "white",
    fontFamily: "ProximaNovaSemibold, sans-serif",
    fontWeight: 500,
    whiteSpace: "nowrap",
    boxShadow:
      "inset 0 1px 3px 0 rgba(18, 51, 88, 0.09), inset 12px 3px 11px 4px rgba(20, 57, 95, 0.27)",
    backgroundImage: "linear-gradient(to bottom, #000000, #000000)",
    maxWidth: "none",
  },
  dropdown: {
    width: 390,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
  },
  modelDropDown: {
    height: 50,
    width: 250,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  smallDropDown: {
    height: 50,
    width: 200,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },

  item: {
    fontFamily: "ProximaNova, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
  },
  icon: {
    top: "calc(50% - 12px)",
    right: 0,
    color: "#102b4e",
    position: "absolute",
    paddingRight: 10,
    cursor: "pointer",
    pointerEvents: "none",
  },
  orangeIcon: {
    top: "calc(50% - 12px)",
    right: 0,
    color: "#eb6147",
    position: "absolute",
    paddingRight: 10,
    cursor: "pointer",
    pointerEvents: "none",
  },
  hide: {
    display: "none",
  },
  disabledTab: {
    color: "#979797",
    fontFamily: "ProximaNova, sans-serif",
    whiteSpace: "nowrap",
    maxWidth: "none",
    cursor: "default",
  },
};

export default subNavStyles;
