import React, { Component } from "react";
import styles from "./EditIcon.module.scss";

export default class EditIcon extends Component {
  render() {
    return (
      <div
        data-testid="editIcon"
        id={"edit" + this.props.id}
        className={styles.Edit}
        onClick={this.props.onClick}
      >
        <div className={styles.RectangleStart}>&nbsp;</div>
        <div className={styles.RectangleEnd}>&nbsp;</div>
      </div>
    );
  }
}
