import React from "react";
import styles from "./PageHeader.module.scss";
import HasPermissionTo from "../../common/HasPermissionTo";
import LanguageUtil from "../../utils/LanguageUtil";
import { THAILAND_ENGLISH, THAILAND_THAI } from "../../common/Constants";

const PageHeader = (props) => {
  return (
    <div>
      <HasPermissionTo
        perform={["languagePreference:write"]}
        permissions={props.user.permissions.rolePermissions}
        render={() => {
          return (
            <div className={`${styles.pageHeader} ${props.className}`}>
              <div className={styles.pageHeaderText}>{props.title}</div>
              <div>
                <button
                  onClick={() =>
                    LanguageUtil.updateLanguage(props, THAILAND_ENGLISH)
                  }
                >
                  English
                </button>
                <button
                  onClick={() =>
                    LanguageUtil.updateLanguage(props, THAILAND_THAI)
                  }
                >
                  ไทย
                </button>
              </div>
            </div>
          );
        }}
        noRender={() => {
          return (
            <div className={`${styles.pageHeader} ${props.className}`}>
              <div className={styles.pageHeaderText}>{props.title}</div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default PageHeader;
