import React from "react";
import styles from "./CustomerContract.module.scss";
import Grid from "@material-ui/core/Grid";
import DownloadButton from "../../../imgs/download.svg";
import UploadButton from "../../../imgs/upload.svg";
import HasPermissionTo from "../../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import { TextValidator } from "react-material-ui-form-validator";

const CustomerContractRow = (props) => {
  const hideRetail = props.hideForRetail(props.contract.dealerType);
  return (
    <div className={styles.borderBottom} key={props.key}>
      <Grid container key={props.key} className="pagePaddingLeft">
        <Grid className={`${styles.gridRow} ${styles.dealerName}`} item xs={3}>
          {hideRetail
            ? props.contract.dealerId +
              " | " +
              props.contract.name +
              " (RETAIL)"
            : props.contract.dealerId + " | " + props.contract.name}
        </Grid>
        <Grid
          className={`${styles.gridRowCentered} ${styles.greyText}`}
          item
          xs={2}
        >
          {props.contract.contract ? props.contract.dateUploaded || "-" : "-"}
        </Grid>
        <Grid
          className={`${styles.gridRowCentered} ${styles.greyText}`}
          item
          xs={2}
        >
          {props.contract.contract
            ? (props.contract.uploadedBy &&
                props.contract.uploadedBy.toUpperCase()) ||
              "-"
            : "-"}
        </Grid>
        <Grid className={styles.gridRow} item xs={2}>
          {props.contract.contract ? (
            <div className={styles.downloadDiv}>
              <div className={styles.download}>
                <span
                  onClick={() => props.handleContractDownload(props.contract)}
                  className={styles.updateText}
                >
                  {props.t("CustomerContract.download")}
                </span>
                <img
                  alt="Download"
                  onClick={() => props.handleContractDownload(props.contract)}
                  src={DownloadButton}
                />
                <HasPermissionTo
                  perform={["customerContract:write"]}
                  permissions={props.user.permissions.rolePermissions}
                  condition={!hideRetail}
                  render={() => {
                    return (
                      <a
                        className={styles.updateLink}
                        onClick={() =>
                          props.updateShowDialogForADealer(props.contract)
                        }
                      >
                        {props.t("CustomerContract.update")}
                      </a>
                    );
                  }}
                />
              </div>
            </div>
          ) : (
            <HasPermissionTo
              perform={["customerContract:write"]}
              permissions={props.user.permissions.rolePermissions}
              render={() => {
                return (
                  <div className={styles.uploadFlexBox}>
                    <div className={!hideRetail ? styles.pointer : ""}>
                      {hideRetail ? (
                        "-"
                      ) : (
                        <div
                          onClick={() =>
                            props.updateShowDialogForADealer(props.contract)
                          }
                        >
                          <span className={styles.updateText}>
                            {props.t("CustomerContract.upload")}
                          </span>
                          <img alt="Upload" src={UploadButton} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              }}
            />
          )}
        </Grid>
        <Grid
          className={`${styles.gridRowRight} ${styles.greyText}`}
          item
          xs={2}
        >
          {props.editMode ? (
            hideRetail ? (
              <span data-testid={"email_" + props.contract.dealerId}>
                {props.contractEmails[props.contract.dealerId]}
              </span>
            ) : (
              <TextValidator
                name={"emailInput_" + props.contract.dealerId}
                className={styles.inputBox}
                value={props.contractEmails[props.contract.dealerId]}
                onChange={(e) =>
                  props.handleEmailChange(props.contract.dealerId, e)
                }
                variant="outlined"
                validators={["required", "isEmail"]}
                errorMessages={[
                  props.t("Error.requiredField"),
                  props.t("Error.invalidEmail"),
                ]}
              />
            )
          ) : (
            <span data-testid={"email_" + props.contract.dealerId}>
              {props.contractEmails[props.contract.dealerId]}
            </span>
          )}
        </Grid>
        <Grid className={styles.gridRowCentered} item xs={1}>
          {props.contract.showMessage && (
            <span className={styles.updateCompleted}>
              {props.t("CustomerContract.updateCompleted")}
            </span>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation("emp")(CustomerContractRow);
