import React, { Component } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import styles from "./CustomerReservation.module.scss";
import crStyles from "./crStyles.js";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../common/HasPermissionTo";
import SortingIcon from "../../shared/sortingIcon/SortingIcon";
import TitleCase from "../../utils/TitleCase";
import CustomerReservationsClient from "../../shared/clients/CustomerReservationsClient";
import UiDateUtils from "../../utils/UiDateUtils";
import LowerCaseText from "../../utils/LowerCaseText";
import UpperCaseText from "../../utils/UpperCaseText";
import { PURCHASE_REQUEST_STATUS } from "../../common/Constants";
import ConvertButton from "../../imgs/convert.svg";
import { FORMAT_COMMON_ID, GET_DEALER_NAME } from "../../utils/EmpUtil";
import { displayError, ERROR_PROCESSING } from "../../errors/ErrorConstants";
import DeliveryScheduleDialog from "./DeliveryScheduleDialog";

class CustomerReservationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortDir: undefined,
      sortKey: undefined,
      sortByString: undefined,
      iconUp: true,
      loading: false,
      reservationId: undefined,
      hasError: [],
    };
    this.statusMap = {
      CREATED: "created",
      RESERVATION_FAILED: "created",
      ORDER_RESERVED: "created",
      CONTRACT_REQUESTED: "confirmed",
      CONTRACT_REQUEST_FAILED: "confirmed",
      CONTRACT_FAILED: "confirmed",
      CONTRACT_CANCEL_REQUESTED: "ordered",
      CONTRACT_CANCEL_REQUEST_FAILED: "ordered",
      RESERVATION_CANCELLED: "cancelled",
      DEALER_CANCELLED: "cancelled",
      CONTRACT_CANCELLED: "cancelled",
      CONVERTED_TO_OFFLINE: "amended",
      AMENDED: "amended",
      ORDER_CONTRACTED: "contracted",
      ORDER_CONFIRMED: "submitted",
      CONTRACT_CANCEL_FAILED: "ordered",
      RESERVATION_CANCELLATION_FAILED: "contracted",
      AMEND_CONTRACT_REQUEST_FAILED: "contracted",
      AMEND_CONTRACT_CANCEL_REQUEST_FAILED: "contracted",
      ORDERED: "ordered",
    };

    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }
  componentDidMount() {
    if (
      this.props.lastPath &&
      this.props.lastPath.includes("/customer-reservations/order-details/") &&
      sessionStorage.getItem(
        "sortKey_" + this.props.commonDealerId.split("|").join("%7C")
      ) &&
      sessionStorage.getItem(
        "sortKey_" + this.props.commonDealerId.split("|").join("%7C")
      ) !== "undefined"
    ) {
      const sortKey = sessionStorage.getItem(
        "sortKey_" + this.props.commonDealerId.split("|").join("%7C")
      );
      const sortDirection = sessionStorage.getItem(
        "sortDirection_" + this.props.commonDealerId.split("|").join("%7C")
      );
      const sortByString = sessionStorage.getItem(
        "sortByString_" + this.props.commonDealerId.split("|").join("%7C")
      );
      this.handleSort(sortKey, sortDirection, sortByString);
    }
  }
  componentWillUnmount() {
    if (this.props.searchEnabled) {
      const keys = Object.keys(sessionStorage);
      for (const key in keys) {
        const value = keys[key];
        if (
          value.startsWith("pageNumber_") ||
          value.startsWith("sortKey_") ||
          value.startsWith("sortDirection") ||
          value.startsWith("sortByString") ||
          value === "scrollPos"
        ) {
          sessionStorage.removeItem(value);
        }
      }
    } else {
      if (
        sessionStorage.getItem(
          "pageNumber_" + this.props.commonDealerId.split("|").join("%7C")
        ) !== this.props.currentPage
      ) {
        sessionStorage.setItem(
          "pageNumber_" + this.props.commonDealerId.split("|").join("%7C"),
          this.props.currentPage
        );
      }

      sessionStorage.setItem(
        "sortKey_" + this.props.commonDealerId.split("|").join("%7C"),
        this.state.sortKey
      );
      sessionStorage.setItem(
        "sortDirection_" + this.props.commonDealerId.split("|").join("%7C"),
        this.state.sortDir
      );
      sessionStorage.setItem(
        "sortByString_" + this.props.commonDealerId.split("|").join("%7C"),
        this.state.sortByString
      );
    }
  }

  onClose = () => {
    this.setState({ open: false });
  };

  handlePrevious() {
    this.props.hideOrShow(true);
    this.setState({ loading: true });
    CustomerReservationsClient.getCustomerReservations(
      this.props.user.token,
      this.props.commonDealerId.split("|").join("%7C"),
      this.props.currentPage - 2,
      this.props.lang,
      this.state.sortByString
    )
      .then((result) => {
        this.updateState(
          result.reservations,
          result.pagination.numberOfPages,
          result.pagination.totalNumberOfResults,
          this.props.currentPage - 1
        );
      })
      .catch()
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({
          loading: false,
        });
      });
  }

  handleNext() {
    this.props.hideOrShow(true);
    this.setState({ loading: true });
    CustomerReservationsClient.getCustomerReservations(
      this.props.user.token,
      this.props.commonDealerId.split("|").join("%7C"),
      this.props.currentPage,
      this.props.lang,
      this.state.sortByString
    )
      .then((result) => {
        this.updateState(
          result.reservations,
          result.pagination.numberOfPages,
          result.pagination.totalNumberOfResults,
          this.props.currentPage + 1
        );
      })
      .catch()
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({
          loading: false,
        });
      });
  }

  handleSort(sortKey, newDirection, sortByString) {
    this.props.hideOrShow(true);
    this.setState({ sortKey, sortDir: newDirection, sortByString });

    CustomerReservationsClient.getCustomerReservations(
      this.props.user.token,
      this.props.commonDealerId.split("|").join("%7C"),
      this.props.currentPage - 1,
      this.props.lang,
      sortByString
    )
      .then((result) => {
        this.updateState(
          result.reservations,
          result.pagination.numberOfPages,
          result.pagination.totalNumberOfResults,
          this.props.currentPage
        );
      })
      .catch()
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({ loading: false });
      });
  }

  getSortString(sortKey) {
    this.setState({ loading: true });
    let newDirection = "DSC";
    if (sortKey === this.state.sortKey) {
      newDirection = this.state.sortDir === "ASC" ? "DSC" : "ASC";
    }

    if (sortKey === "date") {
      newDirection === "ASC"
        ? this.handleSort(sortKey, newDirection, "byDateAsc")
        : this.handleSort(sortKey, newDirection, "byDate");
    } else if (sortKey === "id") {
      newDirection === "ASC"
        ? this.handleSort(sortKey, newDirection, "byOrderNumber")
        : this.handleSort(sortKey, newDirection, "byOrderNumberDesc");
    }
  }

  updateState(reservations, numPages, numResults, newPage) {
    reservations.forEach((reservation) => {
      reservation.orderType =
        reservation.orderType === "pre-order"
          ? this.props.t("CustomerReservations.preOrder")
          : reservation.orderType;
    });

    this.props.updateTables(
      reservations,
      numPages,
      numResults,
      this.props.commonDealerId,
      newPage
    );
    this.loading = false;
  }

  determineRegionalId() {
    const filteredDealer = this.props.user.dealerGroup.filter(
      (dealer) => dealer.commonId === this.props.commonDealerId
    );
    if (filteredDealer && filteredDealer.length === 1)
      return filteredDealer[0].regionalId;
  }

  determineDealerId() {
    const filteredDealer = this.props.user.dealerGroup.filter(
      (dealer) => dealer.commonId === this.props.commonDealerId
    );
    if (filteredDealer && filteredDealer.length === 1)
      return filteredDealer[0].completeDealerId;
  }

  determineDealerName(dealerName) {
    if (this.props.user.dealerGroup) {
      const filteredDealer = this.props.user.dealerGroup.filter(
        (dealer) => dealer.commonId === this.props.commonDealerId
      );
      if (filteredDealer && filteredDealer.length === 1) {
        return GET_DEALER_NAME(filteredDealer[0], this.props.user.market);
      }
    } else return dealerName;
  }

  flipIcon() {
    const current = this.state.iconUp;
    this.setState({ iconUp: !current });
  }

  resolveInlineStyle(status) {
    switch (status) {
      case "created":
      case "contracted":
      case "confirmed":
      case "ordered":
        return crStyles.confirmedStatus;
      case "cancelled":
        return crStyles.cancelledStatus;
      case "amended":
        return crStyles.amendedStatus;
      default:
        return crStyles.commonStatusStyle;
    }
  }

  resolvePurchaseStyle(status) {
    const statusCaps = status ? UpperCaseText(status) : "";
    switch (statusCaps) {
      case PURCHASE_REQUEST_STATUS.ACCEPTED:
        return crStyles.confirmedStatus;
      case PURCHASE_REQUEST_STATUS.CANCELLED:
        return crStyles.cancelledStatus;
      default:
        return crStyles.amendedStatus;
    }
  }

  getSpecs(reservation) {
    // Just return Spec Summary for all scenario
    return (
      reservation.specSummary &&
      reservation.specSummary.filter(Boolean).join(" / ")
    );
  }

  translateOrderType(reservation) {
    //Purchase Request
    if (reservation.purchaseType || reservation.purchaseRequestDate) {
      return this.props.t("CustomerReservations.purchaseRequest");
    }
    //Order
    else if (LowerCaseText(reservation.orderType).includes("order")) {
      return this.props.t("CustomerReservations.order");
    } else return reservation.orderType;
  }
  setStateAndOpenDialog = (reservationId) => {
    this.setState({
      reservationId,
      open: true,
    });
  };

  render() {
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    function getTableHeader(style, divStyle, column) {
      return this.props.fromCrc ? (
        <TableCell id={column + this.props.commonDealerId} style={style}>
          <div className={divStyle}>
            {this.props.t("CustomerReservations." + column)}
          </div>
        </TableCell>
      ) : (
        <TableCell
          data-testid={column + this.props.commonDealerId}
          style={style}
          onClick={() =>
            !this.props.searchEnabled && this.getSortString(column)
          }
        >
          <div className={divStyle} data-testid="sort">
            {this.props.t("CustomerReservations." + column)}
            <SortingIcon
              hide={this.props.searchEnabled}
              currentKey={column}
              sortKey={this.state.sortKey}
              sortDirection={this.state.sortDir}
            />
          </div>
        </TableCell>
      );
    }

    return (
      <div key={this.props.index} className={styles.tablePadding}>
        <ExpansionPanel
          styles={crStyles.expansionPanel}
          defaultExpanded={true}
          style={{ boxShadow: "none" }}
          onChange={() => this.flipIcon()}
        >
          <ExpansionPanelSummary style={crStyles.boldHeaderBar} id="exp">
            <div className={styles.headerBar}>
              <Grid container>
                <Grid item md={5} style={crStyles.boldHeaderItem}>
                  <HasPermissionTo
                    perform={["reservationsRegionalId:view"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    render={() => (
                      <span className={styles.pre}>
                        {this.determineDealerId() + "  |  "}
                      </span>
                    )}
                  />
                  {this.determineDealerName(
                    this.props.reservations[0].dealerName
                  )}
                </Grid>
                <Grid item md={2} style={crStyles.boldHeaderItem}>
                  {this.props.totalNumberOfResults}{" "}
                  {this.props.totalNumberOfResults === 1
                    ? this.props.t("CustomerReservations.item")
                    : this.props.t("CustomerReservations.items")}
                </Grid>
                <Grid item md={3} />
                <Grid item md={2} style={crStyles.dropdown}>
                  {this.state.iconUp ? (
                    <ExpandLessIcon id="less" />
                  ) : (
                    <ExpandMoreIcon id="more" />
                  )}
                </Grid>
              </Grid>
            </div>
            <br />
          </ExpansionPanelSummary>
          <div className={styles.bottomBorder}></div>
          <Table id="newTable" key={this.props.index}>
            {this.state.open && (
              <DeliveryScheduleDialog
                {...this.props}
                open={this.state.open}
                onClose={this.onClose}
                confirmAction={() => {
                  this.props
                    .updateOrder(
                      this.state.reservationId,
                      "INSTORE",
                      FORMAT_COMMON_ID(this.props.commonDealerId)
                    )
                    .then(() => {
                      this.onClose();
                    })
                    .catch(() => {
                      this.setState({ hasError: [ERROR_PROCESSING] });
                    });
                }}
              />
            )}
            <TableHead>
              <TableRow
                style={crStyles.headers}
                className={styles.orderTableHeaderRow}
              >
                <TableCell
                  data-testid={"orderType" + this.props.commonDealerId}
                  style={crStyles.orderTypeHeader}
                >
                  <div className={styles.alignStart}>
                    {this.props.t("CustomerReservations.orderType")}
                  </div>
                </TableCell>
                {getTableHeader.call(
                  this,
                  crStyles.idHeader,
                  styles.alignStart,
                  "id",
                  1
                )}
                {getTableHeader.call(
                  this,
                  crStyles.dateHeader,
                  styles.alignCenter,
                  "date",
                  2
                )}
                <TableCell
                  data-testid={"specs" + this.props.commonDealerId}
                  style={crStyles.specsHeader}
                >
                  <div className={styles.alignStart}>
                    {this.props.t("CustomerReservations.specs")}
                  </div>
                </TableCell>
                <TableCell
                  data-testid={"status" + this.props.commonDealerId}
                  style={crStyles.statusHeader}
                >
                  <div className={styles.alignCenter}>
                    {this.props.t("CustomerReservations.status")}
                  </div>
                </TableCell>
                <TableCell style={crStyles.deliveryScheduledHeader} />
                <TableCell style={crStyles.emptyHeader} />
              </TableRow>
            </TableHead>
            <TableBody
              data-testid="reservationTable"
              className={styles.tableBody}
            >
              {this.props.reservations.map((reservation, index) => (
                <TableRow
                  className="pagePaddingLeft pagePaddingRight"
                  id={"row" + index}
                  key={index}
                >
                  <TableCell style={crStyles.orderType}>
                    {this.translateOrderType(reservation)}
                  </TableCell>
                  <TableCell style={crStyles.orderId}>
                    {reservation.id}
                  </TableCell>
                  <TableCell style={crStyles.date}>
                    {/*Mapping changed to resDate*/}

                    {UiDateUtils.formattedDate(reservation.reservationDate)}
                  </TableCell>
                  <TableCell style={crStyles.specs}>
                    <div className={styles.specsContainer}>
                      <div className={styles.nameplate}>
                        {reservation.nameplate}
                      </div>
                      <span className={styles.specs}>
                        {this.getSpecs(reservation)}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell
                    style={
                      reservation.purchaseRequestStatus
                        ? this.resolvePurchaseStyle(
                            reservation.purchaseRequestStatus
                          )
                        : this.resolveInlineStyle(
                            this.statusMap[reservation.status]
                          )
                    }
                  >
                    {reservation.purchaseRequestStatus
                      ? TitleCase(reservation.purchaseRequestStatus)
                      : TitleCase(reservation.businessStatus)}
                  </TableCell>
                  <TableCell style={crStyles.convert}>
                    <HasPermissionTo
                      perform={["processOfflineSubmit"]}
                      permissions={this.props.user.permissions.rolePermissions}
                      condition={
                        reservation.status === "CREATED" ||
                        reservation.status === "AMENDED"
                      }
                      render={() => (
                        <div
                          className={styles.convertDiv}
                          onClick={() =>
                            this.setStateAndOpenDialog(reservation.id)
                          }
                        >
                          <img alt="CONVERT" src={ConvertButton} />
                          <span className={styles.convertLink}>
                            {this.props.t(
                              "CustomerReservations.deliveryScheduled"
                            )}
                          </span>
                        </div>
                      )}
                    />
                  </TableCell>

                  <TableCell style={crStyles.view}>
                    <HasPermissionTo
                      perform={["crcOrderDetails:view"]}
                      permissions={this.props.user.permissions.rolePermissions}
                      condition={!this.props.fromNscDealerSearch}
                      render={() => (
                        <Button
                          className={styles.tableLink}
                          data-testid="crcView"
                          style={crStyles.viewButton}
                          id={reservation.id}
                          onClick={() =>
                            this.props.showOrderDetails(
                              this.props.commonDealerId,
                              reservation.id,
                              true
                            )
                          }
                        >
                          {this.props.t("CustomerReservations.view")}
                          <ChevronRightIcon style={crStyles.viewIcon} />{" "}
                        </Button>
                      )}
                      noRender={() => (
                        <Link
                          id="reservationLink"
                          to={`/dealer/${this.props.commonDealerId}/customer-reservations/order-details/${reservation.id}`}
                          className={styles.tableLink}
                        >
                          {this.props.t("CustomerReservations.view")}
                          <ChevronRightIcon style={crStyles.viewIcon} />
                        </Link>
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <HasPermissionTo
            perform={["reservationPagination:view"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={!this.props.searchEnabled}
            render={() => (
              <div className={styles.pagesDiv}>
                <button
                  disabled={this.props.currentPage === 1 || this.state.loading}
                  data-testid="prev"
                  onClick={this.handlePrevious}
                >
                  <NavigateBefore
                    style={
                      this.props.currentPage === 1
                        ? crStyles.arrowDisabled
                        : crStyles.arrowEnabled
                    }
                  />
                </button>
                <div className={styles.pages}>
                  {this.props.currentPage} / {this.props.numberOfPages}
                </div>
                <button
                  disabled={
                    this.props.currentPage === this.props.numberOfPages ||
                    this.state.loading
                  }
                  data-testid="next"
                  onClick={this.handleNext}
                >
                  <NavigateNext
                    style={
                      this.props.currentPage === this.props.numberOfPages
                        ? crStyles.arrowDisabled
                        : crStyles.arrowEnabled
                    }
                  />
                </button>
              </div>
            )}
          />
        </ExpansionPanel>
      </div>
    );
  }
}

export default withTranslation("emp")(CustomerReservationTable);
