import React from "react";
import styles from "./RegularDialog.module.scss";
import Clear from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import { Dialog } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const StyledDialog = withStyles({
  paper: {
    width: 500,
    maxWidth: 500,
    padding: 12,
  },
})(Dialog);

const StyledClear = withStyles({
  root: {
    width: 27,
    height: 27,
    fill: "#102b4e",
    align: "right",
  },
})(Clear);

const RegularDialog = (props) => {
  return (
    <StyledDialog open={props.open} className={styles.dialog}>
      <Grid container className={styles.dialogContainer} justify="center">
        <Grid item md={12} className={styles.dialogClose}>
          <div>
            <button onClick={props.close}>
              <StyledClear />
            </button>
          </div>
        </Grid>

        <Grid item md={9}>
          <div className={styles.dialogTitle}>
            {props.icon && <span className={styles.dialogWarning} />}
            <div>{props.title}</div>
          </div>
        </Grid>

        <Grid item md={9}>
          <div className={styles.dialogDescription}>{props.body}</div>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default RegularDialog;
