import React from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import styles from "./OnlineTradeIn.module.scss";

const OnlineTradeIn = (props) => {
  const GridItem = ({ label, value }) => {
    return (
      <Grid item xs={3}>
        <div className={styles.gridItem}>
          <span className={styles.label}>{label}</span>
          <span className={styles.value}>
            {props.parseBoolean(value) || "-"}
          </span>
        </div>
      </Grid>
    );
  };

  return (
    <div className={styles.tradeInWrapper}>
      <span className={styles.subHeader}>
        {props.t("TradeIn.vehicleDetails")}
      </span>
      <Grid container>
        <GridItem
          label={props.t("TradeIn.registrationNumber")}
          value={props.tradeInDetails.registrationNumber}
        />

        <GridItem
          label={props.t("TradeIn.model")}
          value={props.tradeInDetails.vehicleModel}
        />

        <GridItem
          label={props.t("TradeIn.transmission")}
          value={props.tradeInDetails.transmission}
        />

        <GridItem
          label={props.t("TradeIn.tradeInRequest")}
          value={props.tradeInDetails.applyFinanceForTradeIn}
        />
      </Grid>

      <Grid container>
        <GridItem
          label={props.t("TradeIn.vehicleMileage")}
          value={props.tradeInDetails.mileage}
        />

        <GridItem
          label={props.t("TradeIn.bodyType")}
          value={props.tradeInDetails.trim}
        />

        <GridItem
          label={props.t("TradeIn.modelYear")}
          value={props.tradeInDetails.modelYear}
        />

        <Grid item xs={3} />
      </Grid>

      <Grid container>
        <GridItem
          label={props.t("TradeIn.make")}
          value={props.tradeInDetails.vehicleMake}
        />

        <GridItem
          label={props.t("TradeIn.fuelType")}
          value={props.tradeInDetails.fuelType}
        />

        <GridItem
          label={props.t("TradeIn.tradeInValuation")}
          value={props.tradeInDetails.appraisalAmount}
        />

        <Grid item xs={3} />
      </Grid>
    </div>
  );
};

export default withTranslation("emp")(OnlineTradeIn);
