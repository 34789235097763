export const getHeaders = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getHeadersForCsv = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "text/csv;charset=utf-8",
    },
  };
};
