import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import styles from "./SubNav.module.scss";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import subNavStyles from "./subNavStyles";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import RegularDialog from "../Dialog/RegularDialog";
import { IS_NA_MARKET, GET_DEALER_NAME } from "../../utils/EmpUtil";

const SubNavDropdown = (props) => {
  const [open, setOpen] = useState(false);

  const dealerGroup =
    !IS_NA_MARKET(props.user.market) && props.user && props.user.dealerGroup;

  const updateDealer = (event) => {
    let dealer;
    const showAll = event.target.value === "All";

    if (event.target.value === "DISABLED") {
      setOpen(true);
    } else if (showAll) {
      dealer = props.user.dealerGroup.filter(
        (dealer) => dealer.dealerType === "HUB" || dealer.dealerType === "MDLR"
      )[0];
      props.onDealerChanged(dealer, true);
    } else {
      dealer = props.user.dealerGroupingsMap[event.target.value];
      props.onDealerChanged(dealer);
    }
  };

  return (
    <div id="dropdown" className={styles.dropdownDiv}>
      <FormControl id="menu">
        <Select
          name="dropdown"
          id="select"
          data-testid="select"
          style={subNavStyles.dropdown}
          className={styles.dropdown}
          IconComponent={() => <KeyboardArrowDown style={subNavStyles.icon} />}
          value={
            props.user.showAll
              ? "All"
              : props.user &&
                props.user.dealerGroup &&
                props.user.dealerGroup.filter(
                  (dealer) => dealer.commonId === props.user.commonId
                )[0].commonId
          }
          onChange={updateDealer}
        >
          <MenuItem style={subNavStyles.item} id="All" value="All" key="All">
            <p className={styles.dealerName}>{props.t("SubNav.all")}</p>
          </MenuItem>
          {dealerGroup &&
            dealerGroup.map((dealer) => {
              return (
                <MenuItem
                  style={subNavStyles.item}
                  id={dealer.commonId.split("|").join("")}
                  key={dealer.commonId.split("|").join("")}
                  value={dealer.commonId}
                >
                  <p
                    className={styles.unonboardedDealer}
                    id={dealer.commonId.split("|").join("")}
                  >
                    {!dealer.isOnboarded && (
                      <ReportProblemOutlinedIcon
                        className={styles.unonboardedDealerIcon}
                      />
                    )}
                    {GET_DEALER_NAME(dealer, props.user.market)} (
                    {dealer.completeDealerId})
                  </p>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <RegularDialog
        open={open}
        close={() => setOpen(false)}
        icon={true}
        title={props.t("SubNav.disabledDealerTitle")}
        body={props.t("SubNav.disabledDealerBody")}
      />
    </div>
  );
};

export default withTranslation("emp")(SubNavDropdown);
