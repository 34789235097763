import React, { Component } from "react";
import fordStyles from "./GlobalNavBar.module.scss";
import lincolnStyles from "./GlobalNavBar-Lincoln.module.scss";
import { AppBar, Grid, Select, Toolbar, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { invalidateSessionAndRedirect } from "../../common/InvalidateSession";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import HasPermissionTo from "../../common/HasPermissionTo";
import { DEALER_SETUP, RESOURCE_CENTER } from "../../common/Constants";
import LanguageUtil from "../../utils/LanguageUtil";
import { ofType, publish } from "../../common/PubSub";
import LincolnLogo from "../../common/LincolnLogo";
import {
  GET_DEALER_NAME,
  IS_CANADA_MARKET,
  IS_FORD_BRAND,
  IS_USA_MARKET,
} from "../../utils/EmpUtil";
import FordLogo from "../../common/FordLogo";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { FORD_BRAND, LINCOLN_BRAND } from "../../components/accessories/Brands";

const fullHeight = {
  minHeight: "70px",
  height: "100%",
};

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={10}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    paddingLeft: "10px",
    paddingRight: "10px",
    color: "#102b4e",
  },
})(MenuItem);

const LincolnStyledMenuItem = withStyles({
  root: {
    paddingLeft: "10px",
    paddingRight: "10px",
    color: "#324047",
  },
})(MenuItem);

const FordSelect = withStyles({
  root: {
    width: 250,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
})(Select);

const LincolnSelect = withStyles({
  root: {
    width: 250,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#324047",
  },
})(Select);

class GlobalNavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      open: false,
      isFordBrand: IS_FORD_BRAND(this.props.user.brand),
      anchorElPortal: null,
    };

    ofType("brandChanged").subscribe(() => {
      this.updateBrand();
    });
  }

  componentDidMount() {
    const headerHeight = document.getElementById("appHeader").clientHeight;
    this.props.setPadding(headerHeight);
  }

  handleLogoutClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogoutClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogoutDialogOpen = () => {
    this.setState({ open: true });
  };

  handleLogoutDialogClose = () => {
    this.setState({ open: false });
  };

  handleBrandChange = (event) => {
    this.props.changeBrand(event.target.value);
  };

  handlePortalMenuClick = (event) => {
    this.setState({
      anchorElPortal: event.currentTarget,
    });
  };

  handlePortalMenuClose = () => {
    this.setState({
      anchorElPortal: null,
    });
  };

  updateBrand() {
    this.setState({ isFordBrand: IS_FORD_BRAND(this.props.user.brand) });
  }

  isActive(currentUrl, styles) {
    if (this.props.urlPath === currentUrl) {
      return styles.active;
    } else if (
      (currentUrl === "/dealer/customer-reservations" ||
        currentUrl === "/customer-handling") &&
      (this.props.urlPath.includes("customer-reservations/order-details") ||
        this.props.urlPath.includes("customer-reservations/purchase-details"))
    ) {
      return styles.active;
    } else if (
      this.props.urlPath &&
      this.props.urlPath.startsWith("/pricing") &&
      currentUrl === "/pricing"
    ) {
      return styles.active;
    }
    return "";
  }

  updateSubNavTabs() {
    if (!this.props.isNsc) {
      const user = this.props.user;
      if (user) {
        user.updateBusinessSettingsTab && user.updateBusinessSettingsTab(0);
        user.updateDashboardTab && user.updateDashboardTab(0);
      }
    }
  }

  updateLanguage = (language) => {
    LanguageUtil.updateLanguage(this.props, language);
    publish("language", language);
  };

  render() {
    const styles = this.state.isFordBrand ? fordStyles : lincolnStyles;

    function getLink(url, displayText) {
      return (
        <Link
          className={this.isActive(url, styles)}
          onClick={() => this.updateSubNavTabs()}
          to={{
            pathname: url,
          }}
        >
          {this.props.t(displayText)}
        </Link>
      );
    }

    const { user } = this.props;
    const name = GET_DEALER_NAME(user, this.props.user.market)
      ? " (" + GET_DEALER_NAME(user, this.props.user.market) + ")"
      : "";
    return (
      <AppBar
        position="fixed"
        classes={{ root: styles.globalNavBar }}
        id="appHeader"
        data-testid="appHeader"
      >
        <Toolbar style={fullHeight} disableGutters>
          <Grid container style={fullHeight}>
            <Grid item md={12} className={styles.gridItem}>
              <div className={styles.languageContainer}>
                {this.state.isFordBrand ? (
                  <FordLogo className={styles.brandLogo} />
                ) : (
                  <LincolnLogo className={styles.brandLogo} />
                )}
              </div>
              <div className={styles.navBarWrapper}>
                <HasPermissionTo
                  perform={["dashBoardPage"]}
                  permissions={user.permissions.rolePermissions}
                  render={() => (
                    <>
                      {getLink.call(
                        this,
                        "/dashboard",
                        "GlobalNavBar.dashboard"
                      )}
                    </>
                  )}
                />
                <HasPermissionTo
                  perform={["pricingPage"]}
                  permissions={user.permissions.rolePermissions}
                  render={() => (
                    <>
                      {getLink.call(this, "/pricing", "GlobalNavBar.pricing")}
                    </>
                  )}
                />
                <HasPermissionTo
                  perform={["accessoriesPage"]}
                  condition={!IS_CANADA_MARKET(this.props.user.market)}
                  permissions={user.permissions.rolePermissions}
                  render={() => (
                    <>
                      {getLink.call(
                        this,
                        "/pricing/accessories",
                        "GlobalNavBar.accessoriesPricing"
                      )}
                    </>
                  )}
                />
                <HasPermissionTo
                  perform={["customerHandlingPage"]}
                  permissions={user.permissions.rolePermissions}
                  render={() => (
                    <>
                      {getLink.call(
                        this,
                        IS_CANADA_MARKET(this.props.user.market)
                          ? "/customer-handling/reservations_submitted"
                          : "/customer-handling/orders_requiring_attention",
                        "GlobalNavBar.customerHandling"
                      )}
                    </>
                  )}
                />
                <HasPermissionTo
                  perform={["ordersPage"]}
                  permissions={user.permissions.rolePermissions}
                  render={() => (
                    <>
                      {getLink.call(
                        this,
                        "/dealer/customer-reservations",
                        "GlobalNavBar.orders"
                      )}
                    </>
                  )}
                />
                <HasPermissionTo
                  perform={["businessSettingsPage"]}
                  permissions={user.permissions.rolePermissions}
                  render={() => (
                    <>
                      {getLink.call(
                        this,
                        "/business-settings",
                        "GlobalNavBar.settings"
                      )}
                    </>
                  )}
                />
                <HasPermissionTo
                  perform={["reportingPage"]}
                  permissions={user.permissions.rolePermissions}
                  render={() => (
                    <>
                      {getLink.call(
                        this,
                        "/reporting",
                        "GlobalNavBar.reporting"
                      )}
                    </>
                  )}
                />
                <HasPermissionTo
                  perform={["searchLandingPage"]}
                  permissions={user.permissions.rolePermissions}
                  render={() => <>{getLink.call(this, "/search", "Search")}</>}
                />
                <HasPermissionTo
                  perform={["poReportingPage"]}
                  permissions={user.permissions.rolePermissions}
                  render={() => (
                    <>{getLink.call(this, "/", "GlobalNavBar.reporting")}</>
                  )}
                />

                <HasPermissionTo
                  perform={[
                    "dealerReporting:view",
                    "dealerSetupLink",
                    "resourceCenterLink",
                  ]}
                  permissions={user.permissions.rolePermissions}
                  condition={IS_USA_MARKET(this.props.user.market)}
                  render={() => (
                    <div
                      onClick={(event) => this.handlePortalMenuClick(event)}
                      className={styles.portalMenu}
                    >
                      {this.props.t("GlobalNavBar.portalText")}
                      {!this.state.anchorElPortal ? (
                        <KeyboardArrowDown />
                      ) : (
                        <KeyboardArrowUp />
                      )}
                    </div>
                  )}
                />

                <Menu
                  className={styles.userSection}
                  id="portal-menu"
                  anchorEl={this.state.anchorElPortal}
                  keepMounted
                  open={Boolean(this.state.anchorElPortal)}
                  onClose={() => this.handlePortalMenuClose()}
                  onClick={() => this.handlePortalMenuClose()}
                  getContentAnchorEl={null}
                  disableAutoFocusItem={true}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <HasPermissionTo
                    perform={["dealerReporting:view"]}
                    permissions={user.permissions.rolePermissions}
                    condition={IS_USA_MARKET(this.props.user.market)}
                    render={() => (
                      <MenuItem
                        onClick={() =>
                          this.props.history.push("/dealer-reporting")
                        }
                        className={styles.logoutFont}
                      >
                        {this.props.t("GlobalNavBar.reporting")}
                      </MenuItem>
                    )}
                  />

                  <HasPermissionTo
                    perform={["dealerSetupLink"]}
                    permissions={user.permissions.rolePermissions}
                    render={() => (
                      <a
                        target="_blank"
                        href={DEALER_SETUP}
                        className={styles.logoutFont}
                        rel="noreferrer"
                      >
                        <MenuItem className={styles.logoutFont}>
                          {this.props.t("GlobalNavBar.dealerSetup")}
                        </MenuItem>
                      </a>
                    )}
                  />

                  <HasPermissionTo
                    perform={["resourceCenterLink"]}
                    permissions={user.permissions.rolePermissions}
                    render={() => (
                      <a
                        target="_blank"
                        href={RESOURCE_CENTER}
                        className={styles.logoutFont}
                        rel="noreferrer"
                      >
                        <MenuItem className={styles.logoutFont}>
                          {this.props.t("GlobalNavBar.resourceCenter")}
                        </MenuItem>
                      </a>
                    )}
                  />
                </Menu>
              </div>
              <div className={styles.flex} />
              <HasPermissionTo
                perform={["franchiseSelector"]}
                permissions={user.permissions.rolePermissions}
                condition={
                  IS_USA_MARKET(this.props.user.market) &&
                  this.props.user.dealerGroup &&
                  this.props.user.dealerGroup.length > 1
                }
                render={() => (
                  <ChangeBrand
                    {...this.props}
                    styles={styles}
                    brand={this.props.user.brand}
                    handleBrandChange={this.handleBrandChange}
                    isFordBrand={this.state.isFordBrand}
                  />
                )}
              />
              <Logout
                {...this.props}
                styles={styles}
                handleLogoutClick={this.handleLogoutClick}
                user={user}
                name={name}
                anchorEl={this.state.anchorEl}
                handleLogoutClose={this.handleLogoutClose}
                handleLogoutDialogOpen={this.handleLogoutDialogOpen}
                open={this.state.open}
                handleLogoutDialogClose={this.handleLogoutDialogClose}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

const Logout = (props) => {
  return (
    <div>
      <div className={props.styles.dealerContainer}>
        <div
          className={`${props.styles.dealerBox} ${props.styles.withPointer}`}
          onClick={props.handleLogoutClick}
        >
          <HasPermissionTo
            perform={["NADealerDetails:view"]}
            permissions={props.user.permissions.rolePermissions}
            condition={props.user.userType === "na_dealer"}
            render={() => {
              return (
                <NADealerDetails
                  {...props}
                  styles={props.styles}
                  dealerType={props.dealerType}
                  name={props.name}
                />
              );
            }}
            noRender={() => {
              return (
                <DealerDetails
                  user={props.user}
                  name={props.name}
                  styles={props.styles}
                />
              );
            }}
          />
          <KeyboardArrowDown id="logout-menu" className={props.styles.arrow} />
        </div>
      </div>
      <StyledMenu
        className={props.styles.userSection}
        id="customized-menu"
        disableAutoFocusItem
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.handleLogoutClose}
      >
        <StyledMenuItem
          onClick={props.handleLogoutDialogOpen}
          to={{ pathname: "/" }}
        >
          <span className={props.styles.logoutFont}>
            {props.t("Logout.logout")}
          </span>
        </StyledMenuItem>
      </StyledMenu>

      <Dialog
        open={props.open}
        onClose={props.handleLogoutDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className={props.styles.logoutConfirmationFont}>
              {props.t("Logout.confirmation")}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleLogoutDialogClose} color="primary">
            {props.t("Logout.decline")}
          </Button>
          <Button
            onClick={invalidateSessionAndRedirect}
            color="primary"
            autoFocus
          >
            {props.t("Logout.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DealerDetails = (props) => {
  return (
    <div id="name">
      {props.user.fullName}
      {props.user.userType === "dealer" && props.name}
    </div>
  );
};

const NADealerDetails = (props) => {
  const meta = props.user.commonDealerId.split("|");
  return (
    <div className={props.styles.dealerDetails} id="name">
      <span className={props.styles.dealerType}>
        {props.dealerType || props.t("GlobalNavBar.dealer")}
      </span>
      <span className={props.styles.dealerName}>{props.user.dealerName}</span>
      <span className={props.styles.dealerCommonId}>
        {meta[1].concat(" | ").concat(meta[2])}
      </span>
    </div>
  );
};

const ChangeBrand = (props) => {
  const StyledSelect = props.isFordBrand ? FordSelect : LincolnSelect;
  const StyledMenu = props.isFordBrand ? StyledMenuItem : LincolnStyledMenuItem;

  return (
    <StyledSelect
      disableUnderline
      native={props.native}
      variant="outlined"
      value={props.brand}
      onChange={props.handleBrandChange}
      data-testid="change-brand"
      inputProps={{
        "data-testid": "actual-brand",
      }}
      IconComponent={() => (
        <KeyboardArrowDown className={props.styles.brandIcon} />
      )}
    >
      <StyledMenu value={FORD_BRAND} className={props.styles.arrow}>
        &nbsp;&nbsp;{props.t("GlobalNavBar.fordBrand")}
      </StyledMenu>
      <StyledMenu value={LINCOLN_BRAND} className={props.styles.arrow}>
        &nbsp;&nbsp;{props.t("GlobalNavBar.lincolnBrand")}
      </StyledMenu>
    </StyledSelect>
  );
};

export default withTranslation("emp")(GlobalNavBar);
