import React from "react";
import { Dialog, withStyles } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import styles from "./ValuationDialog.module.scss";
import Button from "@material-ui/core/Button";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { withTranslation } from "react-i18next";
import * as DateConstants from "../../../../shared/constants/DateConstants";

const CloseButton = withStyles({
  root: {
    width: 27,
    height: 27,
    fill: "#102b4e",
    align: "right",
  },
})(Clear);

class ValuationDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expiryDate: undefined,
      valuationAmount: undefined,
    };
  }

  handleValuationAmount = (e) =>
    this.setState({ valuationAmount: e.target.value });

  setExpiryDate = (e) => this.setState({ expiryDate: new Date(e) });

  resetInput = () =>
    this.setState({ expiryDate: undefined, valuationAmount: undefined });

  closeValuationDialog = () => {
    this.resetInput();
    this.props.close();
  };

  isConfirmButtonEnabled = () =>
    !!this.state.expiryDate && !!this.state.valuationAmount;

  render() {
    const dateToday = new Date();

    const Container = ({ children }) => {
      return (
        <div className="dialogContainer">
          <CalendarContainer>
            <div className="calendarContainer">{children}</div>
          </CalendarContainer>
        </div>
      );
    };

    return (
      <Dialog
        className={styles.dialogContainer}
        open={this.props.open}
        onClose={this.closeValuationDialog}
      >
        <div className={styles.dialogWrapper}>
          <button
            className={styles.cancel}
            data-testid="closeDialog"
            onClick={this.closeValuationDialog}
          >
            <CloseButton />
          </button>

          <div className={styles.dialogTitle}>
            <div>{this.props.t("TradeIn.valuation")}</div>
          </div>

          <div className={styles.dialogDescription}>
            {this.props.t("TradeInValuationDialog.body")}
          </div>

          <div className={styles.inputLabel}>
            {this.props.t("TradeInValuationDialog.expiryDate")}
          </div>

          <div
            data-testid="calendar"
            className={`datePickerDialogStyles ${styles.calendar}`}
          >
            <DatePicker
              placeholderText={DateConstants.DD_MM_YYYY}
              name="datePicker"
              selected={this.state.expiryDate}
              withPortal
              dateFormat={DateConstants.ddMMyyyy}
              shouldCloseOnSelect={true}
              minDate={dateToday}
              onChange={this.setExpiryDate}
              calendarContainer={Container}
            />
          </div>

          <ValidatorForm
            id="valuationForm"
            onSubmit={() =>
              this.props.saveValuation(
                this.state.expiryDate,
                this.state.valuationAmount
              )
            }
          >
            <div className={styles.inputLabel}>
              {this.props.t("TradeInValuationDialog.valuationAmount")}
            </div>

            <div className={styles.textBox}>
              <TextValidator
                placeholder="Eg. 10000"
                name="valuationAmount"
                autoComplete="off"
                value={this.state.valuationAmount}
                disabled={false}
                className={styles.valuationAmountInput}
                variant="outlined"
                onChange={this.handleValuationAmount}
                validators={["required", "matchRegexp:^[0-9]*$"]}
                errorMessages={[
                  this.props.t("TradeIn.errorReq"),
                  this.props.t("TradeIn.errorNum"),
                ]}
              />
            </div>

            <div className={styles.dialogActions}>
              <Button
                className={styles.backBtn}
                name="back"
                data-testid="back"
                onClick={this.closeValuationDialog}
              >
                {this.props.t("TradeInValuationDialog.goBack")}
              </Button>
              <Button
                className={styles.confirmBtn}
                type="submit"
                name="confirm-btn"
                data-testid="confirm-btn"
                disabled={!this.isConfirmButtonEnabled()}
              >
                {this.props.t("TradeInValuationDialog.confirm")}
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation("emp")(ValuationDialog);
