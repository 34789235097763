import React, { Component } from "react";
import styles from "./OnboardingWelcome.module.scss";
import "../../../styles/sharedStyles.scss";
import SubDealerList from "./subDealerList/SubDealerList";
import { Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import FordLogo from "../../../common/FordLogo";
import HasPermissionTo from "../../../common/HasPermissionTo";
import { NULL_OR_UNDEFINED } from "../../../utils/EmpUtil";

class OnboardingWelcome extends Component {
  componentDidMount() {
    this.props.showActionFooter(true);
  }

  mainDealer() {
    let dealerName = this.props.user.name;
    if (NULL_OR_UNDEFINED(dealerName)) {
      if (this.props.dealerGroup.length === 1) {
        dealerName = this.props.dealerGroup[0].name;
      } else if (this.props.dealerGroup.length > 1) {
        this.props.dealerGroup.forEach(function (dealer) {
          if (dealer.dealerType === "MDLR" || dealer.dealerType === "HUB") {
            dealerName = dealer.name;
          }
        });
      }
    }

    return dealerName;
  }

  render() {
    if (this.props.dealerGroup.length < 1) {
      return (
        <div data-testid="onboard-welcome">
          <h1 className={styles.pageTitle1}>
            {this.props.t("OnboardingWelcome.dealerError")}
          </h1>
        </div>
      );
    } else {
      return (
        <div className="pageWrapper">
          <div className="pagePadding">
            <Grid container spacing={24}>
              <Grid item md={12} className={styles.center}>
                <FordLogo className={styles.brandLogo} />
              </Grid>
              <Grid item md={12} className={styles.center}>
                <div className={styles.welcomeTitle}>
                  {this.props.t("GeneralEMP.onboarding")}
                </div>
              </Grid>
              <Grid item md={12}>
                <div className={styles.welcome}>
                  {this.props.t("OnboardingWelcome.welcome")}
                  {this.mainDealer()}
                </div>
              </Grid>
            </Grid>
            <HasPermissionTo
              perform={["onboardingSteps:view"]}
              permissions={this.props.userDetails.permissions.rolePermissions}
              render={() => {
                return (
                  <Grid container spacing={24}>
                    <Grid item md={12}>
                      <div className={styles.toCompleteTheOnboarding}>
                        {this.props.t("OnboardingWelcome.sublineTwo")}
                      </div>
                    </Grid>

                    <Grid item md={12}>
                      <div>
                        <span className="lineNumber">1 </span>
                        <span className="lineText">
                          {" "}
                          {this.props.t("OnboardingWelcome.stepOne")}
                        </span>
                      </div>
                    </Grid>
                    <Grid item md={12}>
                      <div>
                        <span className="lineNumber">2 </span>
                        <span className="lineText">
                          {" "}
                          {this.props.t("OnboardingWelcome.stepTwo")}
                          <span className={styles.normalFontWeight}>
                            {" "}
                            {this.props.t("OnboardingWelcome.stepTwoSub")}
                          </span>
                        </span>
                      </div>
                    </Grid>
                    <Grid item md={12}>
                      <div>
                        <span className="lineNumber">3 </span>
                        <span className="lineText">
                          {" "}
                          {this.props.t("OnboardingWelcome.stepThree")}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                );
              }}
              noRender={() => {
                return (
                  <Grid container spacing={24}>
                    <Grid item md={12}>
                      <div className={styles.toCompleteTheOnboarding}>
                        {this.props.t("OnboardingWelcome.acceptTerms")}
                      </div>
                    </Grid>
                  </Grid>
                );
              }}
            />

            <Grid container spacing={24}>
              <Grid item md={12}>
                <div>
                  <span className={styles.toCompleteTheOnboarding}>
                    {" "}
                    {this.props.t("OnboardingWelcome.setupRequired")}
                  </span>
                </div>
                <div>
                  <div className={styles.subDealer}>
                    {this.props.dealerGroup.map((dealer) => (
                      <SubDealerList
                        {...this.props}
                        dealer={dealer}
                        key={dealer.completeDealerId}
                      />
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation("emp")(OnboardingWelcome);
