import React, { Component } from "react";
import { InfoIconWithMessage } from "../../../../../shared/infoIcon/InfoIcon";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidationRules from "../../../../../common/ValidationRules";
import ReservationList from "./ReservationList";
import { withTranslation } from "react-i18next";
import styles from "../../../../businessSetting/merchantId/MerchantId.module.scss";
import SubNav from "../../../../../shared/subnav/SubNav";
import HasPermissionTo from "../../../../../common/HasPermissionTo";
import BackToButton from "../../../../../shared/backToButton/BackToButton";
import ReservationPriceClient from "../../../../../shared/clients/ReservationPriceClient";
import { DEALER, MACHE, REGEX_LIST } from "../../../../../common/Constants";
import commonStyles from "../../../../../styles/common/CommonStyles.module.scss";
import { PricesApplied } from "../../../../../shared/feedback/PricesApplied";
import UpperCaseText from "../../../../../utils/UpperCaseText";
import { IS_AUS_MARKET } from "../../../../../utils/EmpUtil";

class ModifyReservationPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservations: [],
      isEditable: this.props.fromQuickstart ? this.props.isNsc : false,
      isDataLoaded: false,
      buttonEnabled: true,
      editButtonDisable: {},
      allChecked: false,
      finalReservations: [],
      vehicleLineMap: {},
    };
    this.dealerCanEditReservationPrice =
      this.dealerCanEditReservationPrice.bind(this);
  }

  componentDidMount() {
    if (this.props.fromQuickstart) {
      this.props.showActionFooter(true);
    }
    ValidationRules();
    this.props.hideOrShow(true);
    ReservationPriceClient.retrieveReservationPrices(this.props.user)
      .then((result) => {
        this.getVehicleLineMap(result);
        if (
          IS_AUS_MARKET(this.props.user.market) &&
          UpperCaseText(this.props.user.userType) === DEALER
        ) {
          result = this.handleBevVehicles(result);
        }
        const listOfVehicles = [];
        for (let idx = 0; idx < result.length; ++idx) {
          if (
            !isNaN(result[idx].amount) &&
            result[idx].amount !== null &&
            result[idx].amount !== ""
          ) {
            result[idx].amount = parseFloat(result[idx].amount).toFixed(2);
            result[idx].checked = false;
          }

          listOfVehicles.push(result[idx]);
        }
        this.setState({
          reservations: this.removeDuplicates(listOfVehicles),
          originalDeposit: result,
        });
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({ isDataLoaded: true });
        if (this.props.fromQuickstart) {
          this.fillAllCheckBox();
        }
      });
  }
  handleBevVehicles(result) {
    const macheIndex = result
      .map((vehicle) => UpperCaseText(vehicle.model))
      .indexOf(MACHE);
    if (macheIndex !== -1) {
      if (this.props.fromQuickstart) {
        // If no bev dealers in group, don't show MACHE
        const bevEnabledIndex = this.props.dealerGroup
          .map((dealer) => dealer.isBevDealer)
          .indexOf(true);
        if (bevEnabledIndex === -1) {
          result.splice(macheIndex, 1);
        }
      } else {
        const currentDealer = this.props.user.getCurrentDealer();
        if (!currentDealer.isBevDealer) {
          result.splice(macheIndex, 1);
        }
      }
    }
    return result;
  }

  getVehicleLineMap = (reservations) => {
    const vehicleLineMap = {};
    reservations.forEach((reservation) => {
      vehicleLineMap[reservation.vehicleLine] = reservation.catalogId;
    });
    this.setState({ vehicleLineMap });
  };

  clickCheckBox = (event) => {
    const index = event.target.value;
    const originalState = this.state.reservations;
    originalState[index].checked = !originalState[index].checked;
    const filteredReservations = originalState.filter(
      (reservation) => reservation.checked === true
    );
    this.setState({
      buttonEnabled: true,
      reservations: originalState,
      allChecked: this.checkIfAllChecked(filteredReservations),
    });
  };

  checkIfAllChecked = (filteredReservations) => {
    return filteredReservations.length === this.state.reservations.length;
  };

  EditReservationsHandler() {
    this.setState({
      isEditable: !this.state.isEditable,
    });
  }

  setEditModeToFalse = () => {
    this.setState({ isEditable: false });
  };

  uploadPrice = () => {
    const filteredReservations = this.state.reservations.filter(
      (reservation) => reservation.checked === true
    );
    const tempRes = JSON.parse(JSON.stringify(filteredReservations));
    this.setState({ finalReservations: tempRes });

    for (let idx = 0; idx < tempRes.length; ++idx) {
      if (
        !isNaN(tempRes[idx].amount) &&
        tempRes[idx].amount !== null &&
        tempRes[idx].amount !== ""
      ) {
        tempRes[idx].amount = parseFloat(tempRes[idx].amount).toFixed(2);
      }
    }

    this.setState(
      {
        originalDeposit: this.state.reservations,
        isEditable: false,
        buttonEnabled: true,
        editButtonDisable: {},
        allChecked: false,
      },
      () => {
        this.props.onSubmit({
          reservations: this.convertReservations(this.state.reservations),
        });
      }
    );
  };

  convertReservations = (reservations) => {
    const newReservations = [];
    for (const i in reservations) {
      const reservation = reservations[i];
      if (reservation.checked) {
        if (Array.isArray(reservation.vehicleLine)) {
          reservation.vehicleLine.forEach((vehicleLine) => {
            const newReservation = { ...reservation };
            newReservation.vehicleLine = vehicleLine;
            newReservation.catalogId = this.state.vehicleLineMap[vehicleLine];
            newReservations.push(newReservation);
          });
        } else {
          newReservations.push(reservation);
        }
      }
    }
    this.uncheckEverything();
    return newReservations;
  };

  uncheckEverything = () => {
    if (!this.props.fromQuickstart) {
      const reservations = this.state.reservations;
      reservations.forEach((reservation) => {
        reservation.checked = false;
      });
      this.setState(reservations);
    }
  };

  updateReservation = (event) => {
    if (!event.target.value.match(REGEX_LIST.number) || !event.target.value) {
      const originalDisables = this.state.editButtonDisable;
      originalDisables[event.target.name] = true;
      this.setState({ editButtonDisable: originalDisables });
    } else {
      const originalDisables = this.state.editButtonDisable;
      originalDisables[event.target.name] = false;
      this.setState({ editButtonDisable: originalDisables });
    }
    const newReservations = this.state.reservations.map((reservation) => {
      const deposit = JSON.parse(JSON.stringify(reservation));
      if (event.target.name === deposit.model) {
        deposit.amount = event.target.value;
      }
      return deposit;
    });
    this.setState({ buttonEnabled: true, reservations: newReservations });
  };

  containsDisabled = () => {
    return Object.values(this.state.editButtonDisable).includes(true);
  };

  containsBlank = () => {
    let condition = false;
    this.state.reservations.forEach((reservation) => {
      if (reservation.checked === true && reservation.amount === null) {
        condition = true;
      }
    });
    return condition;
  };

  dealerCanEditReservationPrice() {
    if (!this.props.isNsc) {
      const currentDealer = this.props.user.dealerGroup.filter(
        (dealer) => dealer.commonId === this.props.user.commonId
      )[0];
      const isMainViewingSDLR = this.props.isMainViewingSDLR(currentDealer);
      return !isMainViewingSDLR;
    }
    return true;
  }

  getCount = (reservations) => {
    let count = 0;
    reservations.forEach((reservation) => {
      if (reservation.checked) {
        count += 1;
      }
    });

    return count > 0 ? count : "";
  };

  getWording = (reservations) => {
    let count = 0;
    reservations.forEach((reservation) => {
      if (reservation.checked) {
        count += 1;
      }
    });

    return count === 1
      ? this.props.t("ActionFooter.orderDeposit")
      : this.props.t("ActionFooter.orderDeposits");
  };

  getSubNav = () => {
    return (
      <>
        <BackToButton
          backToUrl="/pricing"
          display={this.props.t("PricingWrapper.backToPricing")}
        />
        <SubNav
          {...this.props}
          title={this.props.t("ModifyReservationPrice.header")}
          renderSubHeader={false}
          subHeader={null}
        />
      </>
    );
  };

  minNumber = () => {
    const localMarket = this.props.user.market;
    if (
      UpperCaseText(localMarket) === "ZAF" ||
      UpperCaseText(localMarket) === "THA"
    ) {
      return 0;
    }
    return 1;
  };

  applyBtnHandler = (enable) => this.setState({ buttonEnabled: enable });

  fillAllCheckBox = () => {
    const checkedOrNot = !this.state.allChecked;
    const allReservations = this.state.reservations;
    allReservations.forEach((reservation) => {
      if (
        reservation.amount &&
        parseFloat(reservation.amount) >= this.minNumber()
      ) {
        reservation.checked = checkedOrNot;
      }
      if (checkedOrNot === false) {
        reservation.checked = false;
      }
    });
    this.setState({
      reservations: allReservations,
      allChecked: checkedOrNot,
      buttonEnabled: checkedOrNot,
      isEditable: false,
    });
  };

  removeDuplicates = (reservations) => {
    const seenModels = new Map();
    const filteredReservations = [];
    reservations.forEach((reservation) => {
      if (seenModels.has(reservation.model)) {
        const functionToFindModel = (element) =>
          element.model === reservation.model;
        const indexOfReservation =
          filteredReservations.findIndex(functionToFindModel);

        filteredReservations[indexOfReservation].newVehicleLine =
          reservation.newVehicleLine && reservation.newVehicleLine;

        if (!this.props.isNsc) {
          filteredReservations[indexOfReservation].amount = reservation.amount;
        } else {
          filteredReservations[indexOfReservation].amount =
            !reservation.newVehicleLine ? reservation.amount : null;
        }
        if (
          Array.isArray(filteredReservations[indexOfReservation].vehicleLine)
        ) {
          filteredReservations[indexOfReservation].vehicleLine.push(
            reservation.vehicleLine
          );
        } else {
          filteredReservations[indexOfReservation].vehicleLine = [
            filteredReservations[indexOfReservation].vehicleLine,
            reservation.vehicleLine,
          ];
        }
      } else {
        seenModels.set(reservation.model, 0);
        filteredReservations.push(reservation);
      }
    });

    return filteredReservations;
  };

  disableSaveButton = () => {
    return (
      !this.state.buttonEnabled ||
      this.containsDisabled(this.state.editButtonDisable) ||
      this.containsBlank(this.state.editButtonDisable) ||
      !this.getCount(this.state.reservations) > 0
    );
  };

  render() {
    const subheader = this.props.isNsc
      ? this.props.t("ModifyReservationPrice.subheaderNsc")
      : !this.props.fromQuickstart
      ? this.props.t("ModifyReservationPrice.subheaderPricing")
      : this.props.t("ModifyReservationPrice.subheader");
    return this.state.isDataLoaded ? (
      <div className="pageWrapper">
        {!this.props.fromQuickstart && this.getSubNav()}
        <div className="pagePaddingLeft pagePaddingTop">
          {this.props.fromQuickstart && (
            <h1 id="depositPrice" className="pageTitle">
              {this.props.t("ModifyReservationPrice.header")}
            </h1>
          )}
          <InfoIconWithMessage message={subheader} />
        </div>
        <ValidatorForm
          id="modifyReservationForm"
          ref={this.props.formRef}
          onSubmit={this.uploadPrice}
        >
          <ReservationList
            {...this.props}
            originalDeposit={this.state.originalDeposit}
            reservations={this.state.reservations}
            updateReservation={this.updateReservation}
            editMode={this.state.isEditable}
            editReservations={(e) => this.EditReservationsHandler(e)}
            applyBtn={this.applyBtnHandler}
            removeEditMode={this.setEditModeToFalse}
            dealerCanEditReservationPrice={this.dealerCanEditReservationPrice}
            clickCheckBox={(e) => this.clickCheckBox(e)}
            editButtonDisable={this.containsDisabled(
              this.state.editButtonDisable
            )}
            checkAllCheckBox={this.fillAllCheckBox}
            allChecked={this.state.allChecked}
          />
          <HasPermissionTo
            perform={["depositPrice:write"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={this.dealerCanEditReservationPrice()}
            render={() =>
              !this.props.fromQuickstart &&
              this.getCount(this.state.reservations) > 0 ? (
                <div
                  className={
                    !this.state.buttonEnabled ||
                    this.containsDisabled(this.state.editButtonDisable) ||
                    this.containsBlank(this.state.editButtonDisable)
                      ? styles.disabledButtonDiv
                      : styles.buttonDiv
                  }
                >
                  <button
                    disabled={this.disableSaveButton()}
                    id="saveBtn"
                    className={commonStyles.greenBtn}
                  >
                    {`${this.props.t("ActionFooter.apply")} ${this.getCount(
                      this.state.reservations
                    )} ${this.getWording(this.state.reservations)}`}
                  </button>
                </div>
              ) : (
                <PricesApplied
                  success={this.props.pricesApplied}
                  text={`${this.getCount(
                    this.state.finalReservations
                  )} ${this.getWording(
                    this.state.finalReservations
                  )} ${this.props.t("ModifyReservationPrice.published")}`}
                />
              )
            }
          />
          <div className={styles.errorMsg}>{this.props.errorMessage}</div>
        </ValidatorForm>
      </div>
    ) : null;
  }
}

export default withTranslation("emp")(ModifyReservationPrice);
