import React, { Component } from "react";
import styles from "./accessDenied.module.scss";
import FordLogo from "../../common/FordLogo";
import { withTranslation } from "react-i18next";
import { invalidateSession } from "../../common/InvalidateSession";

class AccessDeniedNotOnboardedNsc extends Component {
  componentDidMount() {
    this.props.history.push("/denied-nsc");
    invalidateSession();
  }

  render() {
    return (
      <div className="pageWrapper">
        <div className="pagePadding">
          <div className={styles.center}>
            <FordLogo className={styles.brandLogo} />
          </div>
          <div className={styles.deniedTitle}>
            {this.props.t("AccessDeniedNotOnboardedNsc.header")}
          </div>
          <div className={styles.subtext}>
            <div className={styles.awaitingManagement}>
              {this.props.t("AccessDeniedNotOnboardedNsc.subheader")}
            </div>
            <div className={styles.inOrderToAccess}>
              {this.props.t("AccessDeniedNotOnboardedNsc.sublineOne")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(AccessDeniedNotOnboardedNsc);
