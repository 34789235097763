import React, { Component } from "react";
import FileBrowser from "../../shared/fileBrowser/FileBrowser";
import PreviewTermsConditions from "../../shared/previewTermsConditions/PreviewTermsConditions";
import Button from "@material-ui/core/Button";
import styles from "./UploadTermsConditions.module.scss";
import Grid from "@material-ui/core/Grid";
import { InfoIconWithMessage } from "../../shared/infoIcon/InfoIcon";
import { withTranslation } from "react-i18next";

class UploadTermsConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      numPages: 0,
      displayPreview: false,
      displayError: false,
    };
    this.result = "";
  }

  onFileSelected = (file) => {
    if (file.size > 1000000 || file.type !== "application/pdf") {
      //Need to display an error message like file exceeds 70kb
      this.setState(
        { displayError: true, file: null, displayPreview: false },
        () => {
          this.props.showActionFooter(false);
          this.props.updateParentState(this.state);
        }
      );
    } else {
      this.setState(
        { file: file, displayPreview: true, displayError: false },
        () => {
          this.props.showActionFooter(true);
          this.props.updateParentState(this.state);
        }
      );
    }
  };

  render() {
    return (
      <section className="pageWrapper" id="uploadTermsAndConditions">
        <div className="pagePadding">
          <h1 className="pageTitle">
            {this.props.t("UploadTermsConditions.header")}
          </h1>
          <div className="pageInfoWrapper">
            <InfoIconWithMessage
              message={this.props.t("UploadTermsConditions.bodyCopy")}
            />
          </div>

          {this.state.displayPreview ? (
            <div>
              <p>{this.props.t("UploadTermsConditions.uploadedFile")}</p>

              <Grid container spacing={24}>
                <Grid item xs={12} md={12}>
                  <PreviewTermsConditions
                    file={this.state.file}
                    className={styles.previewPDF}
                  />
                </Grid>
              </Grid>
              <div className={styles.changeFile}>
                <span className={styles.tick}>&#10003;</span>
                <span className={styles.uploadEMPTerms}>
                  {this.props.t("UploadTermsConditions.uploadButton")}
                </span>
                <FileBrowser
                  fileType=".pdf"
                  onFileSelected={this.onFileSelected}
                  id="pdf-file"
                >
                  <Button className={`${styles.changeBtn}`} component="span">
                    {this.props.t("UploadTermsConditions.change")}
                  </Button>
                </FileBrowser>
              </div>
              <p className={styles.fileTypetext}>
                {this.props.t("UploadTermsConditions.tipText")}
              </p>
            </div>
          ) : (
            <div>
              <FileBrowser
                fileType=".pdf"
                onFileSelected={this.onFileSelected}
                id="pdf-file"
              >
                <Button
                  className={styles.uploadBtn}
                  component="span"
                  id="upload-file-btn"
                >
                  {this.props.t("UploadTermsConditions.uploadButton")}
                </Button>
              </FileBrowser>
              <p className={styles.fileTypetext}>
                {this.props.t("UploadTermsConditions.tipText")}
              </p>
              {this.state.displayError && (
                <div className={styles.warningDiv}>
                  <div className={styles.warning}></div>
                  <div className={styles.errorText}>
                    {this.props.t("Error.fileError")}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default withTranslation("emp")(UploadTermsConditions);
