import React from "react";
import Grid from "@material-ui/core/Grid";
import styles from "./LeadTimes.module.scss";
import CheckBox from "../../../shared/checkBox/CheckBox";
import { TextValidator } from "react-material-ui-form-validator";
import { withTranslation } from "react-i18next";
import { Tooltip, withStyles } from "@material-ui/core";
import InfoTooltip from "./InfoTooltip";

const StyledTooltip = withStyles({
  popper: {
    opacity: 1,
    pointerEvents: "auto",
  },
  tooltip: {
    background: "white",
    boxShadow: "1px 1px 9px 0 rgba(0, 0, 0, 0.27)",
  },
})(Tooltip);
const CellValue = ({ value }) => {
  return value ? (value.trim().length === 0 ? "-" : value) : "-";
};
const getInputValue = (inputValue) => {
  return inputValue ? (inputValue.trim().length === 0 ? "" : inputValue) : "";
};
const LeadTimesRow = (props) => {
  const { deliveryStatus } = props.data;

  function getTextField(key) {
    return (
      <StyledTooltip
        title={<InfoTooltip {...props} />}
        placement="top"
        open={
          props.index === 0 &&
          props.infoTooltipOpen &&
          props.infoTooltipColumn === key
        }
      >
        <TextValidator
          className={styles.leadTimesInput}
          variant="outlined"
          name={key + props.index}
          data-testid={key + props.index}
          value={getInputValue(deliveryStatus[key])}
          placeholder="XX-XX"
          validators={[
            "required",
            "matchRegexp:^[0-9][0-9]-[0-9][0-9]$",
            "LeadTimesFirstLessThanSecond",
          ]}
          errorMessages={[
            props.t("Error.required"),
            props.t("Error.invalid"),
            props.t("Error.invalid"),
          ]}
          onKeyDown={(e) => props.handleKeyDown(e)}
          onChange={(e) => props.handleChange(e, props.index, key)}
        />
      </StyledTooltip>
    );
  }

  let modelName = `${props.data.factoryNumber}/${props.data.model}`;
  modelName = props.data.series
    ? `${modelName}/${props.data.series}`
    : modelName;

  return (
    <Grid
      container
      spacing={0}
      className={props.firstLoad ? styles.redRow : styles.row}
    >
      <Grid item xs={1} className={styles.checkDiv}>
        {props.firstLoad && (
          <div data-testid="warning" className={styles.warning}></div>
        )}
        {props.data.saved && (
          <div data-testid="saved" className={styles.saved}></div>
        )}
        <div className={styles.checkbox}>
          <CheckBox
            id={props.index}
            faded={true}
            checked={props.data.checked}
            isDisabled={true}
          />
        </div>
      </Grid>
      <Grid item xs={5} className={styles.descriptionRow}>
        {modelName}
      </Grid>
      <Grid item xs={1} className={styles.editRow}>
        {props.editModeAtYard ? (
          getTextField("AtYard")
        ) : (
          <CellValue value={deliveryStatus.AtYard} />
        )}
      </Grid>

      <Grid item xs={1} className={styles.editRow}>
        {props.editModeProducedInTransit ? (
          getTextField("ProducedInTransit")
        ) : (
          <CellValue value={deliveryStatus.ProducedInTransit} />
        )}
      </Grid>

      <Grid item xs={1} className={styles.editRow}>
        {props.editModeOrderScheduling ? (
          getTextField("OrderScheduling")
        ) : (
          <CellValue value={deliveryStatus.OrderScheduling} />
        )}
      </Grid>
    </Grid>
  );
};
export default withTranslation("emp")(LeadTimesRow);
