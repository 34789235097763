import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_REPORTING_URL, RESERVATION_BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class DealerReportingClient {
  static baseUrl = RESERVATION_BASE_URL;
  static reportingUrl = BASE_REPORTING_URL;

  static downloadDealerReservationReport(user, lang) {
    const commonIds = user.getCommonIdsWithSDLR
      ? user.getCommonIdsWithSDLR().split("%7C").join("|")
      : user.commonId;
    const requestBody = {
      marketId: user.market,
      commonIds: commonIds,
      langCode: lang,
    };

    const url = `${this.reportingUrl}/reporting/dealer/img-reservations-report`;
    return axios
      .post(url, requestBody, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }
}
