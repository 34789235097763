import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubNav from "../../../shared/subnav/SubNav";
import PendingActions from "../pendingActions/PendingActions";
import HasPermissionTo from "../../../common/HasPermissionTo";
import ReservationRelease from "./reservationRelease/ReservationRelease";

class NscDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  handleTabChange = (event, selectedTab) => {
    this.setState({ selectedTab });
  };

  getTabs() {
    return [
      {
        menuName: this.props.t("ProgressBar.notifications"),
        enabled: true,
        id: "notifications",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "pendingActionQueue:view"
        ),
      },
      {
        menuName: this.props.t("ProgressBar.release"),
        enabled: true,
        id: "release",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "reservationReleaseTab"
        ),
      },
    ];
  }

  render() {
    return (
      <section className="pageWrapper">
        <SubNav
          dashboard={true}
          selectedTab={this.state.selectedTab}
          title={this.props.t("GlobalNavBar.dashboard")}
          action={this.handleTabChange}
          tabs={this.getTabs()}
          {...this.props}
        />

        {this.state.selectedTab === 0 ? (
          <HasPermissionTo
            perform={["pendingActionQueue:view"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => <PendingActions {...this.props} />}
          />
        ) : (
          this.state.selectedTab === 1 && (
            <HasPermissionTo
              perform={["reservationReleaseTab"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => <ReservationRelease {...this.props} />}
            />
          )
        )}
      </section>
    );
  }
}

export default withTranslation("emp")(NscDashboard);
