const termsHistoryStyles = {
  tableRow: {
    height: 40,
  },

  documentHeader: {
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: "6%",
    paddingRight: 15,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "left",
    letterSpacing: "1.88px",
  },

  effectiveDateHeader: {
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: "15",
    paddingRight: 15,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "center",
    letterSpacing: "1.88px",
  },
  downloadHeader: {
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    paddingLeft: 15,
    paddingRight: "6%",
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "center",
    letterSpacing: "1.88px",
  },
  document: {
    paddingLeft: "6%",
    paddingRight: 15,
    textAlign: "left",
  },
  rightCell: {
    width: "20%",
    paddingLeft: 15,
    paddingRight: "6%",
  },
  effectiveDate: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
  },
  download: {
    paddingLeft: 15,
    paddingRight: "6%",
    textAlign: "right",
    fontFamily: "FordAntennaCond, sans-serif",
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.17,
    color: "#102b4e",
  },
  downloadIcon: {
    height: "auto",
    margin: 2,
    width: 18,
    padding: 0,
  },
  button: {
    minWidth: 0,
  },
};

export default termsHistoryStyles;
