import React, { Component } from "react";
import ModifyReservationPrice from "./modifyReservationPrice/ModifyReservationPrice";
import { withTranslation } from "react-i18next";
import ReservationPriceClient from "../../../../shared/clients/ReservationPriceClient";
import PendingActionClient from "../../../../shared/clients/PendingActionClient";
import { RESERVATION_DEPOSIT } from "../../../../common/Constants";
import {
  displayError,
  ERROR_PROCESSING,
  isProcessingError,
} from "../../../../errors/ErrorConstants";

class DepositPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: [],
      pricesApplied: false,
    };
    this.pricingRef = React.createRef();
  }

  handleSubmit = () => {
    this.pricingRef.current.submit();
  };

  getDealerList() {
    const dealers = [];
    let dealerList = this.props.user.getCommonIdsWithoutSDLR();
    if (dealerList) {
      dealerList = dealerList.split(",");
      dealerList.forEach((dealer) => {
        const formattedDealer = this.props.user.reformatCommonId(dealer);
        dealers.push(
          this.props.user.dealerGroupingsMap[formattedDealer].completeDealerId
        );
      });
    }
    return dealers;
  }

  updatePendingActions(nsc = false) {
    if (nsc) {
      PendingActionClient.removeNscPendingAction(
        this.props.user,
        RESERVATION_DEPOSIT,
        5
      )
        .then(() => this.props.getPendingActions())
        .catch((error) => {
          if (isProcessingError(error)) {
            this.setState({ hasError: [ERROR_PROCESSING] });
          }
        });
    } else {
      PendingActionClient.removeDealerPendingAction(
        this.props.user,
        this.getDealerList(),
        RESERVATION_DEPOSIT,
        5
      )
        .then(() => this.props.getPendingActions())
        .catch((error) => {
          if (isProcessingError(error)) {
            this.setState({ hasError: [ERROR_PROCESSING] });
          }
        });
    }
  }

  updateReservationPrice = (reservationsObject) => {
    const reservations = reservationsObject.reservations;
    this.props.hideOrShow(true);
    this.props.isNsc
      ? ReservationPriceClient.updateNscReservationPrice(
          this.props.user,
          reservations
        )
          .then(() => {
            this.updatePendingActions(true);
          })
          .catch((error) => {
            if (isProcessingError(error)) {
              this.setState({ hasError: [ERROR_PROCESSING] });
            }
          })
          .finally(() => {
            this.props.hideOrShow(false);
            this.setState({ pricesApplied: true });
          })
      : ReservationPriceClient.updateDealerReservationPrice(
          this.props.user,
          reservations,
          this.getDealerList()
        )
          .then(() => {
            this.updatePendingActions();
          })
          .catch((error) => {
            if (isProcessingError(error)) {
              this.setState({ hasError: [ERROR_PROCESSING] });
            }
          })
          .finally(() => {
            this.props.hideOrShow(false);
            this.setState({ pricesApplied: true });
          });
  };

  render() {
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    return (
      <div>
        <ModifyReservationPrice
          {...this.props}
          formRef={this.pricingRef}
          fromQuickstart={false}
          onSubmit={this.updateReservationPrice}
          pricesApplied={this.state.pricesApplied}
        />
      </div>
    );
  }
}

export default withTranslation("emp")(DepositPricing);
