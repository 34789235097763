import React, { Component } from "react";
import styles from "./accessDenied.module.scss";
import { withTranslation } from "react-i18next";
import FordLogo from "../../common/FordLogo";
import { invalidateSession } from "../../common/InvalidateSession";

class AccessDeniedAsNscNotOnboarded extends Component {
  componentDidMount() {
    this.props.history.push("/nsc-not-onboarded");
    invalidateSession();
  }

  render() {
    return (
      <div className="pageWrapper">
        <div className={styles.brandDiv}>
          <FordLogo className={styles.brandLogo} />
        </div>
        <div className={styles.deniedTitle}>
          {this.props.t("AccessDeniedAsNscNotOnboarded.header")}
        </div>
        <div className={styles.deniedInfo}>
          {this.props.t("AccessDeniedAsNscNotOnboarded.subheader")}
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(AccessDeniedAsNscNotOnboarded);
