import React, { Component } from "react";
import styles from "./CustomerInfo.module.scss";
import sharedStyles from "../OrderDetailsNewVersion.module.scss";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import crStyles from "../../../customerReservation/crStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  StyledBasicExpansionPanel,
  StyledBasicExpansionPanelSummary,
} from "../../../../shared/ExpansionPanels/ExpansionPanel";

class CustomerInfoV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandCustomerInfo: true,
    };
  }

  expandCustomerInfo() {
    this.setState({ expandCustomerInfo: !this.state.expandCustomerInfo });
  }

  render() {
    const data = this.props.customerInfoData;
    const isValidConsumerType = data && data.consumerType;

    function printPhoneNumber(market, displayText) {
      return (
        displayText && (
          <Grid container>
            <div className={styles.infoDetails}>{"0" + displayText}</div>
          </Grid>
        )
      );
    }

    function getInfoDetails(displayText, displayComma) {
      return (
        displayText && (
          <Grid container>
            <div className={styles.infoDetails}>
              {displayText + (displayComma ? ", " : "")}
            </div>
          </Grid>
        )
      );
    }

    return (
      <StyledBasicExpansionPanel
        defaultExpanded={true}
        onChange={() => this.expandCustomerInfo()}
      >
        <StyledBasicExpansionPanelSummary
          style={crStyles.headerBarNeutral}
          id="exp"
        >
          <div className={sharedStyles.headerBar}>
            {isValidConsumerType &&
            data.consumerType.toUpperCase() === "BUSINESS" ? (
              <div id="ci" className={sharedStyles.boldHeader}>
                {this.props.t(
                  "CustomerOrderDetails.customerInformationBusiness"
                )}
              </div>
            ) : isValidConsumerType &&
              data.consumerType.toUpperCase() === "PERSONAL" ? (
              <div id="ci" className={sharedStyles.boldHeader}>
                {this.props.t("CustomerOrderDetails.customerInformationRetail")}
              </div>
            ) : (
              <div id="ci" className={sharedStyles.boldHeader}>
                {this.props.t("CustomerOrderDetails.customerInformationRetail")}
              </div>
            )}
            {this.state.expandCustomerInfo ? (
              <ExpandLessIcon id="less" />
            ) : (
              <ExpandMoreIcon id="more" />
            )}
          </div>
          <br />
        </StyledBasicExpansionPanelSummary>
        <div className={styles.customerInfoGrid}>
          <div className={styles.bottomBorder}></div>

          {data ? (
            <Grid container className={styles.customerContainer}>
              {data.consumerType &&
                data.consumerType.toUpperCase() === "BUSINESS" && (
                  <Grid item xs={3}>
                    <Grid container>
                      <Grid item xs={12}>
                        <div className={styles.infoHeader}>
                          {this.props.t("CustomerOrderDetails.companyDetails")}
                        </div>
                        {getInfoDetails(data.companyName)}
                        {getInfoDetails(data.fin)}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={styles.infoHeader}>
                      {data.consumerType &&
                      data.consumerType.toUpperCase() === "BUSINESS"
                        ? this.props.t("CustomerOrderDetails.companyAddress")
                        : this.props.t("CustomerOrderDetails.contactAddress")}
                    </div>
                    {getInfoDetails(data.address1, true)}
                    {getInfoDetails(data.address2, true)}
                    {getInfoDetails(data.address3, true)}
                    {getInfoDetails(data.address4, true)}
                    {(data.city || data.state) && (
                      <div className={styles.infoDetails}>
                        {data.city && data.city}
                        {data.state && ", " + data.state}
                      </div>
                    )}
                    {(data.country || data.zip) && (
                      <div className={styles.infoDetails}>
                        {data.country && data.country}
                        {data.zip && ", " + data.zip}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={styles.infoHeader}>
                      {this.props.t("CustomerOrderDetails.contactDetails")}
                    </div>
                    {(data.firstName || data.lastName) && (
                      <div className={styles.infoDetails}>
                        {data.firstName && data.firstName}{" "}
                        {data.lastName && data.lastName}
                      </div>
                    )}
                    <div className={styles.infoEmail}>
                      {data.email && data.email}
                    </div>
                    {printPhoneNumber(this.props.market, data.phoneNumber)}
                    {printPhoneNumber(
                      this.props.market,
                      data.alternatePhoneNumber
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <span className={styles.labelError}>
              {" "}
              {this.props.t("CustomerOrderDetails.errorLoadingCustomerInfo")}
            </span>
          )}
        </div>
      </StyledBasicExpansionPanel>
    );
  }
}

export default withTranslation("emp")(CustomerInfoV2);
