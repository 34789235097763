import React, { Component } from "react";
import styles from "./AcceptTermsConditions.module.scss";
import Grid from "@material-ui/core/Grid";
import Error from "../../imgs/error/error.png";
import { InfoIconWithMessage } from "../../shared/infoIcon/InfoIcon";
import { withTranslation } from "react-i18next";
import { base64FileDownload } from "../../utils/FileDownloadUtil";
import TermsAndConditionsClient from "../../shared/clients/TermsAndConditionsClient";

class RenderPreview extends Component {
  handleTermsAndConditionsDownload = () => {
    base64FileDownload(
      this.props.file,
      "application/pdf",
      "terms-conditions.pdf"
    );
  };

  render() {
    const { Preview } = this.props;
    return (
      <Grid container spacing={24}>
        <Grid item xs={12} md={12}>
          <h3 className={styles.previewTitle}>
            {this.props.t("AcceptTermsConditions.termsHeader")}
          </h3>
          <Preview file={this.props.file} className={styles.previewPDF} />
          <div
            className={`${styles.download} ${styles.downloadPointer} ${styles.downloadFile} `}
          >
            <a
              download="terms-conditions.pdf"
              href={null}
              onClick={this.handleTermsAndConditionsDownload}
              className={`${styles.download}`}
            >
              {this.props.t("AcceptTermsConditions.download")}
            </a>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const RenderPreviewT = withTranslation("emp")(RenderPreview);

class AcceptanceSelector extends Component {
  render() {
    let pendingText,
      acceptedText,
      rejectedText,
      pendingLabelStyle = styles.pendingLabelStyle;
    if (this.props.currentStatus === "yes") {
      acceptedText = "";
      pendingText = this.props.t("AcceptTermsConditions.yes");
      rejectedText = "";
      pendingLabelStyle = "";
    } else if (this.props.currentStatus === "no") {
      acceptedText = "";
      pendingText = this.props.t("AcceptTermsConditions.no");
      rejectedText = "";
    } else if (this.props.currentStatus === "pending") {
      acceptedText = this.props.t("AcceptTermsConditions.no");
      pendingText = "";
      rejectedText = this.props.t("AcceptTermsConditions.yes");
      pendingLabelStyle = "";
    }

    const selectedStyle =
      this.props.currentStatus === "yes" ? styles.labelYes : styles.labelNo;

    return (
      <div className={`${styles.acceptanceSelector} pagePadding`}>
        <span className="primary-text-bold-color">
          {this.props.t("AcceptTermsConditions.accept")}
        </span>
        <div className={`${styles.switchToggle} switch-3 switch-candy`}>
          <div>
            <input
              className={styles.toggleInput}
              id="off"
              data-testid="off"
              name="state-d"
              type="radio"
              value="no"
              onChange={(e) => this.props.onSelect(e)}
              checked={this.props.currentStatus === "no"}
            />
            <label
              className={`${styles.toggleLabel} ${styles.labelNo}`}
              htmlFor="off"
            >
              {acceptedText}
            </label>
          </div>

          <div>
            <input
              className={styles.toggleInput}
              id="na"
              name="state-d"
              type="radio"
              value="pending"
              onChange={(e) => this.props.onSelect(e)}
              checked={this.props.currentStatus === "pending"}
            />
            <label
              className={`${styles.toggleLabel} ${selectedStyle} ${pendingLabelStyle}`}
              htmlFor="na"
            >
              {pendingText}
            </label>
          </div>

          <div>
            <input
              className={styles.toggleInput}
              id="on"
              data-testid="on"
              name="state-d"
              type="radio"
              value="yes"
              onChange={(e) => this.props.onSelect(e)}
              checked={this.props.currentStatus === "yes"}
            />
            <label
              className={`${styles.toggleLabel} ${styles.labelYes}`}
              htmlFor="on"
            >
              {rejectedText}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

const AcceptanceSelectorT = withTranslation("emp")(AcceptanceSelector);

class Consequences extends Component {
  render() {
    if (this.props.status === "no") {
      return (
        <div id="consequences" className={`${styles.notAcceptMsg}`}>
          <img src={Error} className={styles.Error} />
          {this.props.t("AcceptTermsConditions.warning")}
        </div>
      );
    } else {
      return <div className={styles.notAcceptMsg}>&nbsp;</div>;
    }
  }
}

const ConsequencesT = withTranslation("emp")(Consequences);

class AcceptanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "pending",
    };
  }

  componentDidMount() {
    this.props.showActionFooter(this.state.status !== "pending");
  }

  onSelectHandler(e) {
    const termsStatus = e.target.value;
    if (termsStatus === "pending") {
      this.setState({ status: "pending" }, () => {
        this.props.updateParentState({ status: "pending" });
        this.props.showActionFooter(false);
      });
    }
    if (termsStatus === "no") {
      this.setState({ status: "no" }, () => {
        this.props.updateParentState({ status: "no" });
        this.props.showActionFooter(true);
      });
    }
    if (termsStatus === "yes") {
      this.setState({ status: "yes" }, () => {
        this.props.updateParentState({ status: "yes" });
        this.props.showActionFooter(true);
      });
    }
  }

  render() {
    return (
      <div>
        <AcceptanceSelectorT
          currentStatus={this.state.status}
          onSelect={(e) => this.onSelectHandler(e)}
        />
        {this.state.status === "no" ? (
          <ConsequencesT status={this.state.status} />
        ) : (
          ""
        )}
      </div>
    );
  }
}

class AcceptTermsConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileFound: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchTermsConditions();
  }

  fetchTermsConditions() {
    this.props.hideOrShow(true);
    TermsAndConditionsClient.retrieveFile(this.props.user)
      .then((file) => this.setState({ file: file, fileFound: true }))
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({ isLoading: false });
      });
  }

  render() {
    if (!this.state.isLoading && this.state.fileFound) {
      return (
        <div id="acceptTermsAndConditions" className="pageWrapper">
          <div className="pagePadding">
            <h1 className="pageTitle">
              {this.props.t("AcceptTermsConditions.header")}
            </h1>
            <div className="pageInfoWrapper">
              <InfoIconWithMessage
                message={this.props.t("AcceptTermsConditions.subheader")}
              />
            </div>
            <RenderPreviewT
              file={this.state.file}
              Preview={this.props.Preview}
            />
          </div>
          {this.state.file === null ? (
            // eslint-disable-next-line
            <></>
          ) : (
            <AcceptanceForm {...this.props} file={this.state.file} />
          )}
        </div>
      );
    } else if (!this.state.fileFound && !this.state.isLoading) {
      return (
        <h1 id="notFound" data-testid="notFound">
          {this.props.t("AcceptTermsConditions.docNotFound")}
        </h1>
      );
    } else {
      return "";
    }
  }
}

export default withTranslation("emp")(AcceptTermsConditions);
