import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { InfoIconWithMessage } from "../../../shared/infoIcon/InfoIcon";
import styles from "./MerchantId.module.scss";
import "../../../styles/sharedStyles.scss";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { logMsg } from "../../../common/Logger";
import DealerMerchantIdClient from "../../../shared/clients/DealerMerchantIdClient";
import HasPermissionTo from "../../../common/HasPermissionTo";
import {
  IS_ZAF_MARKET,
  IS_VNM_MARKET,
  GET_DEALER_NAME,
} from "../../../utils/EmpUtil";
import PendingActionClient from "../../../shared/clients/PendingActionClient";
import { MERCHANT_ID } from "../../../common/Constants";
import AppActionsLogClient from "../../../shared/clients/AppActionsLogClient";

class MerchantId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealers: [],
      originalDealers: [],
      buttonDisabled: true,
      isDataLoaded: false,
    };

    this.uploadMerchantIds = this.uploadMerchantIds.bind(this);
    this.translationPrefix =
      IS_ZAF_MARKET(this.props.user.market) ||
      IS_VNM_MARKET(this.props.user.market)
        ? "StoreIdOriginal"
        : "MerchantId";
  }

  componentDidMount() {
    const STRIPE_MARKET =
      this.props.user.permissions.rolePermissions.hasOwnProperty(
        "stripeEnabled"
      );
    if (STRIPE_MARKET) {
      this.merchantIdValidation = "matchRegexp:^acct_[A-Za-z\\d]{16,25}$";
    } else if (IS_ZAF_MARKET(this.props.user.market)) {
      this.merchantIdValidation = "matchRegexp:^[0-9]{6}$";
    } else if (IS_VNM_MARKET(this.props.user.market)) {
      this.merchantIdValidation = "matchRegexp:^[0-9]{10}$";
    } else {
      this.merchantIdValidation = "matchRegexp:^[A-Za-z0-9]{13}$";
    }

    if (this.props.fromQuickstart) {
      this.props.showActionFooter(true);
      this.setState({
        dealers: this.props.dealerGroup.filter(
          (dealer) =>
            this.isMainDealer(dealer) || this.isNonOnboardedBranchDealer(dealer)
        ),
        isDataLoaded: true,
      });
    } else {
      this.getMerchantIds();
    }
  }

  isMainDealer = (dealer) => this.props.user.isMainDealer(dealer.dealerType);

  isNonOnboardedBranchDealer = (dealer) =>
    !dealer.isOnboarded && this.props.user.isBranchDealer(dealer.dealerType);

  getMerchantIds() {
    const dealerList = [];

    const dealerMap = {};
    this.props.dealerGroup.forEach((dealer) => {
      dealerMap[dealer.commonId] = dealer;
    });
    this.props.hideOrShow(true);
    DealerMerchantIdClient.getMerchantIds(this.props.user)
      .then((res) => {
        const merchantIdData = res.result.dealerMerchantIdData;
        const merchantIdMap = {};
        merchantIdData.forEach((dealer) => {
          merchantIdMap[dealer.dealerCommonId] = dealer.merchantId;
          const dealerFromMap = dealerMap[dealer.dealerCommonId];
          dealerFromMap.merchantId = dealer.merchantId;
          dealerList.push(dealerFromMap);
        });
        this.merchantIdMap = merchantIdMap;
        dealerList.sort((a, b) => {
          if (a.dealerType === "MDLR" || a.dealerType === "HUB") {
            return -1;
          } else if (b.dealerType === "MDLR" || b.dealerType === "HUB") {
            return 1;
          } else {
            return 0;
          }
        });
        this.setState({ dealers: dealerList, originalDealers: dealerList });
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({ isDataLoaded: true });
      });
  }

  merchantIdChangeHandler = () => (event) => {
    const currentDealers = this.state.dealers;
    if (currentDealers) {
      const newDealers = currentDealers.map((dealer) => {
        if (event.target.name === dealer.commonId) {
          return { ...dealer, merchantId: event.target.value };
        }
        return dealer;
      });

      let disabled = true;

      this.merchantIdMap &&
        newDealers.forEach((dealer) => {
          if (disabled) {
            const merchantIdValueFromMap = this.merchantIdMap[dealer.commonId];
            if (merchantIdValueFromMap !== dealer.merchantId) {
              disabled = false;
            }
          }
        });

      this.setState({ dealers: newDealers, buttonDisabled: disabled });
    }
  };

  uploadMerchantIds() {
    if (this.props.fromQuickstart) {
      this.props.onSubmit({ dealers: this.state.dealers });
    } else {
      const updatedList = this.state.dealers;
      const originalDealers = JSON.parse(
        JSON.stringify(this.state.originalDealers)
      );
      const changed = [];
      let commonId;
      let copyIndex;
      for (const dealer in updatedList) {
        commonId = updatedList[dealer].commonId;
        copyIndex = originalDealers.indexOf(
          originalDealers.find((obj) => obj.commonId === commonId)
        );
        if (
          originalDealers[copyIndex].merchantId !==
          updatedList[dealer].merchantId
        ) {
          changed.push({
            dealerCommonId: commonId,
            merchantId: updatedList[dealer].merchantId,
            updatedBy: this.props.user.userId,
          });
        }
      }
      this.props.hideOrShow(true);
      DealerMerchantIdClient.saveMerchantIds(changed, this.props.user.token)
        .then(() => {
          this.getMerchantIds();
          this.removePendingAction();
          if (this.props.user.pendingActions.length - 1 === 0) {
            this.props.updateDealerGroupDetails();
          }
        })

        .then(() => {
          const payload = {
            userId: this.props.user.userId,
          };

          AppActionsLogClient.recordMerchantIdChange(this.props.user, payload)
            .then(() => {})
            .catch(() => {});
        })
        .catch((err) => logMsg(err))
        .finally(() => {
          this.props.hideOrShow(false);
        });
      this.setState({ buttonDisabled: true });
    }
  }

  removePendingAction = () => {
    const pendingActionStatusForDealers =
      this.props.user.getPendingActionStatusForDealers(
        this.props.user.pendingActions,
        MERCHANT_ID
      );
    if (pendingActionStatusForDealers.length !== 0) {
      this.props.hideOrShow(true);
      PendingActionClient.removeDealerPendingAction(
        this.props.user,
        this.getDealerList(),
        MERCHANT_ID
      )
        .then(() => {
          this.props.getPendingActions();
        })
        .finally(() => {
          this.props.hideOrShow(false);
        });
    }
  };

  getDealerList = () => {
    const dealers = [];
    let dealerList = this.props.user.getCommonIdsWithoutSDLR();
    if (dealerList) {
      dealerList = dealerList.split(",");
      dealerList.forEach((dealer) => {
        const formattedDealer = this.props.user.reformatCommonId(dealer);
        dealers.push(
          this.props.user.dealerGroupingsMap[formattedDealer].completeDealerId
        );
      });
    }
    return dealers;
  };

  render() {
    const dealers = this.state.dealers;
    const subHeader = this.props.fromQuickstart
      ? this.props.t(`${this.translationPrefix}.subheaderFromQ`)
      : this.props.t(`${this.translationPrefix}.subheaderFromB`);
    return (
      this.state.isDataLoaded && (
        <div className="pageWrapper">
          <div className="pagePadding">
            <h1 id="merchantIdHeader" className="pageTitle">
              {this.props.t(`${this.translationPrefix}.header`)}
            </h1>
            <div className="pageInfoWrapper">
              <InfoIconWithMessage message={subHeader} />
            </div>
          </div>
          <Grid
            container
            className={`${styles.columnHeaderRow} pagePaddingLeft pagePaddingRight`}
          >
            <Grid item xs={10} className={styles.columnHeader} id="site">
              {this.props.t(`${this.translationPrefix}.site`)}
            </Grid>
            <Grid
              item
              xs={2}
              className={`${styles.columnHeader} ${styles.extraPaddingRight}`}
              id="merchantId"
            >
              {this.props.t(`${this.translationPrefix}.id`)}
            </Grid>
          </Grid>

          <ValidatorForm
            id="merchantIdForm"
            ref={this.props.formRef}
            onSubmit={this.uploadMerchantIds}
          >
            {dealers.map((dealer) => (
              <Grid
                container
                id="dealerRow"
                key={dealer.commonId}
                className={`${styles.dataRow} pagePaddingLeft pagePaddingRight`}
              >
                <Grid item xs={10} className={styles.dataRowCell}>
                  {GET_DEALER_NAME(dealer, this.props.user.market)} (
                  {dealer.dealerId})
                </Grid>
                <Grid item xs={2}>
                  <HasPermissionTo
                    perform={["merchantId:write"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    render={() => {
                      return (
                        <TextValidator
                          data-testid="inputField"
                          style={{ width: "100%" }}
                          autoComplete="off"
                          name={dealer.commonId}
                          disabled={this.props.isMainViewingSDLR(dealer)}
                          className={styles.merchantIdInput}
                          variant="outlined"
                          value={dealer.merchantId || ""}
                          onChange={this.merchantIdChangeHandler(
                            dealer.commonId
                          )}
                          validators={["required", this.merchantIdValidation]}
                          errorMessages={[
                            this.props.t(`${this.translationPrefix}.errorReq`),
                            this.props.t(`${this.translationPrefix}.errorId`),
                          ]}
                        />
                      );
                    }}
                    noRender={() => {
                      return (
                        <div className={styles.merchantIdText}>
                          {dealer.merchantId}
                        </div>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            ))}
            <HasPermissionTo
              perform={["merchantId:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={!this.props.fromQuickstart}
              render={() => {
                return (
                  <div
                    className={
                      this.state.buttonDisabled
                        ? "disabledButtonDiv"
                        : "confirmButton"
                    }
                  >
                    <button
                      disabled={this.state.buttonDisabled}
                      data-testid="saveBtn"
                      id="saveBtn"
                      type="submit"
                    >
                      {this.props.t(`${this.translationPrefix}.saveAndUpdate`)}
                    </button>
                  </div>
                );
              }}
            />
          </ValidatorForm>
        </div>
      )
    );
  }
}

export default withTranslation("emp")(MerchantId);
