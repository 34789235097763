import React, { Component } from "react";
import styles from "./ActionFooter.module.scss";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";

class ActionFooter extends Component {
  render() {
    return (
      // eslint-disable-next-line
      <>
        {this.props.displayActionFooter ? (
          <div
            id="actionFooter"
            className={`${styles.actionFooter} actionFooter`}
          >
            <Button
              type="button"
              onClick={this.props.observer}
              className="saveBtn"
              id="save-action-footer-btn"
            >
              {this.props.actionName}
            </Button>
          </div>
        ) : null}
      </>
    );
  }
}

export default withTranslation("emp")(ActionFooter);
