import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";

const invalidateSessionAndRedirect = () => {
  invalidateSession();

  window.close();
  AuthenticationFailureHandler.logout();
};

const invalidateSession = () => {
  sessionStorage.removeItem("adfs.token");
};

export { invalidateSession, invalidateSessionAndRedirect };
