//Collapse translation section which is not applicable to IMG Market
export const COLLAPSE_TRANSLATIONS = [
  "StoreId",
  "nscDeliveryBands",
  "dealerDeliveryBands",
  "VariableMarketing",
  "DealerFees",
  "PricingDetails",
  "OrderDetailsPricingSummary",
  "OrderDetailsConsumerDeposit",
  "OrderDetailsPaymentMethod",
  "ProtectionPlans",
  "dealerTermsDialog",
  "NamePlate",
  "AccessoriesSearch",
  "BrandSettings",
  "PaymentMethod",
  "WallBox",
  "VehicleSpecs",
  "ReservationRelease",
  "LeadTimes",
  "AccessoriesPricing",
  "FinancialDetailsDialogBox",
  "CustomerOptOffline",
  "ChargingModule",
  "FinanceBreakdown",
  "ItemsNotIncludedOnQuote",
  "CashBreakdown",
  "HandOver",
  "HandOverCompleteDialog",
  "HandOverCompleteCalenderDialog",
  "HandOverCompleteConfirmDialog",
  "HandOverReleaseCommsDialog",
  "HandOverReleaseCommsCalenderDialog",
  "HandOverAppointmentDialog",
  "HandOverCancelAppointmentDialog",
  "PMRegistrationNumber",
  "PMDealerInvoice",
  "PMDeliveryCertificate",
  "PMDeliveryNotes",
  "PMFinanceContract",
  "PMLeaseContract",
  "PMLeasePayBalance",
  "PMLeasePayment",
  "PMCustomerDownPayment",
  "PMFinancePayment",
  "PMAcceptTheOrder",
  "PMOpenPaymentWindow",
  "PMCashPayment",
  "CategorySettings",
  "DocuSignPricingStack",
  "DocuSignPricingTotals",
  "DocuSignVehicleInfo",
];
