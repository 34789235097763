import React, { Component } from "react";
import crStyles from "../customerReservation/crStyles";
import styles from "../tradeIn/TradeIn.module.scss";
import sharedStyles from "../customerOrderDetail/orderDetailsNewVersion/OrderDetailsNewVersion.module.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withTranslation } from "react-i18next";
import {
  IS_USA_MARKET,
  OFFLINE_TRADE_IN_MARKETS,
  ONLINE_TRADE_IN_MARKETS,
} from "../../utils/EmpUtil";
import HasPermissionTo from "../../common/HasPermissionTo";
import { FINANCED, LEASED, OWNED } from "../../common/Constants";
import OfflineTradeIn from "./offline/OfflineTradeIn";
import OnlineTradeIn from "./online/OnlineTradeIn";
import { withStyles } from "@material-ui/core";
import SharedToolTip from "../../shared/sharedToolTip/SharedToolTip";
import Tooltip from "@material-ui/core/Tooltip";
import Clear from "@material-ui/icons/Clear";
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
} from "../../shared/ExpansionPanels/ExpansionPanel";

const StyledClear = withStyles({
  root: {
    color: "#102b4e",
  },
})(Clear);

const StyledTooltip = withStyles({
  popper: {
    opacity: 1,
    pointerEvents: "auto",
  },
  tooltip: {
    background: "white",
    boxShadow: "1px 1px 9px 0 rgba(0, 0, 0, 0.27)",
    paddingTop: 28,
    paddingLeft: 28,
    paddingRight: 28,
    paddingBottom: 18,
    maxWidth: 350,
  },
})(Tooltip);

class TradeIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandTradeInDetails: true,
      confirmationDialog: false,
      tradeInTooltipFlag: false,
      mustAcceptPurchaseRequestTooltipFlag: false,
    };
  }

  handleConfirmationDialog = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      confirmationDialog: !prevState.confirmationDialog,
    }));
  };

  expandCollapseAccordion = () =>
    this.setState((prevState) => ({
      expandTradeInDetails: !prevState.expandTradeInDetails,
    }));

  parseBoolean = (value) => {
    if (value === "false" || value === false) {
      return this.props.t("GeneralEMP.statusNo");
    } else if (value === "true" || value === true) {
      return this.props.t("GeneralEMP.statusYes");
    } else {
      return value;
    }
  };

  getTradeInType = (type) => {
    switch (type) {
      case FINANCED:
        return this.props.t("TradeIn.US.finance");
      case LEASED:
        return this.props.t("TradeIn.US.lease");
      case OWNED:
        return this.props.t("TradeIn.US.owned");
      default:
        return null;
    }
  };

  toggleToolTip = (tooltip, e) => {
    e && e.stopPropagation();
    this.setState((prevState) => ({
      [tooltip]: !prevState[tooltip],
    }));
  };

  render() {
    const { customerReservation } = this.props;
    const market =
      this.props.fromCrc &&
      this.props.user.market !== "USA" &&
      this.props.user.market !== "CAN"
        ? this.props.selectedMarket
        : this.props.user.market;

    const tradeInType =
      this.props.tradeInDetails &&
      this.getTradeInType(this.props.tradeInDetails.ownershipType);

    let tradeInHeader = IS_USA_MARKET(market)
      ? this.props.t("TradeIn.US.header")
      : this.props.t("TradeIn.header");

    if (tradeInType) {
      tradeInHeader = `${tradeInHeader} - ${tradeInType}`;
    }
    const disableDealerActionsFlag =
      customerReservation &&
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.disableDealerActionsFlag;

    const tradeInAssessmentFlag =
      !this.props.tradeInDetails.dealerSubmitted &&
      !this.props.tradeInDetails.tradeInExpired;
    return (
      <StyledExpansionPanel
        defaultExpanded={false}
        onChange={this.expandCollapseAccordion}
      >
        <StyledExpansionPanelSummary style={crStyles.headerBarBg}>
          <div className={sharedStyles.headerBar}>
            <div className={sharedStyles.header}>
              {tradeInHeader}
              {IS_USA_MARKET(market) && (
                <SharedToolTip
                  openTestid="tradeInTooltipOpen"
                  closeTestid="tradeInTooltipClose"
                  open={this.state.tradeInTooltipFlag}
                  closeTooltip={(e) =>
                    this.toggleToolTip("tradeInTooltipFlag", e)
                  }
                  openTooltip={(e) =>
                    this.toggleToolTip("tradeInTooltipFlag", e)
                  }
                  body={this.props.t(
                    "OrderDetailsConsumerDeposit.tradeInTooltipBody"
                  )}
                />
              )}
            </div>
            <div className={styles.acceptRejectDiv}>
              <HasPermissionTo
                perform={["tradeIn:write"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={
                  IS_USA_MARKET(market) &&
                  !this.props.tradeInDetails.dealerSubmitted &&
                  !this.props.tradeInDetails.tradeInExpired &&
                  tradeInType
                }
                render={() => {
                  return this.props.linkEnabled ? (
                    <button
                      className={`${styles.acceptRejectButton} ${
                        tradeInAssessmentFlag && disableDealerActionsFlag
                          ? styles.disableDealerActionsAccept
                          : ""
                      }`}
                      onClick={this.handleConfirmationDialog}
                      data-testid="accept-reject-trade-in"
                      disabled={
                        tradeInAssessmentFlag && disableDealerActionsFlag
                      }
                    >
                      {this.props.t("TradeIn.US.acceptRejectTradeIn")}
                    </button>
                  ) : (
                    <StyledTooltip
                      placement="bottom-start"
                      open={this.state.mustAcceptPurchaseRequestTooltipFlag}
                      title={
                        <div>
                          <button
                            onClick={(e) =>
                              this.toggleToolTip(
                                "mustAcceptPurchaseRequestTooltipFlag",
                                e
                              )
                            }
                            className={styles.exitButton}
                          >
                            <StyledClear />
                          </button>
                          <div className={styles.bodyContent}>
                            {this.props.t(
                              "TradeIn.US.mustAcceptPurchaseRequestTooltipBody"
                            )}
                          </div>
                        </div>
                      }
                    >
                      <button
                        className={styles.disabledAcceptRejectButton}
                        data-testid="accept-reject-trade-in-disabled"
                        onClick={(e) =>
                          this.toggleToolTip(
                            "mustAcceptPurchaseRequestTooltipFlag",
                            e
                          )
                        }
                        disabled={
                          tradeInAssessmentFlag && disableDealerActionsFlag
                        }
                      >
                        {this.props.t("TradeIn.US.acceptRejectTradeIn")}
                      </button>
                    </StyledTooltip>
                  );
                }}
              />
              {this.state.expandTradeInDetails ? (
                <ExpandMoreIcon data-testid="more" />
              ) : (
                <ExpandLessIcon data-testid="less" />
              )}
            </div>
          </div>
        </StyledExpansionPanelSummary>

        {this.props.isOrderCancelled(this.props.status) && (
          <div className={styles.overlay} data-testid="tradeInOverlay" />
        )}
        {ONLINE_TRADE_IN_MARKETS.includes(market) && (
          <OnlineTradeIn parseBoolean={this.parseBoolean} {...this.props} />
        )}
        {OFFLINE_TRADE_IN_MARKETS.includes(market) && (
          <OfflineTradeIn parseBoolean={this.parseBoolean} {...this.props} />
        )}
      </StyledExpansionPanel>
    );
  }
}

export default withTranslation("emp")(TradeIn);
