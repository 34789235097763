import React, { Component } from "react";
import styles from "./MultiToggleWidget.module.scss";
import { withTranslation } from "react-i18next";

class MultiToggleWidget extends Component {
  render() {
    let acceptedText, rejectedText, acceptedStyle, rejectedStyle;
    const toggleDisabled =
      this.props.isToggleDisabled && `${styles.toggleDisabled}`;
    const switchStyle = this.props.fromDealerTable
      ? styles.switchToggleSmall
      : styles.switchToggle;
    const toggleLabelStyle = this.props.fromDealerTable
      ? styles.toggleLabelSmall
      : styles.toggleLabel;
    const toggleInputStyle = this.props.fromDealerTable
      ? styles.toggleInputSmall
      : styles.toggleInput;
    if (this.props.currentStatus === "yes") {
      acceptedText = this.props.settings.acceptedText;
      rejectedText = "";
      acceptedStyle = toggleLabelStyle + " " + this.props.settings.toggleLabel;
      rejectedStyle = styles.switchLabel;
    } else if (this.props.currentStatus === "no") {
      acceptedText = "";
      rejectedText = this.props.settings.rejectedText;
      acceptedStyle = styles.switchLabel;
      rejectedStyle = toggleLabelStyle + " " + this.props.settings.toggleLabel;
    }

    return (
      // eslint-disable-next-line
      <>
        {toggleDisabled ? (
          <div
            className={`${styles.toggleLabel} ${this.props.settings.suppClass}`}
            id={`disabled_${this.props.toggleName}`}
            data-testid={`disabled_${this.props.toggleName}`}
          >
            <div className={`${styles.labelYes}`}>{acceptedText}</div>
            <div className={`${styles.labelNo}`}>{rejectedText}</div>
          </div>
        ) : (
          <div
            className={`${this.props.settings.suppClass} ${switchStyle} ${toggleDisabled} switch-2 switch-candy`}
          >
            <input
              className={toggleInputStyle}
              id={`${this.props.toggleName}`}
              data-testid={`${this.props.toggleName}`}
              disabled={this.props.isToggleDisabled}
              name={`${this.props.toggleName}`}
              type="checkbox"
              value="yes"
              onChange={(e) => this.props.onSelect(e)}
              checked={this.props.currentStatus === "yes"}
            />
            <label htmlFor={`${this.props.toggleName}`}>
              <div className={`${acceptedStyle} ${styles.labelYes}`}>
                {acceptedText}
              </div>
              <div className={`${rejectedStyle} ${styles.labelNo}`}>
                {rejectedText}
              </div>
            </label>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation("emp")(MultiToggleWidget);
