import React, { Component } from "react";
import ProgressBar from "../progressBar/ProgressBar";
import PageHeader from "../../shared/pageHeader/PageHeader";
import ActionFooter from "../../shared/actionFooter/ActionFooter";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../common/HasPermissionTo";
import PendingActionClient from "../../shared/clients/PendingActionClient";

class QuickStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: this.props.progressBarTabs,
      currentTabKey: 0,
      displayActionFooter: false,
      errorMessage: "",
    };
    this.form = React.createRef();
    this.file = null;
    this.termsAcceptedStatus = "";
  }

  componentDidMount() {
    if (this.props.isNsc) {
      PendingActionClient.getNscPendingActionsNotifications(this.props.user);
    }
  }

  onSubmit = (data) => {
    if (data.reservations) {
      if (data.reservations.length === 0) {
        this.setState({
          errorMessage: "Please select a Model to apply the order deposit.",
        });
      } else {
        this.continueBoarding(data);
      }
    } else {
      this.continueBoarding(data);
    }
  };

  nextStep = {
    onSuccess: () => {
      this.setState({
        currentTabKey: this.state.currentTabKey + 1,
        displayActionFooter: true,
      });
    },
    onFailure: () => {},
  };

  continueBoarding = (data) => {
    this.setState({ errorMessage: "" });
    this.props.hideOrShow(true, true);
    this.props.onboardingClient
      .onBoard(this.props.user, data, this.props.dealerGroup)
      .then((response) => {
        if (response === "go to dashboard") {
          this.props.onSave(this.props.user);
        } else if (response === "go to decline page") {
          this.props.onDecline();
        } else {
          this.nextStep.onSuccess();
        }
      })
      .catch((error) => this.nextStep.onFailure(error))
      .finally(() => this.props.hideOrShow(false, true));
  };

  toggleActionFooter = (value) => {
    this.setState({ displayActionFooter: value });
  };

  updateCurrentObject = (childState) => {
    if (childState.file) {
      this.file = childState.file;
    }
    if (childState.status) {
      this.termsAcceptedStatus = childState.status;
    }
  };

  validateFormInfo = () => {
    const saveBtn = this.state.tabs[this.state.currentTabKey].saveBtn;
    switch (saveBtn) {
      case "welcome":
        if (this.props.dealerGroup.length > 0) {
          this.nextStep.onSuccess();
        }
        break;
      case "uploadFile":
        this.onSubmit({ file: this.file });
        break;
      case "saveForm":
        this.form.current && this.form.current.submit();
        break;
      case "acceptTermsAndConditions":
        this.onSubmit({ termsAcceptedStatus: this.termsAcceptedStatus });
        break;
      case "savePaymentThreshold":
        this.form.current && this.form.current.submit();
        break;
      default:
    }
  };

  render() {
    return (
      <div id="quickStart" className="pageWrapper">
        <PageHeader
          {...this.props}
          title={this.props.t("GeneralEMP.onboarding")}
        />
        {this.state.tabs[this.state.currentTabKey].key !== 0 && (
          <HasPermissionTo
            perform={["progressBar:view"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => (
              <ProgressBar
                tabs={this.state.tabs}
                currentTab={this.state.tabs[this.state.currentTabKey]}
                marketId={this.props.user.market}
              />
            )}
          />
        )}
        {React.createElement(
          this.state.tabs[this.state.currentTabKey].stepComponent,
          {
            ...this.props,
            showActionFooter: this.toggleActionFooter,
            updateParentState: this.updateCurrentObject,
            onSubmit: this.onSubmit,
            formRef: this.form,
            errorMessage: this.state.errorMessage,
          }
        )}
        {this.state.tabs[this.state.currentTabKey].name !==
        "ProgressBar.dealer.welcome" ? (
          <ActionFooter
            actionName={this.props.t(
              this.state.tabs[this.state.currentTabKey].actionText
            )}
            disabled={
              this.props.dealerGroup
                ? this.props.dealerGroup.length === 0
                : false
            }
            observer={this.validateFormInfo}
            displayActionFooter={this.state.displayActionFooter}
          />
        ) : null}
      </div>
    );
  }
}

export default withTranslation("emp")(QuickStart);
