import React from "react";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { Button } from "@material-ui/core";
import styles from "./DownloadButton.module.scss";
import { withStyles } from "@material-ui/core/styles";

const DownloadButton = (props) => {
  const StyledButton = withStyles({
    root: {
      minWidth: 0,
    },
  })(Button);
  const StyledSaveAltIcon = withStyles({
    root: {
      height: 18,
      margin: 2,
      width: 18,
      padding: 0,
    },
  })(SaveAltIcon);

  return (
    <StyledButton onClick={props.download}>
      <div className={styles.download}>
        <div>{props.text}</div>
        <StyledSaveAltIcon />
      </div>
    </StyledButton>
  );
};

export default DownloadButton;
