export const PAGE_ERROR = "PAGE_ERROR";

export const ERROR_PROCESSING = "ERROR_PROCESSING";

export const LOAD_ERROR = "LOAD_ERROR";

export const is401 = (error) => {
  return error && error.response && error.response.status === 401;
};

export const is400 = (error) => {
  return error && error.response && error.response.status === 400;
};

export const is404 = (error) => {
  return error && error.response && error.response.status === 404;
};

export const is500 = (error) => {
  return error && error.response && error.response.status === 500;
};

export const isUnknownError = (error) => {
  return error === null || error === undefined;
};

export const isProcessingError = (error) => {
  return is400(error) || is404(error) || is500(error) || isUnknownError(error);
};

export const displayError = (errors) => {
  return errors && errors.length > 0;
};
