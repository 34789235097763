import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class CustomerContractClient {
  static baseUrl = BASE_URL;

  static getCustomerContracts(token, dealerIds) {
    return axios
      .get(this.url(dealerIds), getHeaders(token))
      .then((response) => response.data.result.customerContracts)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static uploadCustomerContract(token, dealerIds, request) {
    return axios
      .put(this.url(dealerIds), request, getHeaders(token))
      .then((response) => response.status)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static updateContractEmails(token, request) {
    const url = `${this.baseUrl}/dealer/customer-contract/emails`;
    return axios
      .post(url, request, getHeaders(token))
      .then(() => Promise.resolve())
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static url = (dealerIds) =>
    `${this.baseUrl}/dealer/customer-contract?dealerIds=${dealerIds}`;
}
