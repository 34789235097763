import React, { Component } from "react";
import { InfoIconWithMessage } from "../../shared/infoIcon/InfoIcon";
import Grid from "@material-ui/core/Grid";
import styles from "./AldCupidId.module.scss";
import { withTranslation } from "react-i18next";
import EditIcon from "../../shared/Edit/EditIcon";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import HasPermissionTo from "../../common/HasPermissionTo";
import MarketPreferencesClient from "../../shared/clients/MarketPreferencesClient";
import PendingActionClient from "../../shared/clients/PendingActionClient";
import { CUPID_ID } from "../../common/Constants";

class AldCupidId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      cupidId: undefined,
      cupidIdCopy: undefined,
      saved: false,
    };
  }

  componentDidMount() {
    this.props.hideOrShow(true);

    MarketPreferencesClient.getAldCupidId(this.props.user)
      .then((response) => {
        const aldCupidId = response.result.aldCupidId;
        this.setState({
          cupidId: aldCupidId || "",
          cupidIdCopy: aldCupidId || "",
        });
      })
      .catch(() => {})
      .finally(() => {
        this.props.hideOrShow(false);
      });
  }

  handleEdit = () => {
    this.setState({ editMode: true, saved: false });
  };

  handleBlur = (e) => {
    if (e.target.value) {
      if (this.aldCupidIdRef.current.isValid()) {
        this.setState({ editMode: false });
      }
    }
  };

  handleChange = (event) => {
    this.setState({ cupidId: event.target.value });
  };

  handleSubmit = () => {
    this.props.hideOrShow(true);

    const cupidIdRequest = {
      cupidLeadID: this.state.cupidId,
    };

    MarketPreferencesClient.saveCupidIdPreferences(
      this.props.user,
      cupidIdRequest
    )
      .then(() => {
        PendingActionClient.removeNscPendingAction(
          this.props.user,
          CUPID_ID,
          2
        );
        this.setState({
          editMode: false,
          cupidIdCopy: this.state.cupidId,
          saved: true,
        });
      })
      .finally(() => this.props.hideOrShow(false));
  };

  aldCupidIdRef = React.createRef();

  render() {
    return (
      <div className="pageWrapper">
        <ValidatorForm onSubmit={this.handleSubmit}>
          <div className="pagePaddingTop pagePaddingLeft pagePaddingRight">
            <InfoIconWithMessage
              message={this.props.t("AldCupidId.subheader")}
            />
          </div>

          <div className="pagePaddingTop">
            <Grid container className={styles.divider}>
              <Grid item xs={6}>
                <div className={`${styles.header} ${styles.leftAlignment}`}>
                  {this.props.t("AldCupidId.provider")}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={`${styles.header} ${styles.rightAlignment}`}>
                  <div>{this.props.t("AldCupidId.cupidId")}</div>
                  <HasPermissionTo
                    perform={["aldCupidId:write"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    condition={!this.state.editMode}
                    render={() => {
                      return (
                        <div className={styles.icon} onClick={this.handleEdit}>
                          <EditIcon />
                        </div>
                      );
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container className={styles.rowDivider}>
              <Grid item xs={6}>
                <div className={`${styles.leftAlignment} ${styles.aldCupidId}`}>
                  {this.props.t("AldCupidId.cupidIdRow")}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  className={`${styles.rightAlignment} ${styles.textBoxCell}`}
                >
                  {this.state.editMode ? (
                    <TextValidator
                      onBlur={this.handleBlur}
                      ref={this.aldCupidIdRef}
                      data-testid="aldCupidIdInput"
                      id="aldCupidIdInput"
                      name="aldCupidIdInput"
                      className={styles.inputField}
                      variant="outlined"
                      value={this.state.cupidId}
                      onChange={this.handleChange}
                      placeholder="-"
                      validators={["required", "matchRegexp:^[0-9]\\d{10}$"]}
                      errorMessages={[
                        this.props.t("Error.requiredField"),
                        this.props.t("AldCupidId.invalidId"),
                      ]}
                    />
                  ) : (
                    <div
                      className={styles.cupidIdValue}
                      data-testid="cupidText"
                    >
                      {this.state.cupidId}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>

          <HasPermissionTo
            perform={["aldCupidId:write"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => {
              return this.state.saved ? (
                <div className={styles.saved}>
                  {this.props.t("AldCupidId.saved")}
                </div>
              ) : (
                <div className={styles.applyButton}>
                  <button
                    type="submit"
                    data-testid="submitBtn"
                    disabled={this.state.cupidId === this.state.cupidIdCopy}
                  >
                    {this.props.t("DealerFees.applyButton")}
                  </button>
                </div>
              );
            }}
          />
        </ValidatorForm>
      </div>
    );
  }
}

export default withTranslation("emp")(AldCupidId);
