import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

export default class DealerMembershipStatusClient {
  static baseUrl = BASE_URL;

  static toggleEmpMembership(user, completeDealerId, active) {
    const status = active ? "ACTIVE" : "INACTIVE";
    const url = `${this.baseUrl}/dealer/${completeDealerId}/info/toggle-membership?status=${status}`;

    return axios
      .post(url, null, getHeaders(user.token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
