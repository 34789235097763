import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { getHeaders } from "../../utils/AuthorizationUtil";
import { BASE_URL } from "../../utils/EmpUtil";
import { is401 } from "../../errors/ErrorConstants";

class LeadTimesClient {
  static baseUrl = BASE_URL;

  static getLeadTimes(user) {
    const url = `${this.baseUrl}/nsc/leadtimes`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) =>
        response &&
        response.data &&
        response.data.result &&
        response.data.result.leadTimes
          ? Object.values(response.data.result.leadTimes)
          : []
      )
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
  static postLeadTimes(leadTimes, user) {
    const url = `${this.baseUrl}/nsc/leadtimes`;

    return axios
      .post(url, leadTimes, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}

export default LeadTimesClient;
