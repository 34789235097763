import React from "react";
import styles from "./LeadTimes.module.scss";
import { withTranslation } from "react-i18next";

const InfoTooltip = (props) => {
  return (
    <div className={styles.tooltipWrapper} data-testid="tooltipInfo">
      <div className={styles.tooltipTitle}>
        {props.t("LeadTimes.tooltipInfoTitle")}
      </div>
      <div className={styles.tooltipBody}>
        {props.t("LeadTimes.tooltipInfoBody")}
      </div>
      <div className={styles.tooltipDismissContainer}>
        <button
          onClick={props.closeInfoTooltip}
          className={styles.tooltipDismiss}
        >
          {props.t("LeadTimes.tooltipInfoGotIt")}
        </button>
      </div>
    </div>
  );
};

export default withTranslation("emp")(InfoTooltip);
