import React, { Component } from "react";
import styles from "./Footer.module.scss";
import HasPermissionTo from "../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import { THAILAND_ENGLISH, THAILAND_THAI } from "../../common/Constants";
import LanguageUtil from "../../utils/LanguageUtil";
import { publish } from "../../common/PubSub";
import HelpAndSupportDialog from "./HelpAndSupportDialog";
import { Link } from "react-router-dom";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));
  };

  updateLanguage = (language) => {
    LanguageUtil.updateLanguage(this.props, language);
    publish("language", language);
  };

  render() {
    return (
      <div className={styles.footerBox} data-testid="appFooter">
        <div className={styles.footerTitleBox}>
          {this.props.t("Footer.title")}
        </div>
        <div className={styles.helpContainer}>
          <HasPermissionTo
            perform={["languagePreference:write"]}
            permissions={this.props.permissions.rolePermissions}
            render={() => (
              <>
                <button
                  data-testid="english"
                  onClick={() => this.updateLanguage(THAILAND_ENGLISH)}
                >
                  ENGLISH
                </button>
                <button
                  data-testid="thai"
                  onClick={() => this.updateLanguage(THAILAND_THAI)}
                >
                  ไทย
                </button>
              </>
            )}
          />
          <HasPermissionTo
            perform={["helpAndSupportModal"]}
            permissions={this.props.permissions.rolePermissions}
            render={() => {
              return (
                <div>
                  <button onClick={this.toggleModal}>
                    {this.props.t("Footer.help")}
                  </button>
                  <HelpAndSupportDialog
                    {...this.props}
                    open={this.state.openModal}
                    closeModal={this.toggleModal}
                  />
                </div>
              );
            }}
          />
          <HasPermissionTo
            perform={["helpAndSupportLink"]}
            permissions={this.props.permissions.rolePermissions}
            render={() => {
              return (
                <Link
                  to={{
                    pathname: "/help",
                  }}
                >
                  {this.props.t("Footer.help")}
                </Link>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(Footer);
