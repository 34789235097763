import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import styles from "../online/OnlineTradeIn.module.scss";
import CheckBox from "../../../shared/checkBox/CheckBox";
import ValuationDialog from "../dialogs/valuationDialog/ValuationDialog";
import HasPermissionTo from "../../../common/HasPermissionTo";
import CustomerReservationsClient from "../../../shared/clients/CustomerReservationsClient";

class OfflineTradeIn extends Component {
  state = {
    openDialog: false,
  };

  handleSaveValuation = (expiryDate, valuationAmount) => {
    this.props.hideOrShow(true);
    CustomerReservationsClient.saveValuation({
      user: this.props.user,
      expiryDate: expiryDate,
      valuationAmount: valuationAmount,
      orderId: this.props.tradeInDetails.orderId,
      appraisalId: this.props.tradeInDetails.appraisalId,
    })
      .then(() => {
        this.props.refreshOrderDetails();
      })
      .catch()
      .finally(() => {
        this.props.hideOrShow(false);
        this.closeDialog();
      });
  };

  openDialog = () => this.setState({ openDialog: true });

  closeDialog = () => this.setState({ openDialog: false });

  render() {
    const { tradeInDetails } = this.props;

    const GridItem = ({ label, value }) => {
      return (
        <Grid item xs={3}>
          <div className={styles.gridItem}>
            <span className={styles.label}>{label}</span>
            <span className={styles.value}>
              {this.props.parseBoolean(value) || "-"}
            </span>
          </div>
        </Grid>
      );
    };

    return (
      <div className={styles.tradeInWrapper}>
        <span className={styles.subHeader}>
          {this.props.t("TradeIn.vehicleDetails")}
        </span>
        <Grid container>
          <GridItem
            label={this.props.t("TradeIn.registrationNumber")}
            value={tradeInDetails.registrationNumber}
          />

          <GridItem
            label={this.props.t("TradeIn.series")}
            value={tradeInDetails.trim}
          />

          <GridItem
            label={this.props.t("TradeIn.modelYear")}
            value={tradeInDetails.modelYear}
          />

          <GridItem
            label={this.props.t("TradeIn.outstandingFinance")}
            value={tradeInDetails.outstandingFinance}
          />
        </Grid>

        <Grid container>
          <GridItem
            label={this.props.t("TradeIn.vehicleMileage")}
            value={tradeInDetails.mileage}
          />

          <GridItem
            label={this.props.t("TradeIn.colour")}
            value={tradeInDetails.offlineColor}
          />

          <GridItem
            label={this.props.t("TradeIn.followedMaintenance")}
            value={tradeInDetails.scheduledMaintenance}
          />

          <GridItem
            label={this.props.t("TradeIn.vehicleCondition")}
            value={tradeInDetails.overallCondition}
          />
        </Grid>

        <Grid container>
          <GridItem
            label={this.props.t("TradeIn.make")}
            value={tradeInDetails.vehicleMake}
          />

          <GridItem
            label={this.props.t("TradeIn.transmission")}
            value={tradeInDetails.transmission}
          />

          <GridItem
            label={this.props.t("TradeIn.towBar")}
            value={tradeInDetails.towBar}
          />

          <GridItem
            label={this.props.t("TradeIn.tradeInRequest")}
            value={tradeInDetails.applyFinanceForTradeIn}
          />
        </Grid>

        <Grid container>
          <GridItem
            label={this.props.t("TradeIn.model")}
            value={tradeInDetails.vehicleModel}
          />

          <GridItem
            label={this.props.t("TradeIn.awd")}
            value={tradeInDetails.allWheelDrive}
          />

          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>

        <div className={styles.checkBoxPadding}>
          <HasPermissionTo
            perform={["tradeIn:write"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => (
              <CheckBox
                id="offline-trade-in"
                checked={
                  tradeInDetails.valuationAmount || this.state.openDialog
                }
                isDisabled={tradeInDetails.expiryDate}
                label={this.props.t("TradeIn.valuation")}
                onChange={this.openDialog}
                textClass={styles.checkBox}
              />
            )}
            noRender={() => (
              <CheckBox
                id="offline-trade-in"
                checked={tradeInDetails.expiryDate}
                isDisabled={true}
                label={this.props.t("TradeIn.valuation")}
                textClass={styles.checkBox}
              />
            )}
          />
        </div>

        <ValuationDialog
          open={this.state.openDialog}
          close={this.closeDialog}
          saveValuation={this.handleSaveValuation}
        />

        {tradeInDetails && tradeInDetails.expiryDate && (
          <div className={styles.valuationInfoWrapper}>
            <span className={styles.valuationAcceptedDate}>
              {tradeInDetails.acceptedDate}
            </span>
            {/*<span>{tradeInDetails.acceptedDate}</span>*/}
            <div className={styles.valuationInfo}>
              <div className={styles.valuationInfoRow}>
                <span className={styles.valuationInfoLabel}>
                  {this.props.t("TradeIn.setBy")}
                </span>
                <span className={styles.valuationInfoValue}>
                  &nbsp;- {this.props.user.userId}
                </span>
              </div>
              <div className={styles.valuationInfoRow}>
                <span className={styles.valuationInfoLabel}>
                  {this.props.t("TradeIn.amount")}
                </span>
                <span className={styles.valuationInfoValue}>
                  &nbsp;- {tradeInDetails.valuationAmount}
                </span>
              </div>
              <div className={styles.valuationInfoRow}>
                <span className={styles.valuationInfoLabel}>
                  {this.props.t("TradeIn.expiryDate")}
                </span>
                <span className={styles.valuationInfoValue}>
                  &nbsp;- {tradeInDetails.expiryDate}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation("emp")(OfflineTradeIn);
