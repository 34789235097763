import React, { Component } from "react";
import SubNav from "../../../shared/subnav/SubNav";
import CustomerInfoV2 from "./customerInfo/CustomerInfoV2";
import styles from "./OrderDetailsNewVersion.module.scss";
import HasPermissionTo from "../../../common/HasPermissionTo";
import { Link } from "react-router-dom";
import CancelOrderDialog from "../CancelOrderDialog";
import ProgressTracker from "./progressTracker/ProgressTracker";
import { withTranslation } from "react-i18next";
import CustomerReservationsClient from "../../../shared/clients/CustomerReservationsClient";
import { logMsg } from "../../../common/Logger";
import TradeIn from "../../tradeIn/TradeIn";
import WallBoxDetails from "./wallBoxDetails/WallBoxDetails";
import UpperCaseText from "../../../utils/UpperCaseText";
import CustomerOptOffline from "./customerOptOffline/CustomerOptOffline";
import OrderDetailsActionNotifications from "./orderDetailsActionNotifications/OrderDetailsActionNotifications";
import OrderInfo from "./orderInfo/OrderInfo";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import VehicleSpecs from "./vehicleSpecs/VehicleSpecs";
import DealerInformation from "../../customerHandling/orderDetails/DealerInformation";

class OrderDetailsNewVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerReservationDetails: null,
      isLoading: true,
      openCancelDialog: false,
      fetchFailed: false,
      is400Error: false,
    };
    this.dialogProps = {
      title: this.props.t("CustomerOrderDetails.confirmDialogTitle"),
      body: this.props.t("CustomerOrderDetails.bodyText"),
      checkbox: this.props.t("CustomerOrderDetails.checkBoxText"),
      checkboxList: [],
      cancel: this.props.t("CustomerOrderDetails.goBack"),
      confirm: this.props.t("CustomerOrderDetails.cancelBtnTxt"),
    };

    this.fetchCustomerOrderDetails = this.fetchCustomerOrderDetails.bind(this);
  }

  componentDidMount() {
    this.fetchCustomerOrderDetails();
    if (
      !this.props.lastPath ||
      !this.props.lastPath.includes("/customer-reservations")
    ) {
      const keys = Object.keys(sessionStorage);
      for (const key in keys) {
        const value = keys[key];
        if (
          value.startsWith("pageNumber_") ||
          value.startsWith("sortKey_") ||
          value.startsWith("sortDirection_") ||
          value.startsWith("sortByString_") ||
          value === "scrollPos"
        ) {
          sessionStorage.removeItem(value);
        }
      }
    }
  }

  handleUpdateOrder = (dealerAction, acceptedDate, cancelReason) => {
    this.props.hideOrShow(true);
    this.handleCancelClose();
    const dealerActionInfo = {
      reservationId: this.props.reservationId,
      dealerAction: dealerAction,
      acceptedDate: acceptedDate,
      dealerRejectedReason: cancelReason,
    };

    CustomerReservationsClient.updateOrder(
      this.props.token,
      this.props.commonId,
      dealerActionInfo,
      this.props.user.userId,
      true
    )
      .then((result) => {
        if (result === "400") {
          this.setState({ is400Error: true });
        } else {
          this.fetchCustomerOrderDetails();
        }
      })
      .catch((error) => logMsg("the error is: ", error))
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  handleCancelClose = () => {
    this.setState({ openCancelDialog: false });
  };

  fetchCustomerOrderDetails() {
    this.props.hideOrShow(true);
    this.setState({ fetchFailed: false });
    const market = this.props.fromCrc ? this.props.selectedMarket : null;
    CustomerReservationsClient.getCustomerOrderDetails(
      this.props.user,
      this.props.reservationId,
      this.props.commonId,
      this.props.fromCrc,
      this.props.lang,
      market
    )
      .then((result) => {
        this.setState({
          customerReservationDetails: result,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          fetchFailed: true,
          isLoading: false,
        });
        logMsg("the error is: ", error);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  }

  getSubNav() {
    return (
      <SubNav
        dashboard={false}
        title={this.props.t("CustomerOrderDetails.orderDetail")}
        restrictDropdown={true}
        {...this.props}
      />
    );
  }

  showCancelConfirmationPopup = (e) => {
    this.setState({ openCancelDialog: true });
    e.stopPropagation();
  };

  isOrderCancelled = (status) => {
    return (
      status === "DEALER_CANCELLED" ||
      status === "RESERVATION_CANCELLED" ||
      status === "CONTRACT_CANCELLED" ||
      status === "ORDER_CANCELLED"
    );
  };

  isReservation = (orderType) => {
    return UpperCaseText(orderType) === "RESERVATION";
  };

  render() {
    if (this.state.isLoading) {
      return this.getSubNav();
    } else if (this.state.fetchFailed) {
      return (
        <div className={styles.errorText}>
          <h2>{this.props.t("FetchError.errorText")}</h2>
        </div>
      );
    } else {
      const customerReservationDetails = this.state.customerReservationDetails;
      const customerReservation =
        customerReservationDetails &&
        customerReservationDetails.customerReservation;
      const tradeIn =
        customerReservationDetails && customerReservationDetails.tradeIn;
      const customerInfo =
        customerReservationDetails && customerReservationDetails.customerInfo;
      const dealerInfo =
        customerReservationDetails && customerReservationDetails.dealerInfo;
      return (
        // eslint-disable-next-line
        <>
          {customerReservation ? (
            <div className="pageWrapper">
              {this.getSubNav()}

              <HasPermissionTo
                perform={["orderDetailsNotifications:view"]}
                permissions={this.props.user.permissions.rolePermissions}
                render={() => (
                  <OrderDetailsActionNotifications
                    {...this.props}
                    customerReservation={customerReservation}
                    tradeIn={tradeIn}
                    isOrderCancelled={this.isOrderCancelled}
                    isReservation={this.isReservation}
                  />
                )}
              />

              <HasPermissionTo
                perform={["backToCRCSearchResults"]}
                permissions={this.props.user.permissions.rolePermissions}
                render={() => (
                  <div className={styles.orderNumberHeader}>
                    <button
                      className={styles.back}
                      onClick={() =>
                        this.props.showOrderDetails(
                          this.props.commonDealerId,
                          null,
                          false
                        )
                      }
                    >
                      <KeyboardArrowLeftIcon fontSize="small" />
                      <span className={styles.backToOrderText}>
                        {this.props.t("CustomerOrderDetails.back")}
                      </span>
                    </button>
                    <span className={styles.orderNumber}>
                      {this.props.t("CustomerOrderDetails.orderId")}
                      &nbsp; {customerReservation.id}
                    </span>
                  </div>
                )}
                noRender={() => (
                  <div className={styles.orderNumberHeader}>
                    <Link
                      className={styles.back}
                      id="back-button"
                      to="/dealer/customer-reservations"
                    >
                      <KeyboardArrowLeftIcon fontSize="small" />
                      <span className={styles.backToOrderText}>
                        {this.props.t("CustomerOrderDetails.back")}
                      </span>
                    </Link>
                    <span className={styles.orderNumber}>
                      {this.props.t("CustomerOrderDetails.orderId")}
                      &nbsp; {customerReservation.id}
                    </span>
                    <CancelOrderDialog
                      dialogProps={this.dialogProps}
                      isRefund={false}
                      is400Error={this.state.is400Error}
                      showDropdown={true}
                      open={this.state.openCancelDialog}
                      onClose={this.handleCancelClose}
                      handleConfirm={this.handleUpdateOrder}
                      {...this.props}
                    />
                  </div>
                )}
              />
              <div
                className={`customerOrderDetails ${styles.componentContainer}`}
              >
                <OrderInfo
                  permissions={this.props.user.permissions.rolePermissions}
                  customerReservation={customerReservation}
                  showCancelConfirmationPopup={this.showCancelConfirmationPopup}
                  market={
                    this.props.fromCrc
                      ? this.props.selectedMarket
                      : this.props.user.market
                  }
                  defaultCurrency={
                    this.props.localizedInfo &&
                    this.props.localizedInfo.defaultCurrency &&
                    this.props.localizedInfo.defaultCurrency.symbol
                  }
                />
                <CustomerInfoV2
                  customerInfoData={customerInfo}
                  market={
                    this.props.fromCrc
                      ? this.props.selectedMarket
                      : this.props.user.market
                  }
                />
                {this.props.fromCrc && (
                  <DealerInformation
                    {...this.props}
                    isNAMarket={false}
                    dealerInfo={dealerInfo}
                  />
                )}
                <VehicleSpecs
                  {...this.props}
                  customerInfoData={customerInfo}
                  customerReservation={customerReservation}
                  defaultCurrency={
                    this.props.localizedInfo.defaultCurrency.symbol
                  }
                  offlinePricingUpdate={
                    customerReservation.offlinePricingUpdate
                      ? customerReservation.offlinePricingUpdate
                      : {}
                  }
                  customerReservationStatus={customerReservation.status}
                  pricingStack={customerReservation.pricingStack}
                  tradeIn={tradeIn}
                  market={
                    this.props.fromCrc
                      ? this.props.selectedMarket
                      : this.props.user.market
                  }
                  nameplate={customerReservation.nameplate}
                />
              </div>
              <HasPermissionTo
                perform={["progressTracker:view"]}
                permissions={this.props.user.permissions.rolePermissions}
                render={() => {
                  return (
                    <div className={styles.componentContainer}>
                      <ProgressTracker
                        reservation={customerReservation}
                        market={
                          this.props.fromCrc
                            ? this.props.selectedMarket
                            : this.props.user.market
                        }
                      />
                    </div>
                  );
                }}
              />
              <HasPermissionTo
                perform={["customerOptOffline:view"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={!this.isReservation(customerReservation.orderType)}
                render={() => {
                  return (
                    <div className={styles.componentContainer}>
                      <CustomerOptOffline
                        {...this.props}
                        handleCustomerOptOffline={this.handleUpdateOrder}
                        offlineDocInfo={
                          customerReservation.offlineDocInfo
                            ? customerReservation.offlineDocInfo
                            : {}
                        }
                        isOrderCancelled={this.isOrderCancelled}
                        customerReservation={customerReservation}
                        offlinePricingUpdate={
                          customerReservation.offlinePricingUpdate
                            ? customerReservation.offlinePricingUpdate
                            : {}
                        }
                      />
                    </div>
                  );
                }}
              />

              <HasPermissionTo
                perform={["wallboxOrderDetails:view"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={
                  !this.isReservation(customerReservation.orderType) &&
                  customerReservation.chargingInstallationRequestDate
                }
                render={() => {
                  return (
                    <div className={styles.componentContainer}>
                      <WallBoxDetails
                        user={this.props.user}
                        isOrderCancelled={this.isOrderCancelled}
                        customerReservation={customerReservation}
                      />
                    </div>
                  );
                }}
              />
              <HasPermissionTo
                perform={["tradeIn:view"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={
                  !this.isReservation(customerReservation.orderType) && tradeIn
                }
                render={() => {
                  return (
                    <div className={styles.componentContainer}>
                      <TradeIn
                        {...this.props}
                        isOrderCancelled={this.isOrderCancelled}
                        refreshOrderDetails={this.fetchCustomerOrderDetails}
                        status={customerReservation.status}
                        tradeInDetails={tradeIn}
                      />
                    </div>
                  );
                }}
              />
            </div>
          ) : (
            <>
              {this.getSubNav()}
              <div className="pagePadding">
                {this.props.t("CustomerOrderDetails.errorLoadingDetails")}
              </div>
            </>
          )}
        </>
      );
    }
  }
}

export default withTranslation("emp")(OrderDetailsNewVersion);
