import React, { Component } from "react";
import styles from "./PricingWrapper.module.scss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HasPermissionTo from "../../../common/HasPermissionTo";
import SubNavDropdown from "../../../shared/subnav/SubNavDropdown";
import PendingActionClient from "../../../shared/clients/PendingActionClient";
import { ACCESSORIES_VERSION } from "../../../common/Constants";
import Grid from "@material-ui/core/Grid";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const DisplayPricingType = (props) => {
  return (
    <Grid item md={4} xs={6}>
      <div className={styles.priceType}>
        <div>
          <div className={styles.priceTitle}>{props.header}</div>
          <div className={styles.pricingText}>
            {props.desc ? props.desc : "--"}
          </div>
          <Link
            className={styles.link}
            data-testid={props.name}
            to={props.link}
            name={props.name}
          >
            <div className={styles.pageBottom}>
              {props.goToPage}
              <ChevronRightIcon className={styles.rightChevron} />
            </div>
          </Link>
        </div>
      </div>
    </Grid>
  );
};

class PricingWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccessories: props.user.hasNscCompletedBasicPAs,
      renderApp: false,
    };
  }

  componentDidMount() {
    this.showAccessories();
  }

  showAccessories = () => {
    if (
      !this.props.isNsc &&
      ACCESSORIES_VERSION[this.props.user.market] === 3
    ) {
      PendingActionClient.getHasNscCompletedPA(this.props.user, 3).then(
        (response) => {
          this.setState({ showAccessories: response, renderApp: true });
        }
      );
    } else {
      this.setState({ renderApp: true });
    }
  };

  render() {
    return (
      this.state.renderApp && (
        <div className="pageWrapper" id="PricingWrapper">
          <div className={`${styles.titleDiv} pagePadding`}>
            <div className="pageTitle">
              {this.props.t("PricingWrapper.title")}
            </div>
            <HasPermissionTo
              perform={["AllCMA:view"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={
                !this.props.restrictDropdown &&
                this.props.user.dealerGroup &&
                this.props.user.dealerGroup.length > 1
              }
              render={() => <SubNavDropdown {...this.props} />}
            />
          </div>
          <p className={`${styles.subHeader} pagePadding`}>
            {this.props.t("PricingWrapper.subTitle")}
          </p>

          <Grid container spacing={24} className={styles.pricing}>
            <HasPermissionTo
              perform={["depositPricingTab"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => (
                <DisplayPricingType
                  goToPage={this.props.t("PricingWrapper.goToPage")}
                  header={this.props.t("PricingWrapper.deposit")}
                  desc={this.props.t("ALDPricing.subheader")}
                  link="/pricing/deposit"
                  name="deposit"
                />
              )}
            />
            <HasPermissionTo
              perform={["dealerInventoryDeposit:view"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => (
                <DisplayPricingType
                  goToPage={this.props.t("PricingWrapper.goToPage")}
                  header={this.props.t("PricingWrapper.dealerDeposit")}
                  desc={this.props.t("PricingWrapper.dealerDepositSubheader")}
                  link="/pricing/dealerpricing"
                  name="deposit"
                />
              )}
            />
            <HasPermissionTo
              perform={["accessoriesPricingTab"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.showAccessories}
              render={() => (
                <DisplayPricingType
                  header={this.props.t("PricingWrapper.accessoriesPricing")}
                  desc={this.props.t("PricingWrapper.accessoriesPricingDesc")}
                  goToPage={this.props.t("PricingWrapper.goToPage")}
                  link="/pricing/accessories"
                  name="accessories"
                />
              )}
            />
          </Grid>
        </div>
      )
    );
  }
}

export default withTranslation("emp")(PricingWrapper);
